import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const EcoImpactContainer = styledSystem(Box)(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.designSystem.size[16],
  padding: theme.designSystem.size[24],
  backgroundColor: theme.designSystem.white,
  height: '100%',
  width: '100%',
  alignItems: 'center'
}))

export const TypographyStyled = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.button,
  fontWeight: theme.designSystem.typography.fontWeight.medium
}))
