import { Description, StyledTypographyBox, Title } from './styles'

interface TypographyBoxProps {
  typographyBoxRole?: string
  title: string
  description: React.ReactNode | string
}

export const TypographyBox = ({ typographyBoxRole, title, description }: TypographyBoxProps) => {
  return (
    <StyledTypographyBox role={typographyBoxRole}>
      <Title role="title">{title}</Title>
      <Description role="description">{description}</Description>
    </StyledTypographyBox>
  )
}
