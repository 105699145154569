import { useState } from 'react'
import {
  ReportDataCO2,
  ReportDataConsumer,
  ReportDataTotal,
  ReportDataEconomy,
  ReportDataEconomyAlert,
  ReportDataFilters,
  ReportCreditBalance
} from './types'
import api, { TOKEN_KEY } from 'shared/services/Api'
import { useReportStore } from 'shared/store/reports'
import endpoints from 'shared/services/Api/endpoints'
import { jwtDecode } from 'jwt-decode'
import mockCreditBalanceSolar from './mockCreditBalanceSolar.json'

const useApiAllReportsEnergy = () => {
  const {
    state: { dataEconomy, dataConsumer, dataEconomyAlert, dataCO2, dataTotal },
    actions: { setDataEconomy, setDataConsumer, setDataEconomyAlert, setDataCO2, setDataTotal }
  } = useReportStore()

  const [loading, setLoading] = useState(false)

  const mutateEnergy = async (dataFilters: ReportDataFilters) => {
    try {
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      setLoading(true)
      const stateSetters = [
        setDataEconomy,
        setDataConsumer,
        setDataEconomyAlert,
        setDataCO2,
        setDataTotal
      ]

      const endpointsApi = [
        api.get(endpoints.reports.economyReport({ ...dataFilters, userId: id })),
        api.get(endpoints.reports.averageConsumptionReport({ ...dataFilters, userId: id })),
        api.get(endpoints.reports.economyAlertreport({ ...dataFilters, userId: id })),
        api.get(endpoints.reports.sustainabilityReport({ ...dataFilters, userId: id })),
        api.get(endpoints.reports.totalCosts({ ...dataFilters, userId: id }))
      ]

      const results = await Promise.allSettled(endpointsApi)

      results.forEach((result, index) => {
        const setState = stateSetters[index]
        setState(result?.status === 'fulfilled' ? result?.value?.data?.data : null)
      })
    } catch (error) {
      console.error('Erro ao buscar o relatórios:', error)
    } finally {
      setLoading(false)
    }
  }

  return { mutateEnergy, dataEconomy, dataConsumer, dataEconomyAlert, dataCO2, dataTotal, loading }
}

const useApiAllReportsSolar = () => {
  const {
    state: { dataEconomy, dataConsumer, dataCO2, dataCreditBalance },
    actions: { setDataEconomy, setDataConsumer, setDataCO2, setDataCreditBalance }
  } = useReportStore()

  const [loading, setLoading] = useState(false)

  const mutateSolar = async (dataFilters: ReportDataFilters) => {
    try {
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      setLoading(true)
      const stateSetters = [setDataEconomy, setDataConsumer, setDataCO2, setDataCreditBalance]

      const endpointsApi = [
        api.get(endpoints.reports.economyReport({ ...dataFilters, userId: id })),
        api.get(endpoints.reports.averageConsumptionReport({ ...dataFilters, userId: id })),
        api.get(endpoints.reports.sustainabilityReport({ ...dataFilters, userId: id })),
        api.get(endpoints.reports.creditBalanceReport({ ...dataFilters, userId: id }))
      ]

      const results = await Promise.allSettled(endpointsApi)

      results.forEach((result, index) => {
        const setState = stateSetters[index]
        setState(result?.status === 'fulfilled' ? result?.value?.data?.data : null)
      })
    } catch (error) {
      console.error('Erro ao buscar o relatórios Solar:', error)
    } finally {
      setLoading(false)
    }
  }

  return { mutateSolar, dataEconomy, dataConsumer, dataCO2, dataCreditBalance, loading }
}
const useApiEconomy = () => {
  const [apiEconomy, setApiEconomy] = useState<ReportDataEconomy | null>(null)
  const [loading, setLoading] = useState(false)

  const mutateEconomy = async (dataFilters: ReportDataFilters) => {
    try {
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      setLoading(true)

      const response = await api.get(endpoints.reports.economyReport({ ...dataFilters, userId: id }))

      setApiEconomy(response?.data?.data)
    } catch (error) {
      console.error('Erro ao buscar o relatório:', error)
      setApiEconomy(null)
    } finally {
      setLoading(false)
    }
  }

  return { mutateEconomy, apiEconomy, loading }
}

const useApiConsumer = () => {
  const [apiConsumer, setApiConsumer] = useState<ReportDataConsumer | null>(null)
  const [loading, setLoading] = useState(false)

  const mutateConsumer = async (dataFilters: ReportDataFilters) => {
    try {
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      setLoading(true)

      const response = await api.get(
        endpoints.reports.averageConsumptionReport({ ...dataFilters, userId: id })
      )

      setApiConsumer(response?.data?.data)
    } catch (error) {
      console.error('Erro ao buscar o relatório:', error)
      setApiConsumer(null)
    } finally {
      setLoading(false)
    }
  }

  return { mutateConsumer, apiConsumer, loading }
}

const useApiEconomyAlert = () => {
  const [apiEconomyAlert, setApiEconomyAlert] = useState<ReportDataEconomyAlert | null>(null)
  const [loading, setLoading] = useState(false)

  const mutateEconomyAlert = async (dataFilters: ReportDataFilters) => {
    try {
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      setLoading(true)

      const response = await api.get(
        endpoints.reports.economyAlertreport({ ...dataFilters, userId: id })
      )

      setApiEconomyAlert(response?.data?.data)
    } catch (error) {
      console.error('Erro ao buscar o relatório:', error)
      setApiEconomyAlert(null)
    } finally {
      setLoading(false)
    }
  }

  return { mutateEconomyAlert, apiEconomyAlert, loading }
}

const useApiCO2 = () => {
  const [apiCO2, setApiCO2] = useState<ReportDataCO2 | null>(null)
  const [loading, setLoading] = useState(false)

  const mutateCO2 = async (dataFilters: ReportDataFilters) => {
    try {
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      setLoading(true)

      const response = await api.get(
        endpoints.reports.sustainabilityReport({ ...dataFilters, userId: id })
      )

      setApiCO2(response?.data?.data)
    } catch (error) {
      console.error('Erro ao buscar o relatório:', error)
      setApiCO2(null)
    } finally {
      setLoading(false)
    }
  }

  return { mutateCO2, apiCO2, loading }
}

const useApiTotal = () => {
  const [apiTotal, setApiTotal] = useState<ReportDataTotal | null>(null)
  const [loading, setLoading] = useState(false)

  const mutateTotal = async (dataFilters: ReportDataFilters) => {
    try {
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      setLoading(true)

      const response = await api.get(endpoints.reports.totalCosts({ ...dataFilters, userId: id }))

      setApiTotal(response?.data?.data)
    } catch (error) {
      console.error('Erro ao buscar o relatório:', error)
      setApiTotal(null)
    } finally {
      setLoading(false)
    }
  }

  return { mutateTotal, apiTotal, loading }
}

const useApiCreditBalance = () => {
  const [apiCreditBalance, setApiCreditBalance] = useState<ReportCreditBalance | null>(null)
  const [loading, setLoading] = useState(false)

  const mutateCreditBalance = async (dataFilters: ReportDataFilters) => {
    try {
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      setLoading(true)

      const response = await api.get(
        endpoints.reports.creditBalanceReport({ ...dataFilters, userId: id })
      )

      setApiCreditBalance(response?.data?.data)
    } catch (error) {
      setApiCreditBalance(mockCreditBalanceSolar)
    } finally {
      setLoading(false)
    }
  }

  return { mutateCreditBalance, apiCreditBalance, loading }
}

export {
  useApiAllReportsEnergy,
  useApiAllReportsSolar,
  useApiEconomy,
  useApiConsumer,
  useApiEconomyAlert,
  useApiCO2,
  useApiTotal,
  useApiCreditBalance
}
