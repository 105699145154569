import Tag from 'shared/designSystem/components/Tag'
import { ContainerTexts, StyledCard, StyledSubtitle, StyledText, StyledTitle } from './styles'
import { formatDateShort } from 'shared/utils/dates'

interface Props {
  title: string
  subtitle?: string
  tagText?: string
  tagColor?: string
  text?: string
  footerTextLeft?: string
  footerTextRigth?: string
  onClick?: VoidFunction
}

const CardData = ({
  title,
  subtitle,
  tagText,
  tagColor,
  text,
  footerTextLeft,
  footerTextRigth,
  onClick
}: Props) => {
  const dateLeft = formatDateShort(footerTextLeft!)

  return (
    <StyledCard onClick={onClick}>
      <ContainerTexts>
        <StyledTitle variant="h3">{title}</StyledTitle>
        {tagText && <Tag label={tagText} colorTag={tagColor} />}
      </ContainerTexts>
      <StyledSubtitle variant="body1">{subtitle}</StyledSubtitle>
      <StyledText marginBottom="16px" variant="body1">
        {text}
      </StyledText>

      <ContainerTexts>
        <StyledText variant="body1">{dateLeft}</StyledText>
        <StyledTitle variant="body1">{footerTextRigth}</StyledTitle>
      </ContainerTexts>
    </StyledCard>
  )
}

export default CardData
