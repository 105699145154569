import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'
import { theme } from 'shared/theme'

export const PeriodBadgeContainer = styledSystem(Box)<{ selected: boolean }>(({ selected }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.designSystem.size[12],
  paddingTop: theme.designSystem.size[4],
  paddingRight: theme.designSystem.size[16],
  paddingLeft: theme.designSystem.size[16],
  paddingBottom: theme.designSystem.size[4],
  border: `1px solid ${selected ? theme.designSystem.primary[900] : '#000'}`,
  borderRadius: theme.designSystem.size[28],
  width: 'auto',
  height: theme.designSystem.size[32],
  maxWidth: 'fit-content',
  backgroundColor: `${selected ? '#000' : theme.designSystem.white}`,
  color: `${selected ? theme.designSystem.white : '#000'}`,
  fontSize: theme.designSystem.typography.fontSize[14],
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  cursor: 'pointer'
}))

export const PeriodContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
  marginBottom: theme.designSystem.size[24]
}))
