import { SelectChangeEvent as MuiSelectChangeEvent } from '@mui/material'

type Props = {
  onChange: (value: string) => void
}

export const SelectChangeEvent = ({ onChange }: Props) => {
  const handleChange = (event: MuiSelectChangeEvent) => {
    onChange(event.target.value)
  }

  return { handleChange }
}
