import { ChartDataset, ChartOptions } from 'chart.js'
import { GraphPropsBalanceCredit } from 'pages/Reports/types/GraphProps'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useFilters from 'shared/hook/useFilters'
import { theme } from 'shared/theme'
import { getDesaturatedColor } from 'shared/utils/getDesaturatedColor'
import { getMonthAbbreviations, getMonthFirstLetter, getMonths } from 'shared/utils/dates'

const useCreditBalance = ({ variant, dataInfos }: GraphPropsBalanceCredit) => {
  const { filters } = useFilters('filtersAll')

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [isMonthView, setIsMonthView] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null)
  const [tooltipData, setTooltipData] = useState<{
    x: number
    y: number
    visible: boolean
  }>({ x: 0, y: 0, visible: false })

  const isModalVariant = variant === 'modal'
  const injectedCredit = dataInfos?.dataGraphic?.injectedCredit || []
  const consumedCredit = dataInfos?.dataGraphic?.consumedCredit || []
  const remainingBalance = dataInfos?.dataGraphic?.remainingBalance || []
  const previousBalance = dataInfos?.dataGraphic?.previousBalance || []
  const hasData = isMonthView ? injectedCredit.some((_, index) => index === selectedMonth) : dataInfos
  const labels = getMonthAbbreviations()
  const months = getMonths()

  useEffect(() => {
    if (filters?.month && filters?.month != 0 && !isModalVariant) {
      setSelectedMonth(Number(filters?.month) - 1)
      setIsMonthView(true)
    } else {
      setSelectedMonth(null)
      setIsMonthView(false)
    }
  }, [filters?.month])

  const generateDatasets = (monthIndex: number | null): ChartDataset<'bar'>[] => {
    const isMobile = window.innerWidth <= 768
    const yearBar = isMobile ? 8 : 22
    const barThickness = monthIndex !== null ? 40 : yearBar

    const filtered = (data: number[]) =>
      monthIndex !== null ? data.filter((_, index) => index === monthIndex) : data

    return [
      {
        label: 'Saldo anterior',
        data: filtered(previousBalance),
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.primary[300]
            : getDesaturatedColor(theme.designSystem.primary[300]),
        barPercentage: 0.8,
        barThickness,
        categoryPercentage: 0.5,
        stack: 'stack1',
        borderWidth: isMonthView ? 4 : 0,
        borderColor: 'white',
        borderSkipped: ['top', 'right']
      },
      {
        label: 'Crédito injetado',
        data: filtered(injectedCredit),
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.primary[100]
            : getDesaturatedColor(theme.designSystem.primary[100]),
        barPercentage: 0.8,
        barThickness,
        categoryPercentage: 0.5,
        stack: 'stack1',
        borderWidth: isMonthView ? 4 : 0,
        borderColor: 'white',
        borderSkipped: ['top', 'left']
      },
      {
        label: 'Crédito consumido',
        data: filtered(consumedCredit),
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.primary[500]
            : getDesaturatedColor(theme.designSystem.primary[500]),
        barPercentage: 0.8,
        barThickness,
        categoryPercentage: 0.5,
        stack: 'stack2',
        borderWidth: isMonthView ? 4 : 0,
        borderColor: 'white',
        borderSkipped: ['bottom', 'right']
      },
      {
        label: 'Saldo remanescente',
        data: filtered(remainingBalance),
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.attention[500]
            : getDesaturatedColor(theme.designSystem.attention[500]),
        barPercentage: 0.8,
        barThickness,
        categoryPercentage: 0.5,
        stack: 'stack2',
        borderWidth: isMonthView ? 4 : 0,
        borderColor: 'white',
        borderSkipped: ['bottom', 'left']
      }
    ]
  }

  const data = {
    labels: isModalVariant ? getMonthAbbreviations() : getMonthFirstLetter(),
    datasets: generateDatasets(null)
  }

  const detailedData = {
    labels: selectedMonth !== null ? [labels[selectedMonth]] : [],
    datasets: generateDatasets(selectedMonth)
  }

  const prevTooltipData = useRef(tooltipData)

  const handleTooltip = useCallback(
    ({ chart, tooltip }: any) => {
      if (tooltip.opacity === 0) {
        if (tooltipData.visible) setTooltipData({ ...tooltipData, visible: false })
        return
      }

      const { offsetLeft, offsetTop } = chart.canvas
      const newTooltipData = {
        x: offsetLeft + tooltip.caretX,
        y: offsetTop + tooltip.caretY,
        visible: true
      }

      if (
        prevTooltipData.current.x !== newTooltipData.x ||
        prevTooltipData.current.y !== newTooltipData.y ||
        !tooltipData.visible
      ) {
        prevTooltipData.current = newTooltipData
        setTooltipData(newTooltipData)
      }
    },
    [tooltipData]
  )

  const baseOptions: ChartOptions<'bar'> = {
    responsive: true,
    animation: false,
    plugins: {
      legend: { display: false },
      datalabels: {
        display: (context) => {
          const datasetLabel = context.dataset.label
          const barElement = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex]
          let widthBar = 0
          if (barElement && 'getProps' in barElement) {
            const { width } = barElement.getProps(['width'], true)
            widthBar = width
          }

          return (
            isMonthView &&
            widthBar > 100 &&
            (datasetLabel === 'Crédito injetado' || datasetLabel === 'Crédito consumido')
          )
        },
        color: (context) => {
          const datasetLabel = context.dataset.label
          if (datasetLabel === 'Crédito injetado') {
            return '#000'
          } else if (datasetLabel === 'Crédito consumido') {
            return '#FFF'
          }
          return '#000'
        },
        anchor: 'end',
        align: 'start',
        padding: isMonthView ? 10 : 0,
        formatter: (value) => `${value.toLocaleString()} KWh`,
        font: {
          weight: 'bolder',
          lineHeight: '21px',
          size: 14
        }
      },
      tooltip: {
        enabled: false,
        external: handleTooltip
      }
    },
    onHover: (_, activeElements) => {
      const newIndex = activeElements.length ? activeElements[0].index : null
      setHoveredIndex(newIndex)
      if (!activeElements.length) {
        setTimeout(() => {
          setHoveredIndex(null)
        }, 50)
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          display: !isMonthView
        }
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: isModalVariant
        },
        ticks: {
          display: isModalVariant
        },
        border: {
          display: false
        }
      }
    },
    elements: {
      bar: {
        hoverBorderColor: '#FFF',
        hoverBackgroundColor: undefined
      }
    }
  }

  const options: ChartOptions<'bar'> = {
    ...baseOptions,
    indexAxis: 'x',
    onClick: (_, activeElements) => {
      if (activeElements.length > 0 && !isModalVariant) {
        const index = activeElements[0].index
        setSelectedMonth(index)
        setIsMonthView(true)
      }
    }
  }

  const detailedOptions: ChartOptions<'bar'> = {
    ...baseOptions,
    indexAxis: 'y',
    onClick: () => {
      setSelectedMonth(null)
      setIsMonthView(false)
    }
  }

  const dataInfosHeaderDetail = useMemo(() => {
    const consumedCreditDetail = consumedCredit.find((_, index) => index === selectedMonth) ?? 0
    const injectedCreditDetail = injectedCredit.find((_, index) => index === selectedMonth) ?? 0

    return [
      {
        titleValue: `Utilizado em ${months[selectedMonth!]}`,
        value: `${consumedCreditDetail ? ((consumedCreditDetail / injectedCreditDetail) * 100).toFixed(2) : 0}%`,
        tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
      }
    ]
  }, [selectedMonth])

  const legends = [
    { color: theme.designSystem.primary[100], text: 'Crédito injetado', variant: 'square' },
    { color: theme.designSystem.primary[500], text: 'Crédito consumido', variant: 'square' },
    { color: theme.designSystem.attention[500], text: 'Saldo remanescente', variant: 'square' },
    { color: theme.designSystem.primary[300], text: 'Saldo anterior', variant: 'square' },
    {
      show: isMonthView,
      color: theme.designSystem.primary[100],
      secondaryColor: theme.designSystem.primary[200],
      text: 'Aguardando medição',
      variant: 'line-thick'
    }
  ]

  const handleEmpty = () => {
    setSelectedMonth(null)
    setIsMonthView(false)
  }

  return {
    isModalVariant,
    data,
    options,
    detailedData,
    detailedOptions,
    legends,
    hoveredIndex,
    tooltipData,
    isMonthView,
    injectedCredit,
    consumedCredit,
    remainingBalance,
    dataInfosHeaderDetail,
    previousBalance,
    setHoveredIndex,
    selectedMonth,
    handleEmpty,
    hasData
  }
}

export default useCreditBalance
