import { Box } from 'shared/designSystem/components/Box'
import { Card } from 'shared/designSystem/components/Card'
import { CardContent } from 'shared/designSystem/components/CardContent'
import { Typography } from 'shared/designSystem/components/Typography'
import { theme } from 'shared/theme'

interface Props {
  title: string
  description: string
  image: string
}

export const TutorialCard = ({ title, description, image }: Props) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 233,
        height: 311,
        boxShadow: 'none',
        border: `1px solid ${theme.designSystem.base[200]}`,
        borderRadius: 0
      }}
    >
      <Box
        sx={{
          height: 170,
          borderBottom: `1px solid ${theme.designSystem.base[200]}`,
          overflow: 'hidden'
        }}
      >
        <img
          src={image}
          alt={title}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </Box>

      <Box>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'center'
          }}
        >
          <Typography
            fontSize={theme.designSystem.typography.fontSize.button}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            color={theme.designSystem.base[900]}
          >
            {title}
          </Typography>
          <Typography
            fontSize={theme.designSystem.typography.fontSize.button}
            fontWeight={theme.designSystem.typography.fontWeight.regular}
            color={theme.designSystem.base[500]}
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  )
}
