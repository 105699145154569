import { DataGrid } from 'shared/designSystem/components/DataGrid'
import { GridColDef } from 'shared/designSystem/components/GridColDef'
import {
  CostCenterReport,
  DataConsumer,
  DataEconomy,
  EconomyAlertReport,
  SustainabilityReport
} from '../interface'
import { TableContainer } from './styles'
import { DataTableRowCreditBalance } from 'shared/services/Requests/Reports/types'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from '@mui/material'

interface Props {
  data:
    | DataConsumer[]
    | DataEconomy[]
    | CostCenterReport[]
    | EconomyAlertReport[]
    | SustainabilityReport[]
    | DataTableRowCreditBalance[]
  columns: GridColDef[]
  footerTable: any
}

export const Table = ({ data, columns, footerTable }: Props) => {
  const CustomFooter = () => {
    return (
      <Box
        mt={1}
        sx={{
          display: 'flex'
        }}
      >
        {columns.map((item, index) => (
          <Box key={index} sx={{ flex: item.flex }}>
            <Typography variant="subtitle1" fontWeight="600">
              {footerTable && footerTable[item?.field]}
            </Typography>
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <TableContainer>
      <DataGrid
        rows={data}
        columns={columns}
        disableColumnSorting
        disableColumnFilter
        hideFooterSelectedRowCount
        hideFooterPagination
        disableRowSelectionOnClick
        hideFooter
        sx={{
          '& .MuiDataGrid-columnSeparator': {
            display: 'none'
          }
        }}
      />
      <CustomFooter />
    </TableContainer>
  )
}
