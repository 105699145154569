import { useNavigate } from 'react-router-dom'

import { StyledButton, StyledTypography } from 'pages/Home/styles'
import { Box } from 'shared/designSystem/components/Box'
import { InfoTooltip } from 'shared/designSystem/components/InfoTooltip'
import { Typography } from 'shared/designSystem/components/Typography'
import IconInfo from 'shared/designSystem/Icons/IconInfo'
import { useTheme } from 'shared/hook/useTheme'
import { formatCurrency } from 'shared/utils/formatCurrency'
import { useConsumptionStore } from 'shared/store/consumption'
import { useMemo } from 'react'

export const Header = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const tooltipText = 'Atualizado conforme medições da distribuidora para todas as unidades'

  const handleNavigation = () => {
    navigate('/reports')
  }

  const {
    state: { filteredEconomy }
  } = useConsumptionStore()

  const averageConsume = useMemo(() => {
    return formatCurrency(filteredEconomy?.totalEconomyValue || 0)
  }, [filteredEconomy])

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
      <Box>
        <StyledTypography>Economia no ano</StyledTypography>

        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            fontSize={theme.designSystem.typography.fontSize.h3}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            color={theme.designSystem.base[900]}
          >
            {averageConsume}
          </Typography>

          <InfoTooltip title={tooltipText}>
            <Box display="inline-flex">
              <IconInfo />
            </Box>
          </InfoTooltip>
        </Box>
      </Box>

      <StyledButton variant="outlined" size="small" onClick={handleNavigation}>
        VER MAIS
      </StyledButton>
    </Box>
  )
}
