import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import useFilters from 'shared/hook/useFilters'
import usePdfElements from 'shared/hook/usePdfElements'
import { useTheme } from 'shared/hook/useTheme'
import { data } from 'shared/mocks/filters.json'
import { useReportStore } from 'shared/store/reports'
import { downloadAllPdf } from 'shared/utils/downloadAllPdf'
import { HeaderContainer } from './HeaderContainer'
import { ContainerFilters } from './HeaderContainer/ContainerFilters'
import { HomeOptions } from './HeaderContainer/HomeOptions'
import { ProductBadge } from './HeaderContainer/ProductBadge'
import { ReportOptions } from './HeaderContainer/ReportOptions'
import type { TypePropsPageHeader } from './interface'
import { ButtonActivation, HeaderMiddleContent } from './styles'

export const Header = ({ fetchData, filterDefault }: TypePropsPageHeader) => {
  const pdfElements = usePdfElements()
  const theme = useTheme()
  const { filters } = useFilters('filtersAll')
  const {
    state: { isOpen, graphicName, pdfRef },
    actions: { closeModal }
  } = useReportStore()
  const location = useLocation()
  const navigate = useNavigate()
  const filteredPdfElements = pdfElements.filter(
    (e) => e?.product.includes(Number(filters?.product)) && e.data
  )

  const handleActivation = () => {
    navigate('/migration-steps')
  }

  const isActive = useMemo(() => {
    try {
      if (filters && filters.hasOwnProperty('unit')) {
        const product = data?.products?.find((product) => product?.value === Number(filters?.product))
        const group = product!?.groups?.find((group) => group?.value === Number(filters?.group))

        const unit = group!?.units?.find((unit) => unit?.value === Number(filters?.unit))

        return unit!?.active
      }
      return true
    } catch (error) {
      return true
    }
  }, [filters])

  return (
    <HeaderContainer>
      {isOpen && <div onClick={closeModal}>X</div>}
      {!filterDefault && <ContainerFilters fetchData={fetchData} />}
      <HeaderMiddleContent sx={{ marginInline: `${filterDefault && 'auto'}` }}>
        <Typography
          fontWeight={theme.designSystem.typography.fontWeight.bold}
          fontSize={{
            xs: theme.designSystem.typography.fontSize.body2,
            sm: theme.designSystem.typography.fontSize.body1
          }}
          color={theme.designSystem.base[900]}
        >
          {location.pathname === '/home' && 'Visão Geral'}
          {location.pathname === '/reports' && !isOpen && 'Relatórios'}
          {location.pathname === '/finances' && 'Financeiro'}
          {isOpen && `Relatório de ${graphicName}`}
        </Typography>
        {!isActive && location.pathname !== '/reports' && (
          <ButtonActivation role="activation" onClick={handleActivation}>
            <Typography
              fontSize={{
                xs: theme.designSystem.typography.fontSize.caption,
                sm: theme.designSystem.typography.fontSize.button
              }}
              fontWeight={theme.designSystem.typography.fontWeight.medium}
            >
              Ver Ativação
            </Typography>
          </ButtonActivation>
        )}
      </HeaderMiddleContent>

      <Box display="flex" alignItems="center" gap={theme.spacing(2)}>
        {location.pathname === '/home' && <HomeOptions />}
        {location.pathname === '/reports' && !filterDefault && (
          <>
            {filters?.product == 1 && <ProductBadge name="Energy" />}
            {filters?.product == 2 && <ProductBadge name="Solar" />}

            <ReportOptions
              handlePrint={() => downloadAllPdf(pdfRef!, filteredPdfElements, 'print')}
              handleDownload={() => downloadAllPdf(pdfRef!, filteredPdfElements, 'download')}
            />
          </>
        )}
        {location.pathname === '/finances' && (
          <>
            {filters?.product == 1 && <ProductBadge name="Energy" />}
            {filters?.product == 2 && <ProductBadge name="Solar" />}
          </>
        )}
      </Box>
    </HeaderContainer>
  )
}
