import { useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { IconClose } from 'shared/designSystem/Icons/IconClose'
import { ErrorButton, ErrorCardContainer, ErrorCircle } from '../styles'
import { TypographyBody, TypographyErrorButton } from './style'

interface Props {
  message: string
  height?: number | string
  onRetry: () => Promise<void>
}

export const ErrorCard = ({ message, onRetry }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleRetry = async () => {
    setIsLoading(true)
    await onRetry()
    setIsLoading(false)
  }

  return (
    <ErrorCardContainer>
      <ErrorCircle>
        <IconClose color="white" width={14.5} height={14.5} />
      </ErrorCircle>

      <Box>
        <TypographyBody>
          Falha ao carregar dados <br /> {message}
        </TypographyBody>
      </Box>

      <ErrorButton variant="outlined" onClick={handleRetry} disabled={isLoading}>
        <TypographyErrorButton>{isLoading ? 'Loading...' : 'Tentar novamente'}</TypographyErrorButton>
      </ErrorButton>
    </ErrorCardContainer>
  )
}
