import { RefObject } from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { GridColDef } from 'shared/designSystem/components/GridColDef'

export const downloadAllPdf = async (
  pdfRef: RefObject<HTMLDivElement>,
  pdfElements: { Graph: any; data: any[]; columns: GridColDef[]; id: number }[],
  operation: 'download' | 'print' | 'share'
): Promise<File | void> => {
  if (!pdfRef.current) {
    console.error('Elemento não encontrado para gerar o PDF')
    return
  }

  pdfRef.current.style.visibility = 'visible'

  try {
    const pdf = new jsPDF('p', 'mm', 'a4')

    for (let i = 0; i < pdfElements.length; i++) {
      const element = pdfRef.current.children[i]
      if (!element) continue

      const canvas = await html2canvas(element as HTMLElement, {
        scale: 2,
        ignoreElements: (el) => el.id === 'hide-in-pdf'
      })

      const imgData = canvas.toDataURL('image/jpeg', 1)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgHeight = (canvas.height * pdfWidth) / canvas.width

      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, Math.min(imgHeight, pdfHeight))

      if (i < pdfElements.length - 1) {
        pdf.addPage()
      }
    }

    const pdfBlob = pdf.output('blob')

    if (operation === 'download') {
      pdf.save('relatorios.pdf')
    } else if (operation === 'print') {
      const pdfUrl = URL.createObjectURL(pdfBlob)
      window.open(pdfUrl, '_blank')
    } else if (operation === 'share') {
      const file = new File([pdfBlob], 'relatorios.pdf', { type: 'application/pdf' })
      return file
    }
  } catch (error) {
    console.error('Erro ao gerar ou compartilhar PDF:', error)
  } finally {
    pdfRef.current.style.visibility = 'hidden'
  }
}
