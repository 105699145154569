export const formatDate = (date: any) => {
  if (date) {
    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    })
      .format(new Date(date))
      .replace(/de /g, '')
      .replace('.', '')
  }
  return ''
}

export const formatDateShort = (date: string) => {
  return new Intl.DateTimeFormat('pt-BR', {
    dateStyle: 'short'
  }).format(new Date(date))
}

export const getMonthFirstLetter = (): string[] => {
  return Array.from({ length: 12 }, (_, i) => {
    const date = new Date(0, i)
    return new Intl.DateTimeFormat('pt-BR', { month: 'short' }).format(date).charAt(0).toUpperCase()
  })
}

export const getMonthAbbreviations = (limit?: number): string[] => {
  return Array.from({ length: limit || 12 }, (_, i) => {
    const date = new Date(0, i)
    return new Intl.DateTimeFormat('pt-BR', { month: 'short' })
      .format(date)
      .replace('.', '')
      .toUpperCase()
  })
}

export const getMonths = () => {
  return Array.from({ length: 12 }, (_, i) => {
    const month = new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(new Date(2024, i))
    return month.charAt(0).toUpperCase() + month.slice(1)
  })
}

export const getDaysInMonth = (month: number, year: number): number[] => {
  const daysInMonth = new Date(year, month + 1, 0).getDate()
  return Array.from({ length: daysInMonth }, (_, i) => i + 1)
}

export const getFixedDaysInMonth = () => {
  const days = Array.from({ length: 30 }, (_, i) => (i + 1).toString())
  return days
}

export function generateProjection(data: number[], targetLength: number): number[] {
  const currentLength = data.length

  if (currentLength >= targetLength) {
    return data.slice(0, targetLength)
  }

  const projection = [...data]
  const lastValue = data[currentLength - 1]
  const step = lastValue - data[currentLength - 2] || 0

  for (let i = currentLength; i < targetLength; i++) {
    projection.push(projection[projection.length - 1] + step)
  }

  return projection
}

export const getMonthsFirstLetter = (): string[] => {
  return ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
}
