import { useEffect, useMemo, useRef, useState } from 'react'
import useFilters from 'shared/hook/useFilters'
import { columnsCO2Graph } from 'shared/mocks/columnsPdf'
import { useApiCO2 } from 'shared/services/Requests/Reports'
import { ReportDataCO2 } from 'shared/services/Requests/Reports/types'
import { useReportStore } from 'shared/store/reports'
import CO2Graph from '../..'

const useModalCO2 = () => {
  const pdfRef = useRef(null)
  const { filters } = useFilters('filtersAll')
  const { mutateCO2, apiCO2, loading } = useApiCO2()
  const {
    state: { graphic }
  } = useReportStore()

  const [stateDataCO2, setStateDataCO2] = useState<ReportDataCO2 | null>(apiCO2)

  const infosHeaderReports = [
    {
      titleValue: 'CO₂ poupado no ano',
      value: stateDataCO2?.dataGraphic?.averageConsumptionByYear
        ? `${stateDataCO2?.dataGraphic?.averageConsumptionByYear} Kg`
        : '0 Kg',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    },
    {
      titleValue: 'Equivalência em árvores',
      value: stateDataCO2?.dataGraphic?.treeEquivalence || '0',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    }
  ]

  const pdfElements = useMemo(
    () => [
      {
        graph: (
          <CO2Graph variant="modal" dataInfos={stateDataCO2} dataInfosHeader={infosHeaderReports} />
        ),
        data: stateDataCO2?.dataTable,
        columns: columnsCO2Graph,
        footerTable: stateDataCO2?.tableTotals
      }
    ],
    [stateDataCO2]
  )

  useEffect(() => {
    if (graphic === 5 && filters?.year)
      mutateCO2({
        year: filters?.year,
        productId: filters?.product,
        groupId: filters?.group,
        unitId: filters?.unit
      })
  }, [graphic, filters])

  useEffect(() => {
    setStateDataCO2(apiCO2)
  }, [apiCO2])

  return { loading, pdfElements, pdfRef, mutateCO2, stateDataCO2, infosHeaderReports }
}

export default useModalCO2
