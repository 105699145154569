import { Card } from 'shared/designSystem/components/Card'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const StyledCard = styledSystem(Card)(({ theme }) => ({
  backgroundColor: theme.designSystem.white,
  cursor: 'pointer',
  padding: '32px 20px',
  borderRadius: 0,
  boxShadow: 'unset',
  border: '1px solid #0509221A'
}))

export const ContainerTexts = styledSystem('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row'
}))

export const StyledTitle = styledSystem(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '17.6px',
  color: theme.designSystem.base[900]
}))

export const StyledSubtitle = styledSystem(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '18px',
  color: `${theme.designSystem.base[900]}99`,
  marginBottom: '16px'
}))

export const StyledText = styledSystem(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '18px',
  color: `${theme.designSystem.base[900]}61`
}))
