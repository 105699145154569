import TabDistributor from 'pages/Financial/tabs/energy/tabDistributor'
import TabGenialInvoice from 'pages/Financial/tabs/energy/tabGenialInvoice'
import { useEffect, useMemo, useState } from 'react'
import { Chip } from 'shared/designSystem/components/Chip'
import { SelectChangeEvent } from 'shared/designSystem/components/SelectChangeEvent'
import { Tab } from 'shared/designSystem/components/Tab'
import useFilters from 'shared/hook/useFilters'
import useFiltersGlobal from 'shared/hook/useFiltersGlobal'
import { useFinancialStore } from 'shared/store/financial'
import { a11yProps } from 'shared/utils/financialTab'
import { TabsStyles } from './style'

const useHeaderTabsSolar = () => {
  const { filters, setBulkFilterData } = useFilters('filtersAll')
  const { globalDate } = useFiltersGlobal()
  const {
    actions: { setViewHistoryTab }
  } = useFinancialStore()
  const [tabItem, setTabItem] = useState(0)
  const [activeFilter, setActiveFilter] = useState<number>(1)

  const TABS = [
    { label: 'Todos', value: 0 },
    { label: 'Residência São Paulo', value: 1 },
    { label: 'Residência Rio', value: 2 },
    { label: 'Casa Petrópolis', value: 3 }
  ]

  const TABSFILTER = [
    { label: 'Fatura Genial', value: 2, component: <TabGenialInvoice Month={tabItem + 1} /> },
    { label: 'Distribuidora', value: 5, component: <TabDistributor Month={tabItem + 1} /> }
  ]

  const handleComponenteView = () => {
    const filterTabsFilter = TABSFILTER.find((item) => item.value === activeFilter)
    setViewHistoryTab(filterTabsFilter?.component)
  }

  const { handleChange } = SelectChangeEvent({
    onChange: (value: string) => {
      setBulkFilterData({ ...filters, yearF: value })
    }
  })

  const renderTabs = useMemo(
    () => (
      <TabsStyles
        value={tabItem}
        onChange={(_, value) => setTabItem(value)}
        aria-label="table financial"
      >
        {TABS.map((item) => (
          <Tab
            key={item.value}
            label={item.label}
            {...a11yProps(item.value)}
            sx={{
              textTransform: 'none'
            }}
          />
        ))}
      </TabsStyles>
    ),
    [tabItem]
  )

  const renderTabsFilterHistory = useMemo(
    () =>
      TABSFILTER.map((item) => (
        <Chip
          key={item.value}
          label={item.label}
          variant={item.value === activeFilter ? 'outlined' : 'filled'}
          sx={{
            marginLeft: { sm: '15px' },
            border: '1px solid #05092261',
            background: item.value === activeFilter ? '#000000' : '#ffffff',
            color: item.value === activeFilter ? '#ffffff' : '#05092261'
          }}
          onClick={() => setActiveFilter(item.value)}
        />
      )),
    [activeFilter, tabItem]
  )

  useEffect(() => {
    handleComponenteView()
  }, [activeFilter, tabItem])

  return { renderTabs, renderTabsFilterHistory, handleChange, filters, globalDate }
}

export default useHeaderTabsSolar
