interface Props {
  color?: 'inherit' | 'primary'
}

export const SubjectOutlinedIcon = ({ color = 'inherit' }: Props) => {
  const fillColor = color === 'inherit' ? '#000000' : '#FFFFFF'
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 8.00033C3.5 7.72418 3.72386 7.50033 4 7.50033H12C12.2761 7.50033 12.5 7.72418 12.5 8.00033C12.5 8.27647 12.2761 8.50033 12 8.50033H4C3.72386 8.50033 3.5 8.27647 3.5 8.00033Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 4.66699C3.5 4.39085 3.72386 4.16699 4 4.16699H7.33333C7.60948 4.16699 7.83333 4.39085 7.83333 4.66699C7.83333 4.94313 7.60948 5.16699 7.33333 5.16699H4C3.72386 5.16699 3.5 4.94313 3.5 4.66699Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 11.3337C3.5 11.0575 3.72386 10.8337 4 10.8337H10C10.2761 10.8337 10.5 11.0575 10.5 11.3337C10.5 11.6098 10.2761 11.8337 10 11.8337H4C3.72386 11.8337 3.5 11.6098 3.5 11.3337Z"
        fill={fillColor}
      />
    </svg>
  )
}
