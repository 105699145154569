import { api } from 'shared/services/Api'

export class UnityApi {
  static async getMigrationsStepsData(userId: number | string) {
    const response = await api.get(`/migration`, {
      params: { userId: userId }
    })
    return response.data
  }

  static async getActivationStatusData() {
    const response = await api.get(`/activationstatus`)
    return response.data
  }
}
