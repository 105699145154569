import { EconomyDataPageProps } from 'pages/Home/models/EconomyData'
import { Box } from 'shared/designSystem/components/Box'
import { GraphTooltip } from 'shared/designSystem/components/GraphTooltip'
import { Typography } from 'shared/designSystem/components/Typography'
import { theme } from 'shared/theme'
import { formatCurrency } from 'shared/utils/formatCurrency'
import { ConsumeGraphBox } from '../ConsumeGraph/styles'
import { ErrorCard } from '../Error/ErrorCard'
import { Header } from './Header'
import { Legend } from './Legend'
import { useEconomyGraph } from './useEconomyGraph'

const EconomyGraph = ({ fetchDataEconomy }: EconomyDataPageProps) => {
  const { errorEconomy, filteredEconomy, getFlexValue, getBarColor, setHoveredBar } = useEconomyGraph()

  return (
    <>
      {errorEconomy ? (
        <Box height={384} display="flex" justifyContent="center" bgcolor="white">
          <ErrorCard message="de economia" onRetry={fetchDataEconomy} />
        </Box>
      ) : (
        <Box height={384}>
          <ConsumeGraphBox>
            <Header />
            <Box mt={4} sx={{ height: '100%', alignContent: 'flex-end' }}>
              <Box width={200}>
                <Box display="flex" mb={1}>
                  <GraphTooltip
                    title={
                      <>
                        <Typography
                          fontWeight={theme.designSystem.typography.fontWeight.regular}
                          fontSize={theme.designSystem.typography.fontSize.caption}
                          color={theme.designSystem.base[300]}
                        >
                          Custo na Genial
                        </Typography>
                        <Typography
                          fontWeight={theme.designSystem.typography.fontWeight.medium}
                          color={theme.designSystem.base[900]}
                          fontSize={theme.designSystem.typography.fontSize.button}
                        >
                          {formatCurrency(filteredEconomy?.totalGenialCost!)}
                        </Typography>
                      </>
                    }
                    aria-modal
                    placement="top"
                  >
                    <Box
                      flex={getFlexValue(filteredEconomy?.totalGenialCost || 0)}
                      bgcolor={getBarColor(0)}
                      height={16}
                      onMouseEnter={() => setHoveredBar(0)}
                      onMouseLeave={() => setHoveredBar(null)}
                    />
                  </GraphTooltip>
                  <GraphTooltip
                    title={
                      <>
                        <Typography
                          fontWeight={theme.designSystem.typography.fontWeight.regular}
                          fontSize={theme.designSystem.typography.fontSize.caption}
                          color={theme.designSystem.base[300]}
                        >
                          Sua Economia
                        </Typography>
                        <Typography
                          fontWeight={theme.designSystem.typography.fontWeight.medium}
                          color={theme.designSystem.base[900]}
                          fontSize={theme.designSystem.typography.fontSize.button}
                        >
                          {formatCurrency(filteredEconomy?.totalEconomyValue!)}
                        </Typography>
                      </>
                    }
                    aria-modal
                    placement="top"
                  >
                    <Box
                      flex={getFlexValue(filteredEconomy?.totalEconomyValue || 0)}
                      bgcolor={getBarColor(1)}
                      height={16}
                      onMouseEnter={() => setHoveredBar(1)}
                      onMouseLeave={() => setHoveredBar(null)}
                    />
                  </GraphTooltip>
                </Box>
                <GraphTooltip
                  title={
                    <>
                      <Typography
                        fontWeight={theme.designSystem.typography.fontWeight.regular}
                        fontSize={theme.designSystem.typography.fontSize.caption}
                        color={theme.designSystem.base[300]}
                      >
                        Mercado Cativo
                      </Typography>
                      <Typography
                        fontWeight={theme.designSystem.typography.fontWeight.medium}
                        fontSize={theme.designSystem.typography.fontSize.button}
                        color={theme.designSystem.base[900]}
                      >
                        {formatCurrency(filteredEconomy?.totalTraditionalCost!)}
                      </Typography>
                    </>
                  }
                  aria-modal
                  placement="top"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    onMouseEnter={() => setHoveredBar(2)}
                    onMouseLeave={() => setHoveredBar(null)}
                  >
                    <Box
                      flex={filteredEconomy?.totalTraditionalCost || 0}
                      bgcolor={getBarColor(2)}
                      height={16}
                    />
                  </Box>
                </GraphTooltip>
              </Box>
            </Box>
            <Legend
              values={{
                genialCost: filteredEconomy?.totalGenialCost || 0,
                economyValue: filteredEconomy?.totalEconomyValue || 0,
                traditionalCost: filteredEconomy?.totalTraditionalCost || 0
              }}
            />
          </ConsumeGraphBox>
        </Box>
      )}
    </>
  )
}

export default EconomyGraph
