import { useState } from 'react'
import { TypographyBox } from '../TypographyBox'
import {
  Button,
  ButtonContainer,
  CheckBox,
  CheckboxContainer,
  Description,
  FormboxContainer,
  LinkToTerms,
  MainBox,
  StyledContentBox
} from './styles'
import { Typography } from 'shared/designSystem/components/Typography'

interface FormBoxProps {
  role?: string
  onAccept: (accepted: boolean) => void
}

export const FormBox = ({ role, onAccept }: FormBoxProps) => {
  const [isChecked, setIsChecked] = useState(false)
  const [error, setError] = useState<boolean>(false)

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev)
    setError(false)
  }

  const handleSubmit = async () => {
    if (!isChecked) {
      setError(true)
      return
    }
    onAccept(true)
  }

  return (
    <MainBox role={role}>
      <FormboxContainer role="form-box">
        <StyledContentBox role="styled-box">
          <TypographyBox
            title="Termos e políticas"
            description={
              <Typography>
                Ao selecionar “Eu concordo”, você declara que leu, revisou e concordou com os{' '}
                <LinkToTerms href="">Termos de Uso</LinkToTerms> e{' '}
                <LinkToTerms href="">Política de Privacidade</LinkToTerms>.
              </Typography>
            }
            typographyBoxRole="typography-box"
          />
        </StyledContentBox>
        <CheckboxContainer>
          <CheckBox
            role="accept-checkbox"
            error={error}
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <Description role="accept-text" error={error}>
            Eu concordo
          </Description>
        </CheckboxContainer>
        <ButtonContainer>
          <Button role="button" onClick={handleSubmit}>
            Continuar
          </Button>
        </ButtonContainer>
      </FormboxContainer>
    </MainBox>
  )
}
