import { useState } from 'react'
import api from 'shared/services/Api'
import endpoints from 'shared/services/Api/endpoints'

//
import {
  IfinancesDetails,
  IfinancesResponseList,
  IfinancesResponseGraphGroup,
  IRequestList,
  IRequestGraphDataGroup,
  IfinancesGraphTotals,
  IRequestGraphDataTotals,
  IfinancesResponseDetails,
  IfinancesResponseGraphUnit
} from './types'

const useFinancesList = () => {
  const [data, setData] = useState<IfinancesResponseList | null>(null)
  const [loading, setLoading] = useState(false)

  const mutate = async (data: IRequestList) => {
    try {
      setData(null)
      setLoading(true)
      const response = await api.get<IfinancesResponseList>(endpoints.finances.listAll(data))
      setData(response?.data)
    } finally {
      setLoading(false)
    }
  }

  return { mutate, data, loading }
}

const useFinancesDetails = () => {
  const [data, setData] = useState<IfinancesDetails | null>(null)
  const [loading, setLoading] = useState(false)

  const mutate = async (InvoiceId: number) => {
    try {
      setData(null)
      setLoading(true)
      const response = await api.get<IfinancesResponseDetails>(endpoints.finances.details({ InvoiceId }))
      setData(response?.data?.data)
    } finally {
      setLoading(false)
    }
  }

  return { mutate, data, loading }
}

const useFinancesGraphDataGroup = () => {
  const [data, setData] = useState<IfinancesResponseGraphGroup | null>(null)
  const [loading, setLoading] = useState(false)

  const mutate = async (data: IRequestGraphDataGroup) => {
    try {
      setData(null)
      setLoading(true)
      const response = await api.get<IfinancesResponseGraphGroup>(
        endpoints.finances.graphDataGroup(data)
      )
      setData(response?.data)
    } finally {
      setLoading(false)
    }
  }

  return { mutate, data, loading }
}

const useFinancesGraphDataUnit = () => {
  const [data, setData] = useState<IfinancesResponseGraphUnit | null>(null)
  const [loading, setLoading] = useState(false)

  const mutate = async (data: IRequestGraphDataTotals) => {
    try {
      setData(null)
      setLoading(true)
      const response = await api.get<IfinancesResponseGraphUnit>(endpoints.finances.graphDataUnit(data))
      setData(response?.data)
    } finally {
      setLoading(false)
    }
  }

  return { mutate, data, loading }
}

const useGraphTotals = () => {
  const [data, setData] = useState<IfinancesGraphTotals | null>(null)
  const [loading, setLoading] = useState(false)

  const mutate = async (data: IRequestGraphDataTotals) => {
    try {
      setData(null)
      setLoading(true)
      const response = await api.get<IfinancesGraphTotals>(endpoints.finances.graphTotals(data))
      setData(response?.data)
    } finally {
      setLoading(false)
    }
  }

  return { mutate, data, loading }
}

export {
  useFinancesList,
  useFinancesDetails,
  useFinancesGraphDataGroup,
  useFinancesGraphDataUnit,
  useGraphTotals
}
