type Props = {
  role?: string
}

export const IconVisibilityOn = ({ role }: Props) => {
  return (
    <svg
      role={role}
      width="24"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.926 1 1 7.667 1 9C1 10.333 5.926 17 12 17C18.074 17 23 10.333 23 9C23 7.667 18.074 1 12 1Z"
        stroke="#050922"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12C10.343 12 9 10.657 9 9C9 7.343 10.343 6 12 6C13.657 6 15 7.343 15 9C15 10.657 13.657 12 12 12Z"
        stroke="#050922"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
