import { Box } from 'shared/designSystem/components/Box'
import { SubjectOutlinedIcon } from 'shared/designSystem/components/Icons/SubjectOutlinedIcon'
import { WindowOutlinedIcon } from 'shared/designSystem/components/Icons/WindowOutlinedIcon'
import { InputAdornment } from 'shared/designSystem/components/InputAdornment'
import { MenuItem } from 'shared/designSystem/components/MenuItem'
import { Select } from 'shared/designSystem/components/Select'
import { ArrowDown } from 'shared/designSystem/Icons/ArrowDown'
import { useFinancialStore } from 'shared/store/financial'
import {
  ButtonsBox,
  Container,
  ContainerContent,
  ContainerValueInput,
  SelectValueYear,
  SliderBackground,
  ToggleButton
} from './styles'
import useHeaderTabs from './useHeaderTabs'
import { theme } from 'shared/theme'

export interface HeaderTabsProps {
  tabItem: number
}
const HeaderTabs = ({ tabItem }: HeaderTabsProps) => {
  const { renderTabs, renderTabsFilterHistory, handleChange, filters, globalDate } = useHeaderTabs()
  const {
    state: { isTable },
    actions: { setIsTable }
  } = useFinancialStore()

  const isUnit = filters && filters.hasOwnProperty('unit')

  return (
    <>
      <Container>
        <ContainerContent container spacing={2}>
          <ButtonsBox>
            <SliderBackground isTable={isTable} />
            <ToggleButton isActive={!isTable} onClick={() => setIsTable(false)}>
              <WindowOutlinedIcon color={isTable ? 'inherit' : 'primary'} />
            </ToggleButton>
            <ToggleButton isActive={isTable} onClick={() => setIsTable(true)}>
              <SubjectOutlinedIcon color={isTable ? 'primary' : 'inherit'} />
            </ToggleButton>
          </ButtonsBox>

          {tabItem === 6 && !isUnit && renderTabsFilterHistory}
        </ContainerContent>
        {((tabItem === 6 && !isUnit) || (tabItem === 2 && isUnit)) && (
          <Select
            size="small"
            variant="outlined"
            value={filters?.yearF}
            onChange={handleChange}
            displayEmpty
            IconComponent={() => null}
            renderValue={(selected) => (
              <ContainerValueInput>
                <SelectValueYear>{selected || 'Ano'}</SelectValueYear>
                <InputAdornment position="end">
                  <ArrowDown sx={{ cursor: 'pointer' }} />
                </InputAdornment>
              </ContainerValueInput>
            )}
            sx={{
              height: theme.designSystem.size[32],
              width: theme.designSystem.size[90],
              borderRadius: theme.designSystem.size[40],
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: `0 ${theme.designSystem.size[8]}px`,
              marginTop: '18px'
            }}
          >
            {globalDate?.filterDateList?.map((item) => (
              <MenuItem key={item?.year} value={item?.year?.toString()}>
                {item?.year}
              </MenuItem>
            ))}
          </Select>
        )}
      </Container>

      {((tabItem === 6 && !isUnit) || (tabItem === 2 && isUnit)) && <Box>{renderTabs}</Box>}
    </>
  )
}

export default HeaderTabs
