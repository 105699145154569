import { ArcElement, Chart as ChartJS, Legend as LegendChart, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Doughnut } from 'react-chartjs-2'
import { Box } from 'shared/designSystem/components/Box'
import { Legend } from '../../Legend'
import { GraphBox, GraphContainer } from 'pages/Reports/styles'
import { GraphPropsTotal } from 'pages/Reports/types/GraphProps'
import { memo } from 'react'
import { HeaderReports } from '../../Header'
import { EmptyData } from '../../EmptyData'
import useTotalGraphEnergy from './useTotalGraphEnergy'

ChartJS.register(ArcElement, Tooltip, LegendChart, ChartDataLabels)

const TotalGraphEnergy = ({ variant, dataInfos, dataInfosHeader }: GraphPropsTotal) => {
  const {
    isModalVariant,
    data,
    options,
    textCenter,
    legends,
    hasMonth,
    dataInfosHeaderDetail,
    hasData
  } = useTotalGraphEnergy({
    variant,
    dataInfos,
    dataInfosHeader
  })

  return (
    <GraphBox isModalVariant={isModalVariant}>
      <HeaderReports
        infos={hasMonth ? dataInfosHeaderDetail : dataInfosHeader}
        graphic={3}
        graphicName="Centro de Custos"
      />

      {hasData ? (
        <>
          <GraphContainer isModalVariant={isModalVariant}>
            <Doughnut
              data={data}
              options={options}
              style={{ margin: 'auto', height: '100%', width: '50%' }}
              plugins={[textCenter]}
            />
          </GraphContainer>

          <Box display="flex" flexWrap="wrap" gap={1} width="90%" sx={{ mt: 2 }}>
            {legends.map((item, index) => (
              <Legend key={index} color={item?.color} text={item?.text} variant={item?.variant} />
            ))}
          </Box>
        </>
      ) : (
        <EmptyData text="Não há dados disponíveis para a visualização do gráfico." />
      )}
    </GraphBox>
  )
}

export default memo(TotalGraphEnergy)
