export const buildQueryString = (params: object = {}, duplicateParams?: boolean): string => {
  const validParams = Object.entries(params).filter(
    ([key, value]) => !!key && value !== '' && value != null && value != 0 && value != '0'
  )

  if (!validParams.length) return ''

  const keyValuedParams: string[] = []

  validParams.forEach(([key, value]) => {
    if (Array.isArray(value) && duplicateParams) {
      value.forEach((item) => {
        keyValuedParams.push(`${key}=${encodeURIComponent(item.toString())}`)
      })
    } else {
      keyValuedParams.push(`${key}=${encodeURIComponent(value.toString())}`)
    }
  })

  return `?${keyValuedParams.join('&')}`
}

export const createQueryString = (filters: Record<string, any>): string => {
  const queryParams = Object.entries(filters)
    .filter(([_, value]) => value !== undefined && value !== null && value !== '')
    .map(([key, value]) =>
      Array.isArray(value)
        ? value.map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&')
        : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&')

  return queryParams
}
