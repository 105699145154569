import { TOKEN_KEY } from 'shared/services/Api'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AlertSnackbar } from 'shared/designSystem/components/AlertSnackbar'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { useTheme } from 'shared/hook/useTheme'
import { useApiCode, useApiLogin } from 'shared/services/Requests/Login'
import { useAuthStore } from 'shared/store/auth'
import { DotsLoader } from '../../../../shared/designSystem/components/DotsLoader'
import { ButtonUnderline, DividerContainer, TextFieldInput } from '../../styles'
import { PasswordRecoveryButton } from '../PasswordRecoveryButton'
import { RecoveryTitle } from '../PasswordRecoveryTitle'
import { BoxChildren, BoxCircleTimer, BoxParent, BoxResendCode } from '../styles'

type Props = {
  handleClose: () => void
  cpf: string
  password: string
}

export const SecurityValidation = ({ handleClose, cpf, password }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { actions } = useAuthStore()
  const { mutateCode, loading } = useApiCode()
  const { mutateLogin, loading: codeLoading } = useApiLogin()

  const [error, setError] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [securityCode, setSecurityCode] = useState('')
  const [rawSecurityCode, setRawSecurityCode] = useState('')
  const [securityCodeError, setSecurityCodeError] = useState('')
  const [timer, setTimer] = useState<number>(0)
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false)
  const [submitErrorSnackbarOpen, setSubmitErrorSnackbarOpen] = useState(false)

  const handleSecurityCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawCode = e.target.value.replace(/\D/g, '')
    const formattedCode = rawCode.replace(/(\d{3})(\d+)/, '$1 $2')
    setSecurityCode(formattedCode)
    setRawSecurityCode(rawCode)
  }

  const handleResendCode = async () => {
    setError('')
    setSecurityCodeError('')

    try {
      const success = await mutateLogin(cpf, password)

      if (success) {
        setTimer(60)
        setAlertOpen(true)
      } else {
        setError('Falha ao reenviar código')
      }
    } catch (error) {
      handleResendError(error)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const validateSecurityCode = () => {
    if (!rawSecurityCode) return 'Este campo é obrigatório'
    if (rawSecurityCode.length !== 6) return 'Código deve ter 6 números'
    return ''
  }

  const handleResendError = (error: unknown) => {
    if (error instanceof Error) {
      setErrorSnackbarOpen(true)
    }
  }

  // TODO: utilizar erros personalizados em breve
  /* const handleApiError = (error: any) => {
    const apiErrorMessage = error.response?.data?.message

    if (apiErrorMessage === 'Invalid Token.' || apiErrorMessage === 'Expired Token.') {
      setSecurityCodeError(getErrorMessage(apiErrorMessage))
      return true
    }

    setSubmitErrorSnackbarOpen(true)
    return false
  } */

  const handleSubmit = async () => {
    setSecurityCodeError('')

    const validationError = validateSecurityCode()
    if (validationError) {
      setSecurityCodeError(validationError)
      return
    }

    const result = await mutateCode(cpf, rawSecurityCode)

    if (result.success) {
      localStorage.setItem(TOKEN_KEY, result.accessToken)
      actions.signIn()
      navigate('/home')
    }
    setSecurityCodeError(error || 'Erro ao validar o código.')
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prev) => prev - 1)
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }, [timer])

  return (
    <Box height="100vh" mt={20}>
      <BoxParent>
        <RecoveryTitle text="Validação de segurança" handleClose={handleClose} />
        <DividerContainer />

        <BoxChildren>
          <Typography
            fontWeight={theme.designSystem.typography.fontWeight.regular}
            fontSize={theme.designSystem.typography.fontSize.body2}
            color={theme.designSystem.base[500]}
          >
            Para continuar, informe o código de segurança que foi enviado para o e-mail de cadastro.
          </Typography>

          <Box>
            <TextFieldInput
              variant="standard"
              label="Código de segurança"
              fullWidth
              value={securityCode}
              onChange={handleSecurityCodeChange}
              error={!!securityCodeError}
              onKeyDown={handleKeyDown}
              slotProps={{
                htmlInput: {
                  minLength: 6,
                  maxLength: 7
                }
              }}
            />
            {securityCodeError && (
              <Typography color={theme.designSystem.deepOrange[500]} variant="caption">
                {securityCodeError}
              </Typography>
            )}
          </Box>

          <BoxResendCode>
            <ButtonUnderline disabled={timer > 0} onClick={handleResendCode} sx={{ mr: 3 }}>
              Reenviar código
            </ButtonUnderline>

            <BoxCircleTimer display={timer > 0 ? 'flex' : 'none'}>
              <Typography color={theme.designSystem.base[400]}>{timer > 0 ? timer : ''}</Typography>
            </BoxCircleTimer>
          </BoxResendCode>

          <PasswordRecoveryButton onClick={handleSubmit} role="button">
            {loading || codeLoading ? (
              <DotsLoader />
            ) : error || alertOpen ? (
              'Tentar novamente'.toUpperCase()
            ) : (
              'Confirmar'.toUpperCase()
            )}
          </PasswordRecoveryButton>
        </BoxChildren>
      </BoxParent>

      <AlertSnackbar
        type="success"
        open={alertOpen}
        onClose={handleAlertClose}
        title="Código enviado"
        message="Verifique o celular cadastrado"
      />
      <AlertSnackbar
        type="error"
        open={errorSnackbarOpen}
        onClose={() => setErrorSnackbarOpen(false)}
        title="Falha ao reenviar código"
        message="Tente novamente"
      />
      <AlertSnackbar
        type="error"
        open={submitErrorSnackbarOpen}
        onClose={() => setSubmitErrorSnackbarOpen(false)}
        title="Erro ao continuar"
        message="Tente novamente"
      />
    </Box>
  )
}
