import { useEffect, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { InputAdornment } from 'shared/designSystem/components/InputAdornment'
import { Menu } from 'shared/designSystem/components/Menu'
import { MenuItem } from 'shared/designSystem/components/MenuItem'
import { Stack } from 'shared/designSystem/components/Stack'
import { TextField } from 'shared/designSystem/components/TextField'
import { ArrowDown } from 'shared/designSystem/Icons/ArrowDown'
import { ArrowRight } from 'shared/designSystem/Icons/ArrowRight'
import { ArrowUp } from 'shared/designSystem/Icons/ArrowUp'
import useFilters from 'shared/hook/useFilters'
import useFiltersGlobal from 'shared/hook/useFiltersGlobal'
import { TypeComboGroup } from 'shared/models/Filters'

const MultiLevelSelectProduct = ({ handleFetchData, filtersModal, setFiltersModal }: any) => {
  const { globalProducts } = useFiltersGlobal()
  const { filters } = useFilters('filtersAll')

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null)
  const [comboGroups, setComboGroups] = useState<TypeComboGroup[]>([])

  const isMenuOpen = Boolean(anchorEl)

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event?.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSubmenuAnchorEl(null)
  }

  const handleCloseSubmenu = () => {
    setSubmenuAnchorEl(null)
  }

  const handleProductClick = (product: number, event: any) => {
    setFiltersModal({ ...filtersModal, product: product, group: '', unit: '' })
    setSubmenuAnchorEl(event?.currentTarget)
  }

  const handleGroupClick = (group: number | string, unit: number | string) => {
    const dataFilters = {
      year: filtersModal?.year,
      productId: filtersModal?.product,
      groupId: group,
      unitId: unit
    }

    setFiltersModal({ ...filtersModal, group: group, unit: unit })
    handleCloseMenu()

    handleFetchData(dataFilters)
  }

  const handleInitialParams = () => {
    if (filtersModal?.product) {
      const datagroup = globalProducts?.find((item: any) => item.value == filtersModal?.product)
      if (datagroup) setComboGroups(datagroup?.groups)
    }
  }

  const handleTitle = () => {
    if (filtersModal?.unit) {
      const dataGroup = comboGroups.find((item: any) => item.value == filtersModal?.group)
      const dataUnit = dataGroup?.units?.find((item: any) => item.value == filtersModal?.unit)
      return dataUnit?.name
    }

    if (filtersModal?.group) {
      const dataGroup = comboGroups.find((item: any) => item.value == filtersModal?.group)
      return dataGroup?.name
    }
    if (filtersModal?.product) {
      const dataProduct = globalProducts?.find((item: any) => item.value == filtersModal?.product)
      return dataProduct?.name
    }

    return 'Selecione um produto'
  }

  useEffect(() => {
    handleInitialParams()
  }, [filtersModal])

  return (
    <>
      <TextField
        size="small"
        onClick={handleOpenMenu}
        variant="outlined"
        value={handleTitle()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isMenuOpen ? (
                <ArrowUp sx={{ cursor: 'pointer' }} />
              ) : (
                <ArrowDown sx={{ cursor: 'pointer' }} />
              )}
            </InputAdornment>
          ),
          style: { fontWeight: 'bold', cursor: 'pointer' }
        }}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
        }}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiMenu-paper': {
            width: anchorEl ? `${anchorEl.offsetWidth}px` : 'auto',
            height: 'auto',
            border: 'none',
            borderRadius: '0',
            marginBottom: '10px',
            borderTop: '1px solid #E0E0E0'
          }
        }}
      >
        {globalProducts
          ?.filter((item) => item.value === Number(filters?.product))
          .map((item, index) => (
            <MenuItem
              key={index}
              sx={{ fontWeight: filtersModal?.product == item.value ? 'bold' : '' }}
              onClick={(event) => handleProductClick(item.value, event)}
            >
              <Stack
                display="flex"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <div>{item.name}</div>
                <div>{item?.groups?.length > 0 && <ArrowRight />}</div>
              </Stack>
            </MenuItem>
          ))}
      </Menu>

      <Menu
        anchorEl={submenuAnchorEl}
        open={Boolean(submenuAnchorEl)}
        onClose={handleCloseSubmenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{
          '& .MuiMenu-paper': {
            width: 'auto',
            maxHeight: '201px',
            border: 'none',
            marginBottom: '10px',
            borderTop: '1px solid #E0E0E0',
            borderRadius: 0,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          },
          '& .MuiList-root': {
            padding: 0
          }
        }}
      >
        <Box
          sx={{
            maxHeight: '200px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#05092229',
              borderRadius: '4px'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1'
            }
          }}
        >
          {comboGroups.map((itemSub, index) => (
            <Box key={itemSub.value}>
              <MenuItem
                onClick={() => handleGroupClick(itemSub.value, '')}
                sx={{
                  fontSize: '14px',
                  padding: '8px 15px ',
                  margin: index === 0 ? '20px 0' : '0',
                  color: index === 0 ? '#05092299' : 'inherit',
                  '&:hover': {
                    backgroundColor: index === 0 ? '#ddd' : 'transparent'
                  }
                }}
              >
                <b>{itemSub.name}</b>
              </MenuItem>
              {itemSub.units.map((unit) => (
                <MenuItem
                  key={unit.value}
                  onClick={() => handleGroupClick(itemSub.value, unit.value)}
                  sx={{
                    fontSize: '14px',
                    marginTrim: 'none',
                    paddingBottom: '20px '
                  }}
                >
                  {unit.name}
                </MenuItem>
              ))}
            </Box>
          ))}
        </Box>
      </Menu>
    </>
  )
}

export default MultiLevelSelectProduct
