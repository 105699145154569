import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { theme } from 'shared/theme'

type Props = {
  values: {
    genialCost: number
    economyValue: number
    traditionalCost: number
  }
}

export const Legend = ({ values }: Props) => {
  const { genialCost, economyValue, traditionalCost } = values

  const getLegendColor = (value: number, color: string): string =>
    value > 0 ? color : theme.designSystem.base[100]

  return (
    <Box display="flex" justifyContent="space-between" mt={4}>
      <Box display="flex" alignItems="center">
        <Box
          mr={1}
          sx={{
            width: theme.designSystem.size[12],
            height: theme.designSystem.size[12],
            bgcolor: getLegendColor(genialCost, theme.designSystem.primary[500])
          }}
        />
        <Typography
          fontSize={theme.designSystem.size[12]}
          fontWeight={theme.designSystem.typography.fontWeight.medium}
        >
          Custo na Genial
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            width: theme.designSystem.size[12],
            height: theme.designSystem.size[12],
            bgcolor: getLegendColor(economyValue, theme.designSystem.primary[50])
          }}
          mr={1}
        />
        <Typography
          fontSize={theme.designSystem.size[12]}
          fontWeight={theme.designSystem.typography.fontWeight.medium}
        >
          Sua economia
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box
          mr={1}
          sx={{
            width: theme.designSystem.size[12],
            height: theme.designSystem.size[12],
            bgcolor: getLegendColor(traditionalCost, theme.designSystem.primary[200])
          }}
        />
        <Typography
          fontSize={theme.designSystem.size[12]}
          fontWeight={theme.designSystem.typography.fontWeight.medium}
        >
          Custo tradicional
        </Typography>
      </Box>
    </Box>
  )
}
