import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'

import { getMonthAbbreviations } from 'shared/utils/dates'
import { Header } from './Header'
import useDataGraphUnit from 'pages/Financial/hooks/useDataGraphUnit'
import { Box } from 'shared/designSystem/components/Box'
import { useFinancialStore } from 'shared/store/financial'
import { formatCurrency } from 'shared/utils/formatCurrency'
import LoadingContentAdvanced from 'shared/designSystem/components/LoadingContentAdvanced'

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip)

const TabDataUnit = () => {
  const { data: dataUnit, loading } = useDataGraphUnit()
  const [selectedItems, setSelectedItems] = useState<any[]>([])

  const {
    state: { selectGroups }
  } = useFinancialStore()

  useEffect(() => {
    const unity = dataUnit?.data?.[0]?.units?.[0] || null

    setSelectedItems([
      {
        label: unity?.unity || 'Unidade',
        data: unity?.invoices?.map((item) => item.value),
        fill: false,
        tension: 0,
        pointRadius: 5,
        pointHoverRadius: 7,
        borderWidth: 2,
        borderColor: '#192488',
        backgroundColor: '#192488',
        pointBackgroundColor: '#192488'
      }
    ])
  }, [dataUnit, selectGroups])

  const chartData = {
    labels: getMonthAbbreviations(dataUnit?.data?.[0]?.units?.[0]?.invoices?.length),
    datasets: selectedItems
  }

  const options: any = {
    responsive: true,
    animation: false,
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
      tooltip: {
        enabled: true,
        displayColors: false,
        mode: 'nearest',
        intersect: true,
        callbacks: {
          title: function (tooltipItems: any) {
            const monthYear = tooltipItems[0]?.label || ''
            return monthYear
          },
          label: function (item: any) {
            const dataTwo = `${dataUnit?.data?.[0]?.units?.[0].invoices?.[item?.dataIndex]?.unity} ${formatCurrency(dataUnit?.data?.[0]?.units?.[0]?.invoices?.[item?.dataIndex]?.value || 0)}`
            return dataTwo
          }
        },
        bodyFont: {
          size: 14,
          weight: 'normal',
          family: 'Arial',
          lineHeight: 2.5
        },
        titleFont: {
          size: 16,
          weight: 'bold',
          family: 'Arial',
          lineHeight: 2
        },
        backgroundColor: '#ffffff',
        borderColor: ' #0000000F',
        borderWidth: 1,
        padding: 10,
        boxWidth: 10,
        caretSize: 5,
        titleColor: '#050922',
        bodyColor: '#05092261'
      }
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        border: {
          display: false
        }
      }
    }
  }

  return (
    <LoadingContentAdvanced loading={loading} noData={!dataUnit}>
      <Header />
      <Box>
        <Line data={chartData} options={options} style={{ paddingBottom: '20px' }} />
      </Box>
    </LoadingContentAdvanced>
  )
}

export default TabDataUnit
