import debounce from 'lodash/debounce'
import { useCallback, useEffect } from 'react'
import useFilters from 'shared/hook/useFilters'
import { useFinancesGraphDataGroup } from 'shared/services/Requests/finances'
import { IRequestList } from 'shared/services/Requests/finances/types'
import { useFinancialStore } from 'shared/store/financial'

const useDataGraph = () => {
  const { filters } = useFilters('filtersAll')
  const {
    state: { selectGroup, selectUnit }
  } = useFinancialStore()

  const { mutate, data, loading } = useFinancesGraphDataGroup()

  const debouncedMountRequest = useCallback(
    debounce(() => {
      const requestData: IRequestList = {
        Year: filters?.yearF || new Date().getFullYear(),
        Month: 1
      }

      if ((selectGroup && selectGroup !== 0) || (filters?.group && filters?.group !== '0'))
        requestData.GroupId = selectGroup || filters?.group

      mutate(requestData)
    }, 300),
    [filters?.yearF, filters?.group, selectGroup, filters?.unit, selectUnit]
  )

  useEffect(() => {
    debouncedMountRequest()
    return () => {
      debouncedMountRequest.cancel()
    }
  }, [debouncedMountRequest])

  return { data, loading }
}

export default useDataGraph
