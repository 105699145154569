import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const ContainerContentPayment = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  gap: 0.3,
  alignItems: 'flex-start',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 20
  }
}))
