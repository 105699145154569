import { ActivationStatus } from 'pages/MigrationSteps/models/ActivationStatus'
import { useState } from 'react'
import { Unity } from 'shared/models/Unity'
import { SideCard } from '../SideCard'
import {
  CardBodyTypography,
  CardButton,
  CardContainer,
  CardEnterprise,
  CardFooter,
  CardHeader,
  CardLogo,
  CardName,
  CardNumber
} from './styles'

interface Props {
  role?: string
  cardData: Unity
  listData: ActivationStatus[]
}

export const Card = ({ role, cardData, listData }: Props) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <CardContainer role={role}>
      <CardHeader role="card-header">
        <CardName role="card-name">{cardData.consumerUnitName && cardData.consumerUnitName}</CardName>
        <CardNumber role="card-number">
          {cardData.consumerUnitInstallationNumber && cardData.consumerUnitInstallationNumber}
        </CardNumber>
      </CardHeader>
      <CardBodyTypography role="card-address">
        {cardData.address &&
          `${cardData.address} ${cardData.addressNumber}, ${cardData.addressComplement} - ${cardData.addressNeighborhood}`}
      </CardBodyTypography>
      <CardBodyTypography role="card-cep">
        {cardData.addressZipCode && cardData.addressZipCode}
      </CardBodyTypography>
      <CardFooter role="card-footer">
        <CardEnterprise role="card-enterprise">
          {cardData.clientLogo ? (
            <CardLogo
              role="card-logo"
              src={cardData.clientLogo}
              alt={cardData.clientFullName && cardData.clientFullName}
            />
          ) : (
            <CardLogo
              role="card-logo"
              src={'/public/uclogo.svg'}
              alt={cardData.clientFullName && cardData.clientFullName}
            />
          )}
          <CardBodyTypography role="card-enterprise-name">
            {cardData.consumerUnitName && cardData.consumerUnitName}
          </CardBodyTypography>
        </CardEnterprise>
        <CardButton role="card-button" variant="contained" onClick={handleClickOpen}>
          {cardData.unitTypeName && cardData.unitTypeName}
        </CardButton>
      </CardFooter>

      <SideCard
        role="side-card"
        open={open}
        handleClose={handleClose}
        sideCardData={cardData}
        listData={listData}
      />
    </CardContainer>
  )
}
