import { GenerateCode } from 'pages/FirstAccess/models/GenerateCode'
import { useState } from 'react'
import api from 'shared/services/Api'
import endpoints from 'shared/services/Api/endpoints'

const useApiLogin = () => {
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState<any>(null)

  const mutateLogin = async (cpf: string, password: string) => {
    try {
      setLoading(true)

      const payload = { cpf, password }
      const response = await api.post(endpoints.login.getLogin(), payload)

      if (response.status === 200) {
        setUserData(response.data.user || null)
        return true
      }
      return false
    } catch (err: any) {
      console.error('Erro ao realizar login:', err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  return { mutateLogin, loading, userData }
}

const useApiCode = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [verified, setVerified] = useState(false)

  const mutateCode = async (cpf: string, code: string) => {
    try {
      setLoading(true)
      setError('')

      const payload = { cpf, code }
      const response = await api.post(endpoints.login.getCode(), payload)

      if (response.status === 200) {
        const accessToken = response.data?.data?.accessToken
        if (accessToken) {
          setVerified(true)
          return { success: true, accessToken }
        } else {
          setError('Token não encontrado na resposta.')
          return { success: false }
        }
      } else {
        setError('Código inválido ou resposta inesperada')
        return { success: false }
      }
    } catch (err: any) {
      console.error('Erro ao verificar código:', err)
      setError('Falha ao verificar código')
      return { success: false }
    } finally {
      setLoading(false)
    }
  }

  return { mutateCode, loading, error, verified }
}

const useApiEmail = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [emailExists, setEmailExists] = useState<boolean | null>(null)

  const mutateEmail = async (email: string) => {
    try {
      setLoading(true)
      setError(null)

      const response = await api.get(endpoints.passwordRecovery.getEmail(email))

      if (response.status === 200) {
        setEmailExists(true)
        return true
      } else {
        setEmailExists(false)
        return false
      }
    } catch (err: any) {
      console.error('Erro ao verificar email:', err)
      setError('Falha ao verificar o e-mail.')
      return false
    } finally {
      setLoading(false)
    }
  }

  return { mutateEmail, loading, error, emailExists }
}

const useApiSendSecurityCode = () => {
  const [error, setError] = useState<string | null>(null)

  const mutateSendSecurityCode = async (payload: GenerateCode) => {
    try {
      setError(null)

      const response = await api.post(endpoints.passwordRecovery.sendSecurityCode(), payload)

      if (response.status === 200) {
        return true
      } else {
        setError('Falha ao enviar o código de segurança.')
        return false
      }
    } catch (err: any) {
      console.error('Erro ao enviar código de segurança:', err)
      setError('Erro ao processar a solicitação.')
      return false
    }
  }

  return { mutateSendSecurityCode, error }
}

const useApiResetPassword = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const mutateResetPassword = async (payload: { email: string; code: string; password: string }) => {
    try {
      setLoading(true)
      setError(null)

      const response = await api.put(endpoints.passwordRecovery.resetPassword(), payload)

      if (response.status === 200) {
        return true
      } else {
        setError('Falha ao redefinir a senha.')
        return false
      }
    } catch (err: any) {
      console.error('Erro ao redefinir a senha:', err)
      setError('Erro ao processar a solicitação.')
      return false
    } finally {
      setLoading(false)
    }
  }

  return { mutateResetPassword, loading, error }
}

export { useApiCode, useApiLogin, useApiEmail, useApiSendSecurityCode, useApiResetPassword }
