interface Props {
  width?: number
  height?: number
  color?: string
}
export default function IconArrowRight({ width = 14, height = 12, color = `#050922` }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66667 10.6666L13 5.99992M13 5.99992L7.66667 1.33325M13 5.99992H1"
        stroke={color}
        strokeOpacity={0.38}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
