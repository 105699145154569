import { Box } from 'shared/designSystem/components/Box'
import { Header } from '../../../../DetailsModal/Header'
import { ModalContainer } from '../../../../DetailsModal/styles'
import EconomyAlertGraph from '../../index'
import { PDFDocument } from '../../../../PDFDocument'
import { Loading } from 'shared/designSystem/components/Loading'
import useModalEconomyAlert from './useModal'

export const EconomyAlertModalDetailEnergy = () => {
  const { loading, mutateEconomyAlert, pdfRef, pdfElements, stateDataEconomyAlert } =
    useModalEconomyAlert()
  return (
    <>
      <ModalContainer sx={{ display: 'flex', flexDirection: 'column' }}>
        <Header fetchData={mutateEconomyAlert} pdfElements={pdfElements} pdfRef={pdfRef} />
        <Box sx={{ flexGrow: 1 }}>
          {loading ? (
            <Loading />
          ) : (
            <EconomyAlertGraph dataInfosHeader={[]} variant="modal" dataInfos={stateDataEconomyAlert} />
          )}
        </Box>
      </ModalContainer>
      <Box
        sx={{
          position: 'absolute',
          top: '-9999px',
          width: '270mm',
          height: '367mm',
          overflow: 'hidden',
          visibility: 'hidden'
        }}
        ref={pdfRef}
      >
        {pdfElements.map((pdf, i) => (
          <PDFDocument
            key={i}
            data={pdf.data}
            columns={pdf.columns}
            name=""
            footerTable={pdf?.footerTable}
          >
            {pdf.graph}
          </PDFDocument>
        ))}
      </Box>
    </>
  )
}
