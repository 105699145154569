import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const FooterContainer = styledSystem(Box)(({ theme }) => ({
  height: theme.designSystem.size[72],
  paddingLeft: theme.designSystem.size[24],
  paddingRight: theme.designSystem.size[24],
  textAlign: 'center',
  backgroundColor: theme.designSystem.white,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
}))

export const FooterTypography = styledSystem(Box)(({ theme }) => ({
  height: theme.designSystem.size[24],
  fontWeight: theme.designSystem.typography.fontWeight.regular,
  fontSize: theme.designSystem.typography.fontSize.button,
  align: 'center',
  color: theme.designSystem.base[300],
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex'
}))
