import { Box } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const ErrorCardContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: theme.designSystem.size[32],
  padding: theme.designSystem.size[24]
}))

export const ErrorCircle = styledSystem(Box)(({ theme }) => ({
  width: theme.designSystem.size[48],
  height: theme.designSystem.size[48],
  backgroundColor: theme.designSystem.base[200],
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export const ErrorButton = styledSystem(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    borderColor: theme.designSystem.base[900],
    color: theme.designSystem.base[500],
    borderRadius: theme.designSystem.size[24],
    padding: `${theme.designSystem.size[12]}px ${theme.designSystem.size[32]}px`,
    textTransform: 'uppercase'
  }
}))
