export const getRandomColor = () => {
  const hue = Math.floor(Math.random() * 360)
  const saturation = '70%'
  const lightness = '50%'
  return `hsl(${hue}, ${saturation}, ${lightness})`
}

export const hslToRgba = (hsl: string, opacity: number) => {
  const regex = /hsl\((\d+), (\d+)%?, (\d+)%?\)/
  const result = hsl.match(regex)
  if (result) {
    const hue = parseInt(result[1])
    const saturation = parseInt(result[2]) / 100
    const lightness = parseInt(result[3]) / 100

    const c = (1 - Math.abs(2 * lightness - 1)) * saturation
    const x = c * (1 - Math.abs(((hue / 60) % 2) - 1))
    const m = lightness - c / 2

    let r, g, b

    if (hue >= 0 && hue < 60) {
      r = c
      g = x
      b = 0
    } else if (hue >= 60 && hue < 120) {
      r = x
      g = c
      b = 0
    } else if (hue >= 120 && hue < 180) {
      r = 0
      g = c
      b = x
    } else if (hue >= 180 && hue < 240) {
      r = 0
      g = x
      b = c
    } else if (hue >= 240 && hue < 300) {
      r = x
      g = 0
      b = c
    } else {
      r = c
      g = 0
      b = x
    }

    r = Math.round((r + m) * 255)
    g = Math.round((g + m) * 255)
    b = Math.round((b + m) * 255)

    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }
  return hsl
}
