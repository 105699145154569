import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const TypographyBody = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.body1,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[900]
}))

export const TypographyErrorButton = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.button,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[900]
}))
