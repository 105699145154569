import { Box } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { Grid } from 'shared/designSystem/components/Grid'
import { Tabs } from 'shared/designSystem/components/Tabs'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'
import { theme } from 'shared/theme'

export const Container = styledSystem(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
}))

export const ContainerContent = styledSystem(Grid)(() => ({
  padding: '18px 0'
}))

export const ContainerValueInput = styledSystem(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const SelectValueYear = styledSystem(Box)(() => ({
  fontWeight: 'bold',
  cursor: 'pointer'
}))

export const TabsStyles = styledSystem(Tabs)(() => ({
  marginBottom: '20px',
  borderBottom: '1px solid #05092261',
  width: '100%',
  '& .MuiTabs-scroller': {
    overflow: 'scroll !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

export const ButtonsBox = styledSystem(Box)({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  borderRadius: theme.designSystem.size[40],
  padding: `${theme.designSystem.size[2]}px ${theme.designSystem.size[4]}px`,
  backgroundColor: theme.designSystem.white,
  border: `1px solid ${theme.designSystem.base[200]}`,
  gap: theme.designSystem.size[3]
})

export const SliderBackground = styledSystem(Box)(({ isTable }: { isTable: boolean }) => ({
  position: 'absolute',
  width: theme.designSystem.size[32],
  height: theme.designSystem.size[24],
  borderRadius: theme.designSystem.size[40],
  backgroundColor: '#000',
  transform: isTable ? 'translateX(36px)' : 'translateX(0)',
  transition: 'transform 0.3s ease',
  zIndex: 0
}))

export const ToggleButton = styledSystem(Button)(({ isActive }: { isActive: boolean }) => ({
  position: 'relative',
  zIndex: 1,
  minWidth: theme.designSystem.size[32],
  minHeight: theme.designSystem.size[24],
  color: isActive ? theme.designSystem.white : '#000',
  backgroundColor: 'transparent'
}))
