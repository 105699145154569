import { useEffect, useMemo, useRef, useState } from 'react'
import useFilters from 'shared/hook/useFilters'
import { columnsCreditBalance } from 'shared/mocks/columnsPdf'
import { useApiCreditBalance } from 'shared/services/Requests/Reports'
import { ReportCreditBalance } from 'shared/services/Requests/Reports/types'
import { useReportStore } from 'shared/store/reports'
import CreditBalanceGraph from '../..'

const useModalCreditBalance = () => {
  const { filters } = useFilters('filtersAll')
  const { mutateCreditBalance, apiCreditBalance, loading } = useApiCreditBalance()
  const {
    state: { graphic }
  } = useReportStore()

  const [isExpanded, setIsExpanded] = useState(false)
  const [stateDataCreditBalance, setStateDataCreditBalance] = useState<ReportCreditBalance | null>(
    apiCreditBalance
  )

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const pdfRef = useRef(null)

  const infosHeaderReport = [
    {
      titleValue: 'Média de crédito injetado no ano',
      value: stateDataCreditBalance?.dataGraphic?.averageCreditInjectedYear || '0KWh',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    },
    {
      titleValue: 'Média de crédito consumido no ano',
      value: stateDataCreditBalance?.dataGraphic?.averageCreditConsumedYear || '0KWh',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    },
    {
      titleValue: 'Média de utilização no ano',
      value: stateDataCreditBalance?.dataGraphic?.averageUsePerYear || '0KWh',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    }
  ]

  const pdfElements = useMemo(
    () => [
      {
        graph: (
          <CreditBalanceGraph
            variant="modal"
            dataInfos={stateDataCreditBalance}
            dataInfosHeader={infosHeaderReport}
          />
        ),
        data: stateDataCreditBalance?.dataTable,
        columns: columnsCreditBalance,
        footerTable: stateDataCreditBalance?.tableTotals
      }
    ],
    [stateDataCreditBalance]
  )

  useEffect(() => {
    if (graphic === 6 && filters?.year)
      mutateCreditBalance({
        year: filters?.year,
        productId: filters?.product,
        groupId: filters?.group,
        unitId: filters?.unit
      })
  }, [graphic, filters])

  useEffect(() => {
    setStateDataCreditBalance(apiCreditBalance)
  }, [apiCreditBalance])

  return {
    mutateCreditBalance,
    pdfRef,
    pdfElements,
    loading,
    stateDataCreditBalance,
    infosHeaderReport,
    isExpanded,
    handleToggleExpand
  }
}

export default useModalCreditBalance
