import { Footer } from 'pages/Home/components/Footer'
import { useState } from 'react'
import { Grid } from 'shared/designSystem/components/Grid'
import { theme } from 'shared/theme'
import { AppBar } from './components/AppBar'
import Board from './components/Board'
import { FooterCustom, MainContainer } from './styles'

const MigrationSteps = () => {
  const [group, setGroup] = useState('TodosOsGrupos')
  const [boardKey, setBoardKey] = useState(0)

  const handleGroupChange = (newGroup: string) => {
    setGroup(newGroup)
    setBoardKey((prevKey) => prevKey + 1)
  }

  return (
    <MainContainer role="main-container">
      <AppBar role="app-bar" selectedGroup={group} onGroupChange={handleGroupChange} />
      <Board role="board" selectedGroup={group} key={boardKey} />
      <FooterCustom>
        <Grid width="100vw" marginBottom={theme.designSystem.size[2]}>
          <Footer />
        </Grid>
      </FooterCustom>
    </MainContainer>
  )
}

export default MigrationSteps
