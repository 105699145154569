import { create } from 'zustand'
import { IndexModal, StoreProps } from './types'
import { RefObject } from 'react'
import {
  ReportDataCO2,
  ReportDataConsumer,
  ReportDataTotal,
  ReportDataEconomy,
  ReportDataEconomyAlert,
  ReportCreditBalance
} from 'shared/services/Requests/Reports/types'

export const useReportStore = create<StoreProps>((set) => ({
  state: {
    isOpen: false,
    graphic: 0,
    graphicName: '',
    pdfRef: null,
    filterDefault: false,
    dataEconomy: null,
    dataConsumer: null,
    dataEconomyAlert: null,
    dataCO2: null,
    dataTotal: null,
    dataFlag: null,
    dataCreditBalance: null
  },
  actions: {
    openModal: (graphic: IndexModal, graphicName: string, isOpen: boolean) => {
      set((store) => ({
        state: { ...store.state, graphic, graphicName, isOpen }
      }))
    },
    closeModal: () => {
      set((store) => ({
        state: { ...store.state, graphic: 0, isOpen: false }
      }))
    },
    setPdfRef: (pdfRef: RefObject<HTMLDivElement>) => {
      set((store) => ({
        state: { ...store.state, pdfRef }
      }))
    },
    setFilterDefault: (isVisible: boolean) => {
      set((store) => ({
        state: { ...store.state, filterDefault: isVisible }
      }))
    },
    setDataEconomy: (dataEconomy: ReportDataEconomy | null) => {
      set((store) => ({
        state: { ...store.state, dataEconomy }
      }))
    },
    setDataConsumer: (dataConsumer: ReportDataConsumer | null) => {
      set((store) => ({
        state: { ...store.state, dataConsumer }
      }))
    },
    setDataEconomyAlert: (dataEconomyAlert: ReportDataEconomyAlert | null) => {
      set((store) => ({
        state: { ...store.state, dataEconomyAlert }
      }))
    },
    setDataCO2: (dataCO2: ReportDataCO2 | null) => {
      set((store) => ({
        state: { ...store.state, dataCO2 }
      }))
    },
    setDataFlag: (dataFlag: ReportDataCO2 | null) => {
      set((store) => ({
        state: { ...store.state, dataFlag }
      }))
    },
    setDataTotal: (dataTotal: ReportDataTotal | null) => {
      set((store) => ({
        state: { ...store.state, dataTotal }
      }))
    },
    setDataCreditBalance: (dataCreditBalance: ReportCreditBalance | null) => {
      set((store) => ({
        state: { ...store.state, dataCreditBalance }
      }))
    },
    setDataReportsReset: () => {
      set((store) => ({
        state: {
          ...store.state,
          dataEconomy: null,
          dataConsumer: null,
          dataEconomyAlert: null,
          dataCO2: null,
          dataTotal: null,
          dataFlag: null,
          dataCreditBalance: null
        }
      }))
    }
  }
}))
