import { useEffect, useMemo, useRef, useState } from 'react'
import useFilters from 'shared/hook/useFilters'
import { columnsConsumeGraphSolar } from 'shared/mocks/columnsPdf'
import { useApiConsumer } from 'shared/services/Requests/Reports'
import { ReportDataConsumer } from 'shared/services/Requests/Reports/types'
import { useReportStore } from 'shared/store/reports'
import ConsumeGraph from '../../index'

const useModalConsumeSolar = () => {
  const { filters } = useFilters('filtersAll')
  const { mutateConsumer, apiConsumer, loading } = useApiConsumer()
  const {
    state: { graphic }
  } = useReportStore()

  const [isExpanded, setIsExpanded] = useState(false)
  const [stateDataConsume, setStateDataConsume] = useState<ReportDataConsumer | null>(apiConsumer)

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const pdfRef = useRef(null)

  const infosHeaderReport = [
    {
      titleValue: 'Média de energia consumida no ano',
      value: stateDataConsume?.dataGraphic?.averageEnergyConsumedYear || '0KWh',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    },
    {
      titleValue: 'Média de energia compensada no ano',
      value: stateDataConsume?.dataGraphic?.averageConsumptionYear || '0KWh',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    }
  ]

  const pdfElements = useMemo(
    () => [
      {
        graph: (
          <ConsumeGraph
            variant="modal"
            dataInfos={stateDataConsume}
            dataInfosHeader={infosHeaderReport}
          />
        ),
        data: stateDataConsume?.dataTable,
        columns: columnsConsumeGraphSolar,
        footerTable: stateDataConsume?.tableTotals
      }
    ],
    [stateDataConsume]
  )

  useEffect(() => {
    if (graphic === 1 && filters?.year)
      mutateConsumer({
        year: filters?.year,
        productId: filters?.product,
        groupId: filters?.group,
        unitId: filters?.unit
      })
  }, [graphic, filters])

  useEffect(() => {
    setStateDataConsume(apiConsumer)
  }, [apiConsumer])

  return {
    mutateConsumer,
    pdfRef,
    pdfElements,
    loading,
    stateDataConsume,
    infosHeaderReport,
    isExpanded,
    handleToggleExpand
  }
}

export default useModalConsumeSolar
