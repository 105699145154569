import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const BoxContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  boxShadow: theme.shadows[3],
  padding: theme.spacing(3),
  width: 456,
  height: 496,
  marginInline: 'auto',
  justifyContent: 'space-between'
}))

export const BoxContent = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '50px auto 0 auto',
  width: 288,
  gap: theme.spacing(3)
}))
