import { Grid } from 'shared/designSystem/components/Grid'
import { BigValueText, LabelText, ValueText } from './style'
import { Box } from '@mui/material'
import { Line } from 'react-chartjs-2'
import { getMonthAbbreviations } from 'shared/utils/dates'
import { useEffect, useState } from 'react'
import { useFinancialStore } from 'shared/store/financial'
import { formatCurrency } from 'shared/utils/formatCurrency'
import useDataGraphUnit from 'pages/Financial/hooks/useDataGraphUnit'
import IconInfo from 'shared/designSystem/Icons/IconInfo'
import { InfoTooltip } from 'shared/designSystem/components/InfoTooltip'

const SubHeader = () => {
  const { data: dataUnit } = useDataGraphUnit()
  const [selectedItems, setSelectedItems] = useState<any[]>([])

  const {
    state: { selectGroups }
  } = useFinancialStore()

  useEffect(() => {
    const unity = dataUnit?.data?.[0]?.units?.[0] || null

    setSelectedItems([
      {
        label: unity?.unity || 'Unidade',
        data: unity?.invoices?.map((item) => item.value),
        fill: false,
        tension: 0,
        pointRadius: 5,
        pointHoverRadius: 7,
        borderWidth: 2,
        borderColor: '#050922',
        backgroundColor: '#050922',
        pointBackgroundColor: '#8bc34a'
      }
    ])
  }, [dataUnit, selectGroups])

  const chartData = {
    labels: getMonthAbbreviations(dataUnit?.data?.[0]?.units?.[0]?.invoices?.length),
    datasets: selectedItems
  }

  const options: any = {
    responsive: true,
    animation: false,
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
      tooltip: {
        enabled: true,
        displayColors: false,
        mode: 'nearest',
        intersect: true,
        callbacks: {
          title: function (tooltipItems: any) {
            const monthYear = tooltipItems[0]?.label || ''
            return monthYear
          },
          label: function (item: any) {
            const dataTwo = `${dataUnit?.data?.[0]?.units?.[0].invoices?.[item?.dataIndex]?.unity} ${formatCurrency(dataUnit?.data?.[0]?.units?.[0]?.invoices?.[item?.dataIndex]?.value || 0)}`
            return dataTwo
          }
        },
        bodyFont: {
          size: 14,
          weight: 'normal',
          family: 'Arial',
          lineHeight: 2.5
        },
        titleFont: {
          size: 16,
          weight: 'bold',
          family: 'Arial',
          lineHeight: 2
        },
        backgroundColor: '#ffffff',
        borderColor: ' #0000000F',
        borderWidth: 1,
        padding: 10,
        boxWidth: 10,
        caretSize: 5,
        titleColor: '#050922',
        bodyColor: '#05092261'
      }
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        border: {
          display: false
        }
      }
    }
  }
  return (
    <Grid container spacing={1}>
      <Grid container size={{ xs: 12, md: 6, lg: 5 }}>
        <Grid size={12}>
          <LabelText variant="body1">Custo mensal das unidades</LabelText>
          <BigValueText variant="h2">R$ 2.910,00</BigValueText>
        </Grid>

        <Grid size={{ xs: 12, sm: 3, md: 4 }}>
          <LabelText variant="body1">Economia no mês</LabelText>
          <ValueText variant="body1" color="#FF5722">
            R$ 342,40
          </ValueText>
        </Grid>

        <Grid size={{ xs: 12, sm: 3, md: 4 }}>
          <LabelText variant="body1">
            Pagamento
            <InfoTooltip
              title={`Você possui pagamentos configurados em boleto e débito automático. 
                      Para alterá-los, clique em "Configurar pagamento" no botão abaixo,
                      selecione a unidade e escolha o tipo de pagamento desejado.`}
            >
              <Box display="inline-flex">
                <IconInfo />
              </Box>
            </InfoTooltip>
          </LabelText>
          <ValueText variant="body1">Ambos</ValueText>
        </Grid>

        <Grid size={{ xs: 12, sm: 3, md: 4 }} mb={4}>
          <LabelText variant="body1">
            Tipo de fatura
            <InfoTooltip
              title={`Você possui faturas unificadas e não unificadas.Para alterá-las,
                      entre em contato pelo e-mail atendimento.solar@genial.com.vc ou
                      envie uma mensagem para o nosso WhatsApp: (21) 99449-2078`}
            >
              <Box display="inline-flex">
                <IconInfo />
              </Box>
            </InfoTooltip>
          </LabelText>
          <ValueText variant="body1">Ambos</ValueText>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 7 }}>
        <Box>
          <Line data={chartData} options={options} style={{ maxHeight: '200px' }} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default SubHeader
