import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconClose } from 'shared/designSystem/Icons/IconClose'
import useFilters from 'shared/hook/useFilters'
import { useTheme } from 'shared/hook/useTheme'
import { HeaderContainer } from 'shared/layout/Header/HeaderContainer'
import { ProductBadge } from 'shared/layout/Header/HeaderContainer/ProductBadge'
import { ReportOptions } from 'shared/layout/Header/HeaderContainer/ReportOptions'
import { TypePropsPageHeader } from 'shared/layout/Header/interface'
import { HeaderMiddleContent } from 'shared/layout/Header/styles'
import { useReportStore } from 'shared/store/reports'
import { ButtonClose, ContainerIconsSelectsHeader } from '../styles'
import { ContainerFiltersModalDetails } from './components/ContainerFilters'
import { ReactNode, RefObject } from 'react'
import { downloadPdf } from 'shared/utils/downloadPdf'
import { GridColDef } from '@mui/x-data-grid'

interface Props extends TypePropsPageHeader {
  pdfElements: { graph?: ReactNode; data?: any[]; columns?: GridColDef[] }[]
  pdfRef: RefObject<HTMLDivElement>
}

export const Header = ({ fetchData, pdfElements, pdfRef }: Props) => {
  const { filters } = useFilters('filtersAll')
  const theme = useTheme()
  const {
    state: { isOpen, graphicName },
    actions: { closeModal }
  } = useReportStore()

  return (
    <HeaderContainer>
      <ContainerIconsSelectsHeader>
        <ButtonClose sx={{ marginRight: '14px' }} onClick={closeModal}>
          <IconClose />
        </ButtonClose>
        <ContainerFiltersModalDetails fetchData={fetchData} />
      </ContainerIconsSelectsHeader>
      <HeaderMiddleContent>
        <Typography
          fontWeight={theme.designSystem.typography.fontWeight.bold}
          fontSize={{
            xs: theme.designSystem.typography.fontSize.body2,
            sm: theme.designSystem.typography.fontSize.body1
          }}
          color={theme.designSystem.base[900]}
        >
          {isOpen && `Relatório de ${graphicName}`}
        </Typography>
      </HeaderMiddleContent>

      <Box display="flex" alignItems="center"></Box>

      <Box display="flex" alignItems="center" gap={theme.spacing(2)}>
        {filters?.product == 1 && <ProductBadge name="Energy" />}
        {filters?.product == 2 && <ProductBadge name="Solar" />}

        <ReportOptions
          handlePrint={() => downloadPdf(pdfRef, pdfElements, 'print')}
          handleDownload={() => downloadPdf(pdfRef, pdfElements, 'download')}
          disabledActions={!pdfElements.filter((e) => e.data).length}
        />
      </Box>
    </HeaderContainer>
  )
}
