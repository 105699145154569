import ArrowLeftIcon from 'shared/designSystem/components/Icons/ArrowLeftIcon'
import { AppBarCustom, CustomDropDown, CustomIconButton, CustomTypography } from './styles'
import { useNavigate } from 'react-router-dom'

interface Props {
  role?: string
  onGroupChange: (group: string) => void
  selectedGroup?: string
}

export const AppBar = ({ role, onGroupChange }: Props) => {
  const dropDownOptions = ['Energy', 'Solar']
  const navigate = useNavigate()

  const handleBackToHome = () => {
    navigate('/home')
  }

  return (
    <AppBarCustom role={role}>
      <CustomIconButton role="icon-button" onClick={handleBackToHome}>
        <ArrowLeftIcon />
      </CustomIconButton>
      <CustomTypography role="typography">Ativação da conta</CustomTypography>
      <CustomDropDown label="Todos os grupos" options={dropDownOptions} onChange={onGroupChange} />
    </AppBarCustom>
  )
}
