import { User } from 'shared/models/User'
import { api } from 'shared/services/Api'
import { SecurityCode } from '../models/SecurityCode'
import { VerificationCode } from '../models/VerificationCode'
import { GenerateCode } from '../models/GenerateCode'
import { GenerateToken } from '../models/GenerateToken'

export class UserApi {
  static async generateCode(payload: GenerateCode) {
    await api.post(`/security-code/email`, payload)
  }

  static async submitFirstAccess(user: User) {
    const response = await api.post(`/user/first-access`, user)
    return response.data
  }

  static async sendVerificationCode(payload: VerificationCode) {
    const response = await api.post(`/security-code/phone`, payload)
    return response.data
  }

  static async validateSecurityCode(payload: SecurityCode) {
    const response = await api.post(`/security-code/validate`, payload)
    return response.data
  }

  static async generateToken(payload: GenerateToken) {
    const response = await api.post(`/login/token/auth`, payload)
    return response.data
  }
}
