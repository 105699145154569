import { Typography } from '@mui/material'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const LabelText = styledSystem(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  fontSize: '16px',
  fontWeight: 600,
  color: '#05092261',
  lineHeight: '22px',
  marginBottom: '10px'
}))

export const BigValueText = styledSystem(Typography)(() => ({
  fontSize: '26px',
  fontWeight: 600,
  color: '#050922',
  lineHeight: '32px'
}))

export const ValueText = styledSystem(Typography)(({ color }) => ({
  fontSize: '18px',
  fontWeight: 600,
  color: color || '#050922',
  lineHeight: '22px',
  display: 'flex',
  alignItems: 'center',
  gap: '5px'
}))
