import { Box } from 'shared/designSystem/components/Box'
import { Stack } from 'shared/designSystem/components/Stack'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const ContentContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  overflow: 'auto',
  padding: theme.designSystem.size[2],
  width: '100%',
  flexWrap: 'wrap',
  overflowX: 'hidden'
}))

export const ContainerTabs = styledSystem(Box)(() => ({
  width: '100%',
  overflow: 'hidden'
}))

export const ContainerFiltersRenders = styledSystem(Stack)(({ theme }) => ({
  padding: '16px 16px 0 16px',
  gap: '24px',
  width: '100%',
  background: theme.designSystem.white,
  flexDirection: 'column',
  alignItems: 'center',

  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    alignItems: 'flex-start'
  }
}))

export const ContainerFilters = styledSystem(Stack)(({ theme }) => ({
  maxWidth: '340px',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    minWidth: '338px',
    width: 'auto'
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%'
  }
}))
