import { useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Modal } from 'shared/designSystem/components/Modal'
import { Slide } from 'shared/designSystem/components/Slide'
import { AccessFailureModal } from './components/AccessFailureModal'

type Props = {
  open: boolean
  handleClose: () => void
}

export const AccessFailureModalFlow = ({ open, handleClose }: Props) => {
  const [showContent, setShowContent] = useState(true)
  const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('left')

  const handleCloseModal = () => {
    setShowContent(false)
    setTimeout(() => {
      handleClose()
      setShowContent(true)
      setSlideDirection('left')
    }, 300)
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      slotProps={{
        backdrop: {
          style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
        }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          zIndex: 1300
        }}
      >
        {
          <Slide in={showContent} direction={slideDirection} timeout={300}>
            <Box>
              <AccessFailureModal handleClose={handleCloseModal} />
            </Box>
          </Slide>
        }
      </Box>
    </Modal>
  )
}
