type Props = {
  role?: string
  width?: number
  height?: number
  fillColor?: string
  fillColorSecondary?: string
}

export const Logo = ({
  role,
  width,
  height,
  fillColor = '#4AA9FC',
  fillColorSecondary = 'white'
}: Props) => {
  return (
    <svg
      role={role}
      width={width ?? 18}
      height={height ?? 28}
      viewBox="0 0 18 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="17.0685" height="28" transform="translate(0.46582)" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3263 0C11.6095 0 11.0271 0.582402 11.0271 1.3104V1.4896C11.0271 2.2064 11.6095 2.8 12.3263 2.8H14.7567V5.2416C14.7567 5.9584 15.3391 6.55201 16.0559 6.55201H16.2351C16.9519 6.55201 17.5343 5.9696 17.5343 5.2416V2.8V1.3216C17.5343 0.615999 16.9743 0.0447973 16.2799 0.0111973C16.2687 0.0111973 16.2575 0.0111973 16.2463 0.0111973C16.2351 0.0111973 16.2351 0.0111973 16.2239 0.0111973H14.7455H12.3263V0Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9714 19.4544C10.9378 19.4208 10.8818 19.4096 10.837 19.432C9.94102 19.824 8.94423 20.0368 7.90263 20.0368C3.79223 20.0368 0.46582 16.7104 0.46582 12.6112C0.46582 8.51195 3.79223 5.18555 7.90263 5.18555C12.013 5.18555 15.3394 8.51195 15.3394 12.6112C15.3394 14.4368 14.6786 16.1056 13.5922 17.3936C13.5474 17.4496 13.5474 17.528 13.6034 17.5728C14.6898 18.6816 15.3506 20.104 15.3506 21.6496C15.3506 25.1328 12.0354 28 7.91383 28C3.79222 28 0.477024 25.144 0.477024 21.6496C0.477024 21.5712 0.544226 21.5152 0.611426 21.5152H3.59063C3.65783 21.5152 3.72502 21.5712 3.72502 21.6496C3.72502 22.5568 4.20663 23.3744 4.96823 23.9568C5.72983 24.5392 6.77142 24.8976 7.91383 24.8976C9.05623 24.8976 10.109 24.5392 10.8594 23.9568C11.621 23.3744 12.1026 22.568 12.1026 21.6496C12.0802 20.7984 11.6546 20.0256 10.9714 19.4544ZM7.89143 17.0576C10.3554 17.0576 12.349 15.064 12.349 12.6C12.349 10.136 10.3554 8.14234 7.89143 8.14234C5.42743 8.14234 3.43382 10.136 3.43382 12.6C3.43382 15.064 5.42743 17.0576 7.89143 17.0576Z"
        fill={fillColorSecondary}
      />
    </svg>
  )
}
