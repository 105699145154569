import { memo } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import { GraphBox, GraphContainer } from 'pages/Reports/styles'
import { GraphPropsConsumer } from 'pages/Reports/types/GraphProps'
import { Bar, Line } from 'react-chartjs-2'
import { Legend } from '../../Legend'
import { CustomTooltip } from '../../Tooltip'
import { HeaderReports } from '../../Header'
import { EmptyData } from '../../EmptyData'
import useConsumeGraphEnergy from './useConsumeGraphEnergy'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  annotationPlugin
)

const ConsumeGraphEnergy = ({ variant, dataInfos, dataInfosHeader }: GraphPropsConsumer) => {
  const {
    isModalVariant,
    hasData,
    setHoveredIndex,
    data,
    options,
    tooltipData,
    legends,
    labels,
    isMonthView,
    detailedOptions,
    detailedData,
    verticalHoverFill,
    labelsMonth,
    dataInfosHeaderDetail,
    handleEmpty
  } = useConsumeGraphEnergy({ variant, dataInfos, dataInfosHeader })

  return (
    <GraphBox isModalVariant={isModalVariant}>
      {dataInfosHeader?.length && (
        <HeaderReports
          infos={isMonthView ? dataInfosHeaderDetail : dataInfosHeader}
          graphic={1}
          graphicName="Consumo"
        />
      )}

      {hasData ? (
        <>
          <GraphContainer isModalVariant={isModalVariant} onMouseLeave={() => setHoveredIndex(null)}>
            {isMonthView ? (
              <Line data={detailedData} options={detailedOptions} plugins={[verticalHoverFill]} />
            ) : (
              <Bar data={data} options={options} />
            )}

            {tooltipData.visible && (
              <CustomTooltip
                x={tooltipData!.x}
                y={tooltipData!.y}
                visible={tooltipData.visible}
                datas={isMonthView ? labelsMonth : labels}
              />
            )}
          </GraphContainer>
          <Box
            display="flex"
            flexWrap="wrap"
            gap={1}
            width="90%"
            sx={{ position: { xs: 'relative', sm: 'static' }, zIndex: { xs: 9, sm: 0 } }}
          >
            {legends
              .filter((item) => !item.show)
              .map((item, index) => (
                <Legend
                  key={index}
                  color={item?.color}
                  text={item?.text}
                  variant={item?.variant}
                  secondaryColor={item?.secondaryColor}
                />
              ))}
          </Box>
        </>
      ) : (
        <EmptyData
          onclick={handleEmpty}
          text="Não há dados disponíveis para a visualização do gráfico."
        />
      )}
    </GraphBox>
  )
}

export default memo(ConsumeGraphEnergy)
