import useTabsSolar from '../useTabsSolar'
import SubHeader from '../tabs/solar/subHeader'
import { ContainerFiltersHeader, ContainerTabs, ContentContainer } from './style'

const FinancialSolar = () => {
  const { renderTabs, renderTabsContent } = useTabsSolar()

  return (
    <ContentContainer data-testid="content-container">
      <ContainerFiltersHeader>
        <SubHeader />
        <ContainerTabs>
          {renderTabs}
          {renderTabsContent}
        </ContainerTabs>
      </ContainerFiltersHeader>
    </ContentContainer>
  )
}

export default FinancialSolar
