import { ButtonStyle } from './style'

interface ButtonDetailsProps {
  onClick: VoidFunction
}

const ButtonDetails = ({ onClick }: ButtonDetailsProps) => {
  return (
    <ButtonStyle variant="outlined" color="inherit" size="small" onClick={onClick}>
      Detalhes
    </ButtonStyle>
  )
}

export default ButtonDetails
