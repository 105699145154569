import TabCCEE from 'pages/Financial/tabs/energy/tabCCEE'
import TabDistributor from 'pages/Financial/tabs/energy/tabDistributor'
import TabEnergyNote from 'pages/Financial/tabs/energy/tabEnergyNote'
import TabGenialInvoice from 'pages/Financial/tabs/energy/tabGenialInvoice'
import TabManagementNote from 'pages/Financial/tabs/energy/tabManagementNote'
import { useEffect, useMemo, useState } from 'react'
import { Chip } from 'shared/designSystem/components/Chip'
import { SelectChangeEvent } from 'shared/designSystem/components/SelectChangeEvent'
import { Tab } from 'shared/designSystem/components/Tab'

import useFilters from 'shared/hook/useFilters'
import useFiltersGlobal from 'shared/hook/useFiltersGlobal'
import { useFinancialStore } from 'shared/store/financial'
import { a11yProps } from 'shared/utils/financialTab'
import { TabsStyles } from './styles'

const useHeaderTabs = () => {
  const { filters, setBulkFilterData } = useFilters('filtersAll')
  const { globalDate } = useFiltersGlobal()
  const {
    actions: { setViewHistoryTab }
  } = useFinancialStore()
  const [tabItem, setTabItem] = useState(0)
  const [activeFilter, setActiveFilter] = useState<number>(1)

  const TABS = [
    { label: 'Jan', value: 0 },
    { label: 'Fev', value: 1 },
    { label: 'Mar', value: 2 },
    { label: 'Abr', value: 3 },
    { label: 'Mai', value: 4 },
    { label: 'Jun ', value: 5 },
    { label: 'Jul ', value: 6 },
    { label: 'Ago ', value: 7 },
    { label: 'Set ', value: 8 },
    { label: 'Out ', value: 9 },
    { label: 'Nov ', value: 10 },
    { label: 'Dez ', value: 11 }
  ]

  const TABSFILTER = [
    { label: 'CCEE', value: 1, component: <TabCCEE Month={tabItem + 1} /> },
    { label: 'Fatura Genial', value: 2, component: <TabGenialInvoice Month={tabItem + 1} /> },
    { label: 'Nota de Gestão', value: 3, component: <TabManagementNote Month={tabItem + 1} /> },
    { label: 'Nota de Energia', value: 4, component: <TabEnergyNote Month={tabItem + 1} /> },
    { label: 'Distribuidora', value: 5, component: <TabDistributor Month={tabItem + 1} /> }
  ]

  const handleComponenteView = () => {
    const filterTabsFilter = TABSFILTER.find((item) => item.value === activeFilter)
    setViewHistoryTab(filterTabsFilter?.component)
  }

  const { handleChange } = SelectChangeEvent({
    onChange: (value: string) => {
      setBulkFilterData({ ...filters, yearF: value })
    }
  })

  const renderTabs = useMemo(
    () => (
      <TabsStyles
        value={tabItem}
        onChange={(_, value) => setTabItem(value)}
        aria-label="table financial"
      >
        {TABS.map((item) => (
          <Tab key={item.value} label={item.label} {...a11yProps(item.value)} />
        ))}
      </TabsStyles>
    ),
    [tabItem]
  )

  const renderTabsFilterHistory = useMemo(
    () =>
      TABSFILTER.map((item) => (
        <Chip
          key={item.value}
          label={item.label}
          variant={item.value === activeFilter ? 'outlined' : 'filled'}
          sx={{
            marginLeft: '15px',
            border: '1px solid #05092261',
            background: item.value === activeFilter ? '#000000' : '#ffffff',
            color: item.value === activeFilter ? '#ffffff' : '#05092261'
          }}
          onClick={() => setActiveFilter(item.value)}
        />
      )),
    [activeFilter, tabItem]
  )

  useEffect(() => {
    handleComponenteView()
  }, [activeFilter, tabItem])

  return { renderTabs, renderTabsFilterHistory, handleChange, filters, globalDate }
}

export default useHeaderTabs
