import { Stack } from '@mui/material'
import { Loading } from '../Loading'
import { Typography } from '../Typography'

interface IDataType {
  loading?: boolean
  children: React.ReactNode
  noData?: boolean
}

const LoadingContentAdvanced = ({ loading, children, noData }: IDataType) => {
  if (loading)
    return (
      <Stack
        overflow="hidden"
        style={{ pointerEvents: 'none' }}
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Loading />
      </Stack>
    )

  if (noData)
    return (
      <Typography variant="body1">
        Atualmente, não há dados disponíveis para exibição. Em caso de dúvidas, entre em contato pelo
        e-mail clientes.energia@genial.com.vc ou envie uma mensagem para o nosso WhatsApp: (21)
        97445-3315
      </Typography>
    )

  return children
}

export default LoadingContentAdvanced
