import { UserApi } from 'pages/FirstAccess/services/User'
import { useNavigate } from 'react-router-dom'
import { TOKEN_KEY } from 'shared/services/Api'
import { useAuthStore } from 'shared/store/auth'

import {
  Button,
  Description,
  FormboxContainer,
  MainBox,
  StyledImage,
  Title,
  TypographyBox
} from './styles'

interface Props {
  cpf: string
  password: string
}

const HOME_ROUTE = '/home'

export const DefinedPasswordBox = ({ cpf, password }: Props) => {
  const {
    actions: { signIn }
  } = useAuthStore()
  const navigate = useNavigate()

  const handleSubmit = async () => {
    try {
      const response = await UserApi.generateToken({ cpf, password })
      localStorage.setItem(TOKEN_KEY, response.data.accessToken)
      signIn()
      navigate(HOME_ROUTE)
    } catch (error) {
      console.error('Erro ao definir senha:', error)
      throw error
    }
  }

  return (
    <MainBox role="defined-password-box">
      <FormboxContainer role="formbox">
        <StyledImage role="image">
          <img alt="success" src="./public/success.png" />
        </StyledImage>
        <TypographyBox role="typography-box">
          <Title role="title">Senha definida!</Title>
          <Description role="message">
            A partir de agora você pode acessar nossa plataforma utilizando CPF e senha
          </Description>
        </TypographyBox>
        <Button role="button" onClick={handleSubmit}>
          Ir para a página inicial
        </Button>
      </FormboxContainer>
    </MainBox>
  )
}
