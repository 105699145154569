import { Box } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { Card } from 'shared/designSystem/components/Card'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const MainContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 16px)',
  boxSizing: 'border-box',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  }
}))

export const ContentWrapper = styledSystem(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  width: '100%',
  maxWidth: '100%'
}))

export const ContentContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  overflow: 'auto',
  padding: theme.designSystem.size[2],
  width: '100%',
  flexWrap: 'wrap'
}))

export const StyledButton = styledSystem(Button)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.button,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  borderRadius: theme.designSystem.size[20],
  textTransform: 'uppercase',
  color: theme.designSystem.base[400],
  borderColor: theme.palette.divider,
  paddingLeft: theme.designSystem.size[16],
  paddingRight: theme.designSystem.size[16]
}))

export const StyledTypography = styledSystem(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[400],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.designSystem.typography.fontSize.button
  }
}))

export const StyledNavigationButton = styledSystem(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.designSystem.base[300]}`,
  borderRadius: '50%',
  minWidth: theme.designSystem.size[30],
  minHeight: theme.designSystem.size[30],

  '&:disabled': {
    color: theme.designSystem.base[200],
    borderColor: theme.designSystem.base[200]
  }
}))

export const HomeCardStyled = styledSystem(Card)(({ theme }) => ({
  padding: theme.designSystem.size[32],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.designSystem.size[20],
  borderRadius: 0,
  boxShadow: 'none'
}))
