import { Box } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

interface OpenProps {
  open: boolean
}

export const ContainerButtonBurguer = styledSystem('div')(({ theme }) => ({
  width: '100%',
  height: 50,

  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}))

export const ButtonBurguer = styledSystem(Button)<OpenProps>(({ theme, open }) => ({
  position: open ? 'absolute' : 'inherit',
  right: open ? '10px' : 'inherit',
  top: open ? '10px' : 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  zIndex: 999,
  maxWidth: open ? '40px' : '100%',
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}))

export const Background = styledSystem('div')(({ theme }) => ({
  position: 'fixed',
  background: '#12162cad',
  width: '100vw',
  height: '100vh',
  top: 0,
  zIndex: 998,

  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}))

export const SideContainerStyled = styledSystem(Box)<OpenProps>(({ theme, open }) => ({
  component: 'aside',
  height: '100%',
  top: 0,
  left: 0,
  width: theme.designSystem.size[80],
  position: 'fixed',
  zIndex: 999,
  display: open ? 'flex' : 'none',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.designSystem.base[800],
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    position: 'inherit',
    display: 'flex'
  }
}))
