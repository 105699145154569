import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const MainContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  height: '98.3vh',
  width: 'auto',
  flexDirection: 'column',
  boxSizing: 'border-box',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  }
}))
