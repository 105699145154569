import { useMemo, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Tab } from 'shared/designSystem/components/Tab'
import { Tabs } from 'shared/designSystem/components/Tabs'
import { useFinancialStore } from 'shared/store/financial'
import HeaderTabsSolar from './components/headerTabsSolar'
import TabIsOpen from './tabs/solar/tabIsOpen'
import TabHistory from './tabs/solar/tabHistory'

const useTabsSolar = () => {
  const [tabItem, setTabItem] = useState(0)

  const {
    actions: { selectTab }
  } = useFinancialStore()

  const TABS = [
    { label: 'Em aberto', value: 0, component: <TabIsOpen /> },
    { label: 'Histórico', value: 1, component: <TabHistory /> }
  ]

  const handleSelectTab = (value: number) => {
    selectTab(value)
    setTabItem(value)
  }

  const renderTabs = useMemo(
    () => (
      <Tabs
        sx={{
          borderBottom: '1px solid #05092261',
          marginTop: {
            md: '-1.5vh'
          },

          width: '100%'
        }}
        value={tabItem}
        onChange={(_, value) => handleSelectTab(value)}
        aria-label="table financial"
      >
        {TABS.map((item) => (
          <Tab
            label={item.label}
            id={`financial-tab-${item.value}`}
            aria-controls={`financial-tabpanel-${item.value}`}
            sx={{
              textTransform: 'none'
            }}
          />
        ))}
      </Tabs>
    ),
    [tabItem]
  )

  const renderTabsContent = useMemo(
    () => (
      <>
        <HeaderTabsSolar tabItem={tabItem} />
        {TABS.map((item) => (
          <Box hidden={tabItem !== item.value}>{tabItem === item.value && item.component}</Box>
        ))}
      </>
    ),
    [tabItem]
  )

  return {
    renderTabs,
    renderTabsContent
  }
}

export default useTabsSolar
