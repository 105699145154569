import { NoDataBox } from 'pages/Reports/styles'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { theme } from 'shared/theme'
import { Legend } from '../Legend'

export const EmptyData = ({ text, onclick }: { text: string; onclick?: () => void }) => {
  return (
    <>
      <NoDataBox onClick={onclick}>
        <Box>
          <Typography
            fontSize={theme.designSystem.typography.fontSize.button}
            fontWeight={theme.designSystem.typography.fontWeight.regular}
            color={theme.designSystem.base[300]}
          >
            {text}
          </Typography>
        </Box>
      </NoDataBox>
      <Box sx={{ mt: 3 }}>
        <Legend color={theme.designSystem.base[200]} text="Não disponível" variant="square" />
      </Box>
    </>
  )
}
