import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AlertSnackbar } from 'shared/designSystem/components/AlertSnackbar'
import { Box } from 'shared/designSystem/components/Box'
import { DotsLoader } from 'shared/designSystem/components/DotsLoader'
import { IconButton } from 'shared/designSystem/components/IconButton'
import { Typography } from 'shared/designSystem/components/Typography'
import { Logo } from 'shared/designSystem/Icons/IconLogo'
import { IconVisibilityOff } from 'shared/designSystem/Icons/IconVisibilityOff'
import { IconVisibilityOn } from 'shared/designSystem/Icons/IconVisibilityOn'
import { useTheme } from 'shared/hook/useTheme'
import { useApiLogin } from 'shared/services/Requests/Login'
import { useAuthStore } from 'shared/store/auth'
import { cleanCpf, formatCpf, isValidCpf } from 'shared/utils/validateCpf'
import { AccessFailureModalFlow } from './AccessFailureModalFlow'
import { SocialIconsLogin } from './components/SocialIconsLogin'
import { LoginFlow } from './LoginFlow'
import { PasswordRecoveryFlow } from './PasswordRecoveryFlow'
import {
  ButtonContainer,
  ButtonLogin,
  ButtonUnderline,
  FormContainer,
  InputCpfAndPassword,
  InputStyle,
  LeftPane,
  RightPane,
  SignInContainer,
  SocialIconsContainer,
  TypographyContainer
} from './styles'

export const SignIn = () => {
  const theme = useTheme()

  const [cpf, setCpf] = useState('')
  const [password, setPassword] = useState('')
  const [cpfError, setCpfError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false)
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const [isUserInactive, setIsUserInactive] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const navigate = useNavigate()
  const [isPageLoading, setIsPageLoading] = useState(true)

  const { mutateLogin, loading } = useApiLogin()

  const handleOpenModal = () => setIsOpenPasswordModal(true)
  const handleCloseModal = () => setIsOpenPasswordModal(false)

  const isAuthenticated = useAuthStore((state) => state.state.isAuthenticated)

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  const validateCpf = () => {
    const cleanedCpf = cleanCpf(cpf)

    if (!cpf) {
      setCpfError('Este campo é obrigatório')
      return
    }

    if (!isValidCpf(cleanedCpf)) {
      setCpfError('O CPF não é válido')
      return
    }

    setCpfError('')
  }

  const validatePassword = () => {
    if (!password) {
      setPasswordError('Este campo é obrigatório')
      return
    }

    setPasswordError('')
  }

  const handleCpfChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputCpf = e.target.value
    setCpf(formatCpf(inputCpf))
    setCpfError('')
  }

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleError = (error: any) => {
    const errorMessage = error?.response?.data?.message
    const statusCode = error?.response?.status

    if (statusCode === 412) {
      setIsUserInactive(true)
      return
    }

    setAlertMessage(errorMessage || 'Falha ao entrar')
    setAlertOpen(true)
  }

  const handleSubmit = async () => {
    validateCpf()
    validatePassword()
    if (!cpf || !password) return

    const cpfToSend = cleanCpf(cpf)
    try {
      const success = await mutateLogin(cpfToSend, password)

      if (success) {
        setIsOpenLoginModal(true)
      } else {
        // TODO: melhorar erros, resposta foi alterada no back
        setAlertMessage('Falha ao entrar')
        setAlertOpen(true)
      }
    } catch (error: any) {
      handleError(error)
    }
  }

  useEffect(() => {
    if (isAuthenticated) navigate('/home')
    else setIsPageLoading(false)
  }, [isAuthenticated])

  return (
    <>
      {isPageLoading ? (
        <DotsLoader />
      ) : (
        <SignInContainer role="sign-in-box">
          <LeftPane role="background-login" />
          <RightPane role="login-form">
            <Logo fillColorSecondary={theme.designSystem.base[900]} height={50} width={34} />
            <TypographyContainer>
              <Typography
                fontWeight={theme.designSystem.typography.fontWeight.medium}
                fontSize={theme.designSystem.typography.fontSize.h3}
              >
                Olá, seja bem-vindo!
              </Typography>
            </TypographyContainer>

            <FormContainer
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit()
                }
              }}
            >
              <InputCpfAndPassword>
                <Box sx={{ position: 'relative', mb: 3 }}>
                  <InputStyle
                    variant="standard"
                    label="CPF"
                    fullWidth
                    value={cpf}
                    onChange={handleCpfChange}
                    error={!!cpfError}
                  />
                  {cpfError && (
                    <Typography color={theme.designSystem.deepOrange[500]} variant="caption">
                      {cpfError}
                    </Typography>
                  )}
                </Box>

                <Box sx={{ position: 'relative' }}>
                  <InputStyle
                    variant="standard"
                    label="Senha"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handlePasswordChange}
                    error={!!passwordError}
                    slotProps={{
                      htmlInput: {
                        minLength: 8,
                        maxLength: 64
                      }
                    }}
                  />
                  {passwordError && (
                    <Typography color={theme.designSystem.deepOrange[500]} variant="caption">
                      {passwordError}
                    </Typography>
                  )}
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: passwordError ? '30%' : '50%',
                      transform: 'translateY(-50%)',
                      color: 'grey.500'
                    }}
                  >
                    {showPassword ? <IconVisibilityOn /> : <IconVisibilityOff />}
                  </IconButton>
                </Box>
              </InputCpfAndPassword>
            </FormContainer>
            <ButtonContainer>
              <ButtonUnderline variant="text" onClick={handleOpenModal}>
                Esqueci a senha
              </ButtonUnderline>

              <ButtonLogin onClick={handleSubmit}>{loading ? <DotsLoader /> : 'Entrar'}</ButtonLogin>
            </ButtonContainer>

            <SocialIconsContainer>
              <SocialIconsLogin />
            </SocialIconsContainer>
          </RightPane>

          <AlertSnackbar
            type="error"
            open={alertOpen}
            onClose={handleAlertClose}
            title={alertMessage}
            message="Tente novamente"
          />

          {isUserInactive && (
            <AccessFailureModalFlow open={isUserInactive} handleClose={() => setIsUserInactive(false)} />
          )}
          <PasswordRecoveryFlow open={isOpenPasswordModal} onClose={handleCloseModal} />
          <LoginFlow
            password={password}
            cpf={cleanCpf(cpf)}
            open={isOpenLoginModal}
            onClose={() => setIsOpenLoginModal(false)}
          />
        </SignInContainer>
      )}
    </>
  )
}
