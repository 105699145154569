import { useFinancialStore } from 'shared/store/financial'

const TabHistory = () => {
  const {
    state: { viewHistoryTab }
  } = useFinancialStore()

  return viewHistoryTab
}

export default TabHistory
