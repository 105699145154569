import { Box } from 'shared/designSystem/components/Box'
import type { TypePropsPageHeader } from '../../interface'
import MultiLevelSelectProduct from './components/MultiLevelSelectProduct'
import MultiLevelSelectYear from './components/MultiLevelSelectYear'

export const ContainerFilters = ({ fetchData }: TypePropsPageHeader) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
      <Box
        sx={{
          border: '1px solid #E6E6E9',
          boxShadow: '0px 4px 10px rgba(5, 9, 34, 0.1)'
        }}
      >
        <MultiLevelSelectProduct fetchData={fetchData} />
      </Box>
      {location.pathname === '/reports' && (
        <Box sx={{ border: '1px solid #E6E6E9', boxShadow: '0px 4px 10px rgba(5, 9, 34, 0.1)' }}>
          <MultiLevelSelectYear fetchData={fetchData} />
        </Box>
      )}
    </Box>
  )
}
