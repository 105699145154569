import { useEffect, useMemo, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Tab } from 'shared/designSystem/components/Tab'
import { Tabs } from 'shared/designSystem/components/Tabs'
import useFilters from 'shared/hook/useFilters'
import { useFinancialStore } from 'shared/store/financial'
import HeaderTabs from './components/headerTabs'
import TabCCEE from './tabs/energy/tabCCEE'
import TabData from './tabs/energy/tabData'
import TabDataUnit from './tabs/energy/tabDataUnit'
import TabDistributor from './tabs/energy/tabDistributor'
import TabEnergyNote from './tabs/energy/tabEnergyNote'
import TabGenialInvoice from './tabs/energy/tabGenialInvoice'
import TabHistory from './tabs/energy/tabHistory'
import TabManagementNote from './tabs/energy/tabManagementNote'
import TabObligations from './tabs/energy/tabObligations'

const useTabsEnergy = () => {
  const [tabItem, setTabItem] = useState(0)
  const { filters } = useFilters('filtersAll')

  const {
    state: { tab },
    actions: { selectTab }
  } = useFinancialStore()

  const isUnit = filters && filters.hasOwnProperty('unit')

  const TABS = isUnit
    ? [
        { label: 'Obrigações', value: 0, component: <TabObligations /> },
        { label: 'Dados', value: 1, component: <TabDataUnit /> },
        { label: 'Histórico ', value: 2, component: <TabHistory /> }
      ]
    : [
        { label: 'CCEE', value: 0, component: <TabCCEE /> },
        { label: 'Fatura Genial', value: 1, component: <TabGenialInvoice /> },
        { label: 'Nota de Gestão', value: 2, component: <TabManagementNote /> },
        { label: 'Nota de Energia', value: 3, component: <TabEnergyNote /> },
        { label: 'Distribuidora', value: 4, component: <TabDistributor /> },
        { label: 'Dados', value: 5, component: <TabData /> },
        { label: 'Histórico ', value: 6, component: <TabHistory /> }
      ]

  const handleSelectTab = (value: number) => {
    selectTab(value)
    setTabItem(value)
  }

  const renderTabs = useMemo(
    () => (
      <Tabs
        sx={{
          borderBottom: '1px solid #05092261',
          marginTop: {
            md: '-1.5vh'
          },
          width: '100%',
          '& .MuiTabs-scroller': {
            overflow: 'scroll !important',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }
        }}
        variant="fullWidth"
        value={tabItem}
        onChange={(_, value) => handleSelectTab(value)}
        aria-label="table financial"
      >
        {TABS.map((item) => (
          <Tab
            key={item.value}
            label={item.label}
            id={`financial-tab-${item.value}`}
            aria-controls={`financial-tabpanel-${item.value}`}
            sx={{
              textTransform: 'none'
            }}
          />
        ))}
      </Tabs>
    ),
    [tabItem, isUnit]
  )

  const renderTabsContent = useMemo(
    () => (
      <>
        {((tab !== 5 && !isUnit) || (tab !== 1 && isUnit)) && <HeaderTabs tabItem={tabItem} />}
        {TABS.map((item) => (
          <Box hidden={tabItem !== item.value}>{tabItem === item.value && item.component}</Box>
        ))}
      </>
    ),
    [tabItem, isUnit]
  )

  useEffect(() => {
    handleSelectTab(0)
  }, [isUnit])

  return {
    renderTabs,
    renderTabsContent
  }
}

export default useTabsEnergy
