import useDataList from 'pages/Financial/hooks/useDataList'
import useFilters from 'shared/hook/useFilters'
import { formatCurrency } from 'shared/utils/formatCurrency'
import CardData from '../../../components/cardData'
import DrawerDetails from '../../../components/drawerDetails'
import ViewCardTable from '../../../components/viewCardTable'
import useDrawer from 'pages/Financial/hooks/useDrawer'
import { columnsCCEE } from '../columns'

const TabCCEE = ({ Month = 1 }: { Month?: number }) => {
  const { currentItem, isOpenedDrawer, onCloseDrawer, onOpenDrawerDetails } = useDrawer()

  const { filters } = useFilters('filtersAll')
  const isUnit = filters && filters.hasOwnProperty('unit')

  const { dataFiltered, loading } = useDataList(Month, 'CCEE')

  return (
    <>
      <ViewCardTable
        data={dataFiltered}
        columns={columnsCCEE((row) => onOpenDrawerDetails(row))}
        loading={loading}
        cardComponent={(item) => (
          <CardData
            title={item?.obligation || item?.unitName}
            subtitle={item?.group}
            tagText="CCEE"
            tagColor="#5D65F7"
            text="O valor deverá ser pago à CCEE"
            footerTextLeft={item?.dueDate}
            footerTextRigth={(item?.value && formatCurrency(item.value)) || ''}
            onClick={() => onOpenDrawerDetails(item)}
          />
        )}
      />

      <DrawerDetails
        isOpened={isOpenedDrawer}
        onClose={onCloseDrawer}
        id={currentItem?.id}
        title={currentItem?.obligation || 'CCEE'}
        subTitle={isUnit ? currentItem?.unitName : currentItem?.group}
      />
    </>
  )
}

export default TabCCEE
