import { Box } from 'shared/designSystem/components/Box'
import { IconButton } from 'shared/designSystem/components/IconButton'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconArrowDown } from 'shared/designSystem/Icons/IconArrowDown'
import useFilters from 'shared/hook/useFilters'
import { theme } from 'shared/theme'
import { Pulse } from './components/Pulse'
import { FlagContainer } from './styles'
import useFlag from './useFlag'

export interface Props {
  type: 'green' | 'yellow' | 'red2' | 'red1'
}

export enum sizes {
  'SMALL',
  'NORMAL',
  'LARGE'
}

export const Flag = ({ type }: Props) => {
  const { filters } = useFilters('filtersAll')
  const { size, colors, handleExpand, handleResize, handleMinimize } = useFlag({ type })

  return (
    <FlagContainer
      isExpanded={size}
      color={colors.color}
      backgroundColor={colors.bgColor}
      onClick={() => size === sizes.SMALL && handleExpand()}
      onMouseLeave={() => size === sizes.LARGE && handleResize()}
      sx={{ cursor: 'pointer' }}
    >
      {size === sizes.NORMAL && (
        <Box
          sx={{ display: 'flex', width: '100%', position: 'relative', height: 48 }}
          onMouseOver={handleExpand}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              height: '100%'
            }}
          >
            <Box ml={2}>
              <Pulse color={colors.color} />
            </Box>
            <Box sx={{ cursor: 'pointer' }}>
              <Typography
                fontSize={theme.designSystem.typography.fontSize.body2}
                fontWeight={theme.designSystem.typography.fontWeight.medium}
                ml={2}
                mr={3}
                width="100%"
              >
                {type === 'green' && 'Bandeira verde'}
                {type === 'yellow' && 'Bandeira amarela'}
                {type === 'red2' && 'Bandeira vermelha'}
                {type === 'red1' && 'Bandeira vermelha'}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              height: '100%',
              borderLeft: `1px solid #FAFBFF7A`,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <IconButton onClick={handleMinimize} sx={{ ml: 1 }}>
              <IconArrowDown color={colors.color} />
            </IconButton>
          </Box>
        </Box>
      )}

      {size === sizes.LARGE && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between'
            }}
          >
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography
                fontSize={theme.designSystem.typography.fontSize.body1}
                fontWeight={theme.designSystem.typography.fontWeight.medium}
              >
                {type === 'green' && 'Bandeira verde'}
                {type === 'yellow' && 'Bandeira amarela'}
                {type === 'red2' && 'Bandeira vermelha'}
                {type === 'red1' && 'Bandeira vermelha'}
              </Typography>
              {(type === 'red1' || type === 'red2') && (
                <Typography
                  fontSize={theme.designSystem.typography.fontSize.body1}
                  fontWeight={theme.designSystem.typography.fontWeight.regular}
                  color={colors.color}
                >
                  {type === 'red2' ? 'Patamar 2' : 'Patamar 1'}
                </Typography>
              )}
            </Box>

            <Box mt={1} mr={1}>
              <Pulse color={colors.color} />
            </Box>
          </Box>

          <Typography
            fontSize={theme.designSystem.typography.fontSize.button}
            fontWeight={theme.designSystem.typography.fontWeight.regular}
          >
            Condições ainda mais custosas de geração de energia. A tarifa atual é acrescida em{' '}
            <strong>R$ 0,078</strong> para cada quilowatt-hora (kWh)
          </Typography>

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography
                fontSize={theme.designSystem.typography.fontSize.body2}
                fontWeight={theme.designSystem.typography.fontWeight.medium}
              >
                R$ 0.82
              </Typography>

              <Typography
                fontSize={theme.designSystem.typography.fontSize.caption}
                fontWeight={theme.designSystem.typography.fontWeight.regular}
              >
                {filters.product == 1
                  ? 'Valor atual do quilowatt-hora (kWh)'
                  : 'Acréscimo por quilowatt-hora'}
              </Typography>
            </Box>
            <IconButton
              onClick={handleMinimize}
              sx={{ borderRadius: 2, border: `1px solid #FAFBFF29`, padding: 2 }}
            >
              <IconArrowDown color={colors.color} />
            </IconButton>
          </Box>
        </Box>
      )}

      {size === sizes.SMALL && (
        <Box sx={{ cursor: 'pointer', marginInline: 1 }} onClick={handleExpand}>
          <Pulse color={colors.color} />
        </Box>
      )}
    </FlagContainer>
  )
}
