import { GridColDef } from 'shared/designSystem/components/GridColDef'
import { theme } from 'shared/theme'

export const columnsConsumeGraphEnergy: GridColDef[] = [
  {
    field: 'month',
    headerName: 'Mês',
    flex: 1,
    renderCell: (params) => (
      <span
        style={{
          color: theme.designSystem.base[900],
          fontWeight: theme.designSystem.typography.fontWeight.regular
        }}
      >
        {params.value}
      </span>
    )
  },
  {
    field: 'contractedVolume',
    headerName: 'Volume contratado',
    flex: 1
  },
  {
    field: 'volumeConsumed',
    headerName: 'Volume consumido',
    flex: 1
  },
  {
    field: 'shortTerm',
    headerName: 'Curto prazo',
    flex: 1,
    renderCell: (params) => (
      <span
        style={{
          color: theme.designSystem.deepOrange[500],
          fontWeight: theme.designSystem.typography.fontWeight.regular
        }}
      >
        {params.value}
      </span>
    )
  },
  {
    field: 'maximumFlexibility',
    headerName: 'Flexibilidade máxima',
    flex: 1
  },
  {
    field: 'minimalFlexibility',
    headerName: 'Flex. mínima',
    flex: 1
  }
]

export const columnsConsumeGraphSolar: GridColDef[] = [
  {
    field: 'month',
    headerName: 'Mês',
    flex: 1,
    renderCell: (params) => (
      <span
        style={{
          color: theme.designSystem.base[900],
          fontWeight: theme.designSystem.typography.fontWeight.regular
        }}
      >
        {params.value}
      </span>
    )
  },
  {
    field: 'compensatedEnergy',
    headerName: 'Energia compensada',
    flex: 0.5
  },
  {
    field: 'energyConsumed',
    headerName: 'Energia contratada',
    flex: 0.5
  }
]

export const columnsEconomyGraphEnergy: GridColDef[] = [
  {
    field: 'month',
    headerName: 'Mês',
    flex: 1
  },
  {
    field: 'captiveCost',
    headerName: 'Custo cativo',
    flex: 1
  },
  {
    field: 'freeCost',
    headerName: 'Custo livre',
    flex: 1
  },
  {
    field: 'monthlySavings',
    headerName: 'Economia mensal',
    flex: 1
  }
]

export const columnsEconomyGraphSolar: GridColDef[] = [
  {
    field: 'month',
    headerName: 'Mês',
    flex: 1
  },
  {
    field: 'traditionalCost',
    headerName: 'Custo tradicional',
    flex: 1
  },
  {
    field: 'costGenial',
    headerName: 'Custo genial',
    flex: 1
  },
  {
    field: 'monthlySavings',
    headerName: 'Economia mensal',
    flex: 1
  }
]

export const columnsEconomyAlertGraph: GridColDef[] = [
  {
    field: 'month',
    headerName: 'Mês',
    flex: 1
  },
  {
    field: 'measuredInTip',
    headerName: 'Medido em ponta',
    flex: 1
  },
  {
    field: 'measuredOffTip',
    headerName: 'Medido fora ponta',
    flex: 1
  }
]
export const columnsTotalGraph: GridColDef[] = [
  {
    field: 'month',
    headerName: 'Mês',
    flex: 1
  },
  {
    field: 'energy',
    headerName: 'Energia',
    flex: 1
  },
  {
    field: 'distribution',
    headerName: 'Distribuição',
    flex: 1
  },
  {
    field: 'energyManagement',
    headerName: 'Gestão de Energia',
    flex: 1
  },
  {
    field: 'devecIcms',
    headerName: 'DEVEC/ICMS',
    flex: 1
  },
  {
    field: 'ccee',
    headerName: 'CCEE',
    flex: 1
  },
  {
    field: 'others',
    headerName: 'Outros',
    flex: 1
  }
]
export const columnsCO2Graph: GridColDef[] = [
  {
    field: 'month',
    headerName: 'Mês',
    flex: 1
  },
  {
    field: 'treePlanting',
    headerName: 'Plantio de árvores',
    flex: 1
  },
  {
    field: 'estimatedTreePlanting',
    headerName: 'Plantio estimado de árvores',
    flex: 1
  }
]

export const columnsCreditBalance: GridColDef[] = [
  {
    field: 'month',
    headerName: 'Mês',
    flex: 1
  },
  {
    field: 'injectedCredit',
    headerName: 'Crédito injetado',
    flex: 1
  },
  {
    field: 'consumedCredit',
    headerName: 'Crédito consumido',
    flex: 1
  },
  {
    field: 'remainingBalance',
    headerName: 'Saldo remanescente',
    flex: 1
  },
  {
    field: 'previousBalance',
    headerName: 'Saldo anterior',
    flex: 1
  }
]
