import { Box } from 'shared/designSystem/components/Box'
import { TextField } from 'shared/designSystem/components/TextField'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const FinancialTextField = styledSystem(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': {
    color: theme.designSystem.base[900]
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: '#0509221A',
    borderBottomWidth: '0px'
  },
  '& .MuiInput-underline:hover:before': {
    borderBottomColor: '#0509221A',
    borderBottomWidth: '0px'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#0509221A',
    borderBottomWidth: '0px'
  }
}))

export const SideContainer = styledSystem(Box)(({ theme }) => ({
  padding: `${theme.designSystem.size[16]}px 0`,
  overflowY: 'auto',
  borderRight: '1px solid #0509221A',
  borderTop: '1px solid #0509221A',

  [theme.breakpoints.up('lg')]: {
    height: 'calc(100vh - 140px)'
  }
}))
