import butter from 'shared/services/ButterClient'
import { Tutorial } from '../models/Tutorial'
import { Highlight } from '../models/WeekHighlight'

export class ButterApi {
  static async getTutorials(product: string): Promise<Tutorial[]> {
    const slug = product === '2' ? 'solar_content_center' : 'energy_content_center'

    const response = (await butter.page.retrieve('*', slug)) as {
      data: {
        data: {
          fields: {
            tutorials: {
              meta: { id: number }
              title: string
              description: string
              image: string
              link: string
              tag: string
            }[]
          }
        }
      }
    }

    return response.data.data.fields.tutorials.map((tutorial) => ({
      id: tutorial.meta.id,
      title: tutorial.title,
      description: tutorial.description,
      image: tutorial.image,
      link: tutorial.link,
      tag: tutorial.tag
    }))
  }

  static async getWeekHighlights(product: string): Promise<Highlight[]> {
    const slug = product === '2' ? 'solar_content_center' : 'energy_content_center'
    const response = (await butter.page.retrieve('*', slug)) as {
      data: {
        data: {
          fields: {
            highlights: {
              meta: { id: number }
              title: string
              description: string
              summary: string
              image: string
              link: string
              date_created: string
              suggested_highlights: string[]
              tag: string
            }[]
          }
        }
      }
    }

    return response.data.data.fields.highlights.map((highlight) => ({
      id: highlight.meta.id,
      title: highlight.title,
      description: highlight.description,
      summary: highlight.summary,
      image: highlight.image,
      link: highlight.link,
      dateCreated: highlight.date_created,
      suggestedHighlights: highlight.suggested_highlights,
      tag: highlight.tag
    }))
  }
}
