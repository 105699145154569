import { ChartOptions } from 'chart.js'
import { GraphPropsEconomyAlert } from 'pages/Reports/types/GraphProps'
import { useEffect, useRef, useState } from 'react'
import useFilters from 'shared/hook/useFilters'
import { theme } from 'shared/theme'
import { getDesaturatedColor } from 'shared/utils/getDesaturatedColor'
import { getMonthAbbreviations, getMonthFirstLetter } from 'shared/utils/dates'

const useEconomyAlertGraphEnergy = ({ variant, dataInfos }: GraphPropsEconomyAlert) => {
  const { filters } = useFilters('filtersAll')

  const [isModalOpen, setModalOpen] = useState(false)
  const [isMonthView, setIsMonthView] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({
    type: 'error',
    open: false,
    message: '',
    title: ''
  })

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [tooltipData, setTooltipData] = useState<{
    x: number
    y: number
    visible: boolean
  }>({ x: 0, y: 0, visible: false })

  const isModalVariant = variant === 'modal'
  const demandMeasuredAtPeak = dataInfos?.dataGraphic?.demandMeasuredAtPeak || []
  const demandMeasuredOffPeak = dataInfos?.dataGraphic?.demandMeasuredOffPeak || []
  const contractedMeasuredAtPeak = dataInfos?.dataGraphic?.contractedMeasuredAtPeak
  const contractedMeasuredOffPeak = dataInfos?.dataGraphic?.contractedMeasuredOffPeak
  const hasData = isMonthView
    ? demandMeasuredAtPeak.some((_, index) => index === selectedMonth)
    : !!dataInfos
  const labels = getMonthAbbreviations()

  useEffect(() => {
    if (filters?.month && filters?.month != 0 && !isModalVariant) {
      setSelectedMonth(Number(filters?.month) - 1)
      setIsMonthView(true)
    } else {
      setSelectedMonth(null)
      setIsMonthView(false)
    }
  }, [filters?.month])

  const generateDatasets = (monthIndex: number | null) => {
    const isMobile = window.innerWidth <= 768
    const yearBar = isMobile ? 8 : 22
    const barThickness = monthIndex !== null ? 40 : yearBar

    const filtered = (data: number[]) =>
      monthIndex !== null ? data.filter((_, index) => index === monthIndex) : data

    return [
      {
        label: 'Bar Dataset',
        data: filtered(demandMeasuredAtPeak),
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.primary[100]
            : getDesaturatedColor(theme.designSystem.primary[100]),
        barPercentage: 1,
        barThickness,
        categoryPercentage: 1
      },
      {
        label: 'Bar Dataset',
        data: filtered(demandMeasuredOffPeak),
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.primary[500]
            : getDesaturatedColor(theme.designSystem.primary[500]),
        barPercentage: 1,
        barThickness,
        categoryPercentage: 1
      }
    ]
  }

  const data = {
    labels: isModalVariant ? getMonthAbbreviations() : getMonthFirstLetter(),
    datasets: generateDatasets(null)
  }

  const detailedData = {
    labels: selectedMonth !== null ? [labels[selectedMonth]] : [],
    datasets: generateDatasets(selectedMonth)
  }

  const prevTooltipData = useRef(tooltipData)

  const baseOptions: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    animation: false,
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
      annotation: {
        annotations: {
          line1: {
            type: 'line' as const,
            yMin: contractedMeasuredAtPeak,
            yMax: contractedMeasuredAtPeak,
            borderColor: theme.designSystem.deepOrange[500],
            borderWidth: 1,
            drawTime: 'beforeDatasetsDraw'
          },
          line2: {
            type: 'line' as const,
            yMin: contractedMeasuredOffPeak,
            yMax: contractedMeasuredOffPeak,
            borderColor: theme.designSystem.attention[500],
            borderWidth: 1,
            drawTime: 'beforeDatasetsDraw'
          }
        }
      },

      tooltip: {
        enabled: false,
        external: (context) => {
          const { chart, tooltip } = context

          if (tooltip.opacity === 0) {
            if (tooltipData.visible) {
              setTooltipData((prev) => ({ ...prev, visible: false }))
            }
            return
          }

          const { offsetLeft, offsetTop } = chart.canvas

          if (
            prevTooltipData.current.x !== offsetLeft + tooltip.caretX ||
            prevTooltipData.current.y !== offsetTop + tooltip.caretY ||
            !tooltipData.visible
          ) {
            const newTooltipData = {
              x: offsetLeft + tooltip.caretX,
              y: offsetTop + tooltip.caretY,
              visible: true
            }
            prevTooltipData.current = newTooltipData
            setTooltipData(newTooltipData)
          }
        }
      }
    },
    onHover: (_, activeElements) => {
      const newIndex = activeElements.length ? activeElements[0].index : null
      setHoveredIndex(newIndex)
      if (!activeElements.length) {
        setTimeout(() => {
          setHoveredIndex(null)
        }, 50)
      }
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: isModalVariant
        },
        ticks: {
          display: isModalVariant
        },
        border: {
          display: false
        }
      }
    }
  }

  const options: ChartOptions<'bar' | 'line'> = {
    ...baseOptions,
    onClick: (_, activeElements) => {
      if (activeElements.length > 0 && !isModalVariant) {
        const index = activeElements[0].index
        setSelectedMonth(index)
        setIsMonthView(true)
      }
    }
  }

  const detailedOptions: ChartOptions<'bar' | 'line'> = {
    ...baseOptions,
    onClick: () => {
      setSelectedMonth(null)
      setIsMonthView(false)
    }
  }

  const handleRequestAnalysis = async () => {
    setLoading(true)
    try {
      setModalOpen(true)
    } catch (error) {
      setAlert({
        open: true,
        type: 'error',
        title: 'Falha ao solicitar estudo',
        message: 'Tente novamente'
      })
    } finally {
      setLoading(false)
    }
  }

  const legends = [
    { color: theme.designSystem.primary[100], text: 'Demanda medida em ponta', variant: 'square' },
    { color: theme.designSystem.primary[500], text: 'Demanda medida fora ponta', variant: 'square' },
    {
      color: theme.designSystem.deepOrange[500],
      text: 'Demanda contratada em ponta',
      variant: 'square'
    },
    {
      color: theme.designSystem.attention[500],
      text: 'Demanda contratada fora ponta',
      variant: 'square'
    }
  ]

  const handleEmpty = () => {
    setSelectedMonth(null)
    setIsMonthView(false)
  }

  return {
    isModalVariant,
    hasData,
    data,
    options,
    tooltipData,
    demandMeasuredAtPeak,
    demandMeasuredOffPeak,
    setHoveredIndex,
    hoveredIndex,
    handleRequestAnalysis,
    isModalOpen,
    setModalOpen,
    loading,
    alert,
    legends,
    setAlert,
    contractedMeasuredAtPeak,
    contractedMeasuredOffPeak,
    detailedData,
    detailedOptions,
    isMonthView,
    selectedMonth,
    handleEmpty
  }
}

export default useEconomyAlertGraphEnergy
