import { create } from 'zustand'
import { StoreProps } from './type'
import { TOKEN_KEY } from 'shared/services/Api'

export const useAuthStore = create<StoreProps>((set) => ({
  state: {
    isAuthenticated: !!localStorage.getItem(TOKEN_KEY),
    user: undefined,
    token: ''
  },
  actions: {
    loggout: () => {
      localStorage.clear()
      set((store) => ({
        ...store,
        state: { ...store.state, isAuthenticated: false, token: '' }
      }))
    },
    signIn: () => {
      set((store) => ({
        ...store,
        state: { ...store.state, isAuthenticated: true, token: localStorage.getItem(TOKEN_KEY)! }
      }))
    },
    setToken: (newToken: string) => {
      localStorage.setItem(TOKEN_KEY, newToken)
      set((store) => ({
        ...store,
        state: { ...store.state, token: newToken }
      }))
    }
  }
}))
