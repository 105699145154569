import { Box } from 'shared/designSystem/components/Box'

import CO2Graph from '../../index'
import { Header } from '../../../DetailsModal/Header'
import { ModalContainer } from '../../../DetailsModal/styles'
import { PDFDocument } from '../../../PDFDocument'
import { Loading } from 'shared/designSystem/components/Loading'
import useModalCO2 from './useModal'

export const CO2ModalDetail = () => {
  const { loading, pdfElements, pdfRef, mutateCO2, stateDataCO2, infosHeaderReports } = useModalCO2()

  return (
    <>
      <ModalContainer>
        <Header fetchData={mutateCO2} pdfElements={pdfElements} pdfRef={pdfRef} />
        {loading ? (
          <Loading />
        ) : (
          <Box sx={{ display: 'flex', height: '100%' }}>
            <CO2Graph variant="modal" dataInfos={stateDataCO2} dataInfosHeader={infosHeaderReports} />
          </Box>
        )}
      </ModalContainer>
      <Box
        sx={{
          position: 'absolute',
          top: '-9999px',
          width: '270mm',
          height: '367mm',
          overflow: 'hidden',
          visibility: 'hidden'
        }}
        ref={pdfRef}
      >
        {pdfElements.map((pdf, i) => (
          <PDFDocument
            key={i}
            data={pdf.data}
            columns={pdf.columns}
            name="Relatório de Sustentabilidade"
            footerTable={pdf?.footerTable}
          >
            {pdf.graph}
          </PDFDocument>
        ))}
      </Box>
    </>
  )
}
