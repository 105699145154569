import { memo } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import { GraphBox, GraphContainer } from 'pages/Reports/styles'
import { GraphPropsBalanceCredit } from 'pages/Reports/types/GraphProps'
import { Bar } from 'react-chartjs-2'
import { Legend } from '../../Legend'
import { CustomTooltip } from '../../Tooltip'
import { HeaderReports } from '../../Header'
import { EmptyData } from '../../EmptyData'
import useCreditBalance from './useCreditBalance'

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Tooltip)

const CreditBalanceGraph = ({ variant, dataInfos, dataInfosHeader }: GraphPropsBalanceCredit) => {
  const {
    isModalVariant,
    setHoveredIndex,
    data,
    options,
    tooltipData,
    injectedCredit,
    consumedCredit,
    legends,
    hoveredIndex,
    detailedData,
    detailedOptions,
    isMonthView,
    dataInfosHeaderDetail,
    selectedMonth,
    handleEmpty,
    hasData
  } = useCreditBalance({ variant, dataInfos, dataInfosHeader })

  return (
    <GraphBox isModalVariant={isModalVariant}>
      {dataInfosHeader?.length && (
        <HeaderReports
          infos={isMonthView ? dataInfosHeaderDetail : dataInfosHeader}
          graphic={6}
          graphicName="Balanço de Créditos"
        />
      )}

      {hasData ? (
        <>
          <GraphContainer isModalVariant={isModalVariant} onMouseLeave={() => setHoveredIndex(null)}>
            <Bar
              data={isMonthView ? detailedData : data}
              options={isMonthView ? detailedOptions : options}
            />
            {tooltipData.visible && (
              <CustomTooltip
                x={tooltipData!.x}
                y={tooltipData!.y}
                visible={tooltipData.visible}
                datas={[
                  {
                    label: 'Crédito injetado',
                    value: `${injectedCredit[selectedMonth ?? hoveredIndex!]} KWh`
                  },
                  {
                    label: 'Crédito consumido',
                    value: `${consumedCredit[selectedMonth ?? hoveredIndex!]} KWh`
                  },
                  {
                    label: 'Utilização',
                    value: `${((consumedCredit[selectedMonth ?? hoveredIndex!] / injectedCredit[selectedMonth ?? hoveredIndex!]) * 100).toFixed(2)}%`
                  }
                ]}
              />
            )}
          </GraphContainer>

          <Box display="flex" flexWrap="wrap" gap={1} width="90%">
            {legends
              .filter((item) => !item.show)
              .map((item, index) => (
                <Legend
                  key={index}
                  color={item?.color}
                  text={item?.text}
                  variant={item?.variant}
                  secondaryColor={item?.secondaryColor}
                />
              ))}
          </Box>
        </>
      ) : (
        <EmptyData
          onclick={handleEmpty}
          text="Não há dados disponíveis para a visualização do gráfico."
        />
      )}
    </GraphBox>
  )
}

export default memo(CreditBalanceGraph)
