import { Box } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { Grid } from 'shared/designSystem/components/Grid'
import { Tabs } from 'shared/designSystem/components/Tabs'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

interface ButtonFormatCardTableProps {
  isTable: boolean
  onClick: () => void
}

export const Container = styledSystem(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
}))

export const ContainerContent = styledSystem(Grid)(() => ({
  padding: '18px 0'
}))

export const ContainerValueInput = styledSystem(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const SelectValueYear = styledSystem(Box)(() => ({
  fontWeight: 'bold',
  cursor: 'pointer'
}))

export const TabsStyles = styledSystem(Tabs)(() => ({
  marginBottom: '20px',
  borderBottom: '1px solid #05092261',
  '& .MuiTabs-scroller': {
    overflow: 'scroll !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

export const ContainerFormatTableCard = styledSystem(Box)(() => ({
  borderRadius: '40px',
  padding: '5px 10px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #05092229',
  display: 'flex',
  gap: '5px'
}))

export const ButtonFormatCard = styledSystem(Button)<ButtonFormatCardTableProps>(({ isTable }) => ({
  minWidth: '32px',
  minHeight: '24px',
  borderRadius: '40px',
  backgroundColor: isTable ? '#FFFFFF' : '#000',
  color: isTable ? '#000' : '#fff',
  '&:hover': {
    backgroundColor: isTable ? '#F0F0F0' : '#000'
  }
}))

export const ButtonFormatTable = styledSystem(Button)<ButtonFormatCardTableProps>(({ isTable }) => ({
  minWidth: '32px',
  minHeight: '24px',
  borderRadius: '40px',
  backgroundColor: isTable ? '#000' : '#FFFFFF',
  color: isTable ? '#fff' : '#000',
  '&:hover': {
    backgroundColor: isTable ? '#000' : '#F0F0F0'
  }
}))

export const ContainerChips = styledSystem('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center'
  }
}))
