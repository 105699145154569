import { Button as ButtonMui } from 'shared/designSystem/components/Button'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'
import { Box } from 'shared/designSystem/components/Box'

export const Button = styledSystem(ButtonMui)(({ theme }) => ({
  variant: 'contained',
  height: theme.designSystem.size[48],
  backgroundColor: theme.designSystem.primary[900],
  border: 'solid 1px',
  borderColor: theme.designSystem.base[900],
  borderRadius: theme.designSystem.size[24],
  color: theme.designSystem.base[100],
  fontSize: theme.designSystem.size[14],
  display: 'flex',
  width: '100%'
}))

export const ProductBadgeContainer = styledSystem(Box)<{ selected: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.designSystem.size[12],
  paddingTop: theme.designSystem.size[4],
  paddingRight: theme.designSystem.size[16],
  paddingLeft: theme.designSystem.size[16],
  paddingBottom: theme.designSystem.size[4],
  border: `1px solid ${selected ? theme.designSystem.primary[900] : theme.designSystem.base[300]}`,
  borderRadius: theme.designSystem.size[28],
  width: 'auto',
  maxWidth: 'fit-content',
  color: `${selected ? theme.designSystem.primary[900] : theme.designSystem.base[300]}`,
  fontSize: theme.designSystem.typography.fontSize[14],
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  cursor: 'pointer'
}))

export const FilterDefaultContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
  border: `1px solid ${theme.designSystem.base[200]}`,
  borderRadius: '1%',
  margin: 'auto',
  width: '55%',
  maxWidth: '645px',
  height: '500px'
}))

export const QuestionContainer = styledSystem(Box)(({ theme }) => ({
  marginBottom: theme.designSystem.size[20],
  display: 'flex',
  flexDirection: 'column',
  gap: theme.designSystem.size[10]
}))
