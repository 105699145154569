import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'

import useDataGraph from 'pages/Financial/hooks/useDataGraph'
import { Box } from 'shared/designSystem/components/Box'
import { useFinancialStore } from 'shared/store/financial'
import { formatCurrency } from 'shared/utils/formatCurrency'
import { getMonthAbbreviations } from 'shared/utils/dates'
import { getRandomColor, hslToRgba } from 'shared/utils/getRandomColor'
import { Header } from './Header'
import useTabData from './useTabData'
import LoadingContentAdvanced from 'shared/designSystem/components/LoadingContentAdvanced'

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip)

const TabData = () => {
  const { data, loading } = useDataGraph()

  const { renderTabs, tabItem } = useTabData()
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const [colors, setColors] = useState<string[]>([])

  const {
    state: { selectGroups }
  } = useFinancialStore()

  useEffect(() => {
    if (data?.data?.groups && data?.data?.groups?.length > 0) {
      const currentColors: string[] = []
      data?.data?.groups?.map?.(() => {
        currentColors.push(getRandomColor())
      })
      setColors(currentColors)
    }
  }, [data])

  useEffect(() => {
    if (colors.length === 0) return

    const selectedData = (tabItem === 0 ? data?.data?.groups : data?.data?.aggregateGroup) || []

    const transformedData = selectedData?.map((group: any, index) => {
      const isConsolidated = !group?.groupName

      if (isConsolidated) {
        return {
          label: group?.groupName || 'Consolidado',
          data: group?.monthlyInvoices
            ? group?.monthlyInvoices.map((invoice: any) => invoice.totalInvoiceValue)
            : selectedData?.map?.((item: any) => item.totalInvoiceValue),
          fill: false,
          tension: 0,
          borderColor: '#192488',
          backgroundColor: '#192488',
          pointBackgroundColor: '#192488',
          pointRadius: 5,
          pointHoverRadius: 7,
          borderWidth: 2
        }
      }

      const isActive = selectGroups.length === 0 || selectGroups.some((el) => el === group?.groupId)
      const baseColor = isActive ? colors[index] : hslToRgba(colors[index], 0.3)

      return {
        label: group.groupName || 'Grupo',
        data: group.monthlyInvoices
          ? group.monthlyInvoices.map((invoice: any) => invoice.totalInvoiceValue)
          : selectedData.map((item: any) => item.totalInvoiceValue),
        fill: false,
        tension: 0,
        pointRadius: 5,
        pointHoverRadius: 7,
        borderWidth: 2,
        borderColor: baseColor,
        backgroundColor: baseColor,
        pointBackgroundColor: baseColor
      }
    })

    setSelectedItems(transformedData || [])
  }, [tabItem, data, colors, selectGroups])

  const chartData = {
    labels: getMonthAbbreviations(),
    datasets: selectedItems
  }

  const options: any = {
    responsive: true,
    animation: false,
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: true,
        callbacks: {
          title: function (tooltipItems: any) {
            const monthYear = tooltipItems[0]?.label || ''
            return monthYear
          },
          label: function (item: any) {
            const dataOne = `Todos os Grupos ${formatCurrency(data?.data?.aggregateGroup?.[item?.dataIndex]?.totalInvoiceValue || 0)}`
            const dataTwo = `${data?.data?.groups?.[item?.datasetIndex]?.groupName} ${formatCurrency(data?.data?.groups?.[item?.datasetIndex]?.monthlyInvoices?.[item?.dataIndex]?.totalInvoiceValue || 0)}`
            return tabItem === 0 ? [dataOne, dataTwo] : [dataOne]
          }
        },
        bodyFont: {
          size: 14,
          weight: 'normal',
          family: 'Arial',
          lineHeight: 2.5
        },
        titleFont: {
          size: 16,
          weight: 'bold',
          family: 'Arial',
          lineHeight: 2
        },
        backgroundColor: '#ffffff',
        borderColor: ' #0000000F',
        borderWidth: 1,
        padding: 10,
        boxWidth: 10,
        caretSize: 5,
        titleColor: '#000000',
        bodyColor: '#000000'
      }
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        border: {
          display: false
        }
      }
    }
  }

  return (
    <LoadingContentAdvanced loading={loading} noData={!data}>
      <Header />
      {renderTabs}
      <Box>
        <Line data={chartData} options={options} style={{ paddingBottom: '20px' }} />
      </Box>
    </LoadingContentAdvanced>
  )
}

export default TabData
