import { useEffect, useState } from 'react'
import { IfinancesItemList } from 'shared/services/Requests/finances/types'

const useDrawer = () => {
  const [isOpenedDrawer, setIsOpenedDrawer] = useState(false)
  const [currentItem, setCurrentItem] = useState<IfinancesItemList | null>(null)

  useEffect(() => {
    if (!isOpenedDrawer) setCurrentItem(null)
  }, [isOpenedDrawer])

  const onOpenDrawerDetails = (row: IfinancesItemList) => {
    setIsOpenedDrawer(true)
    setCurrentItem(row)
  }

  return {
    isOpenedDrawer,
    onCloseDrawer: () => setIsOpenedDrawer(false),
    onOpenDrawer: () => setIsOpenedDrawer(true),
    onOpenDrawerDetails,
    setCurrentItem,
    currentItem
  }
}

export default useDrawer
