import { Box } from 'shared/designSystem/components/Box'
import { SubjectOutlinedIcon } from 'shared/designSystem/components/Icons/SubjectOutlinedIcon'
import { WindowOutlinedIcon } from 'shared/designSystem/components/Icons/WindowOutlinedIcon'
import { InputAdornment } from 'shared/designSystem/components/InputAdornment'
import { MenuItem } from 'shared/designSystem/components/MenuItem'
import { Select } from 'shared/designSystem/components/Select'
import { ArrowDown } from 'shared/designSystem/Icons/ArrowDown'
import { useFinancialStore } from 'shared/store/financial'
import useHeaderTabsSolar from './useHeaderTabsSolar'
import { HeaderTabsProps } from '../headerTabs'
import {
  ButtonFormatCard,
  ButtonFormatTable,
  Container,
  ContainerChips,
  ContainerContent,
  ContainerFormatTableCard,
  ContainerValueInput,
  SelectValueYear
} from './style'

const HeaderTabsSolar = ({ tabItem }: HeaderTabsProps) => {
  const { renderTabs, renderTabsFilterHistory, handleChange, filters, globalDate } = useHeaderTabsSolar()
  const {
    state: { isTable },
    actions: { setIsTable }
  } = useFinancialStore()

  // const isUnit = filters && filters.hasOwnProperty('unit')

  return (
    <>
      <Container>
        <ContainerContent container spacing={2}>
          <ContainerFormatTableCard>
            <ButtonFormatCard isTable={isTable} onClick={() => setIsTable(false)}>
              <WindowOutlinedIcon color={isTable ? 'inherit' : 'primary'} />
            </ButtonFormatCard>

            <ButtonFormatTable isTable={isTable} onClick={() => setIsTable(true)}>
              <SubjectOutlinedIcon color={isTable ? 'primary' : 'inherit'} />
            </ButtonFormatTable>
          </ContainerFormatTableCard>
          <ContainerChips>{tabItem == 1 && renderTabsFilterHistory}</ContainerChips>
        </ContainerContent>
        {tabItem == 1 && (
          <Select
            size="small"
            variant="outlined"
            value={filters?.yearF}
            onChange={handleChange}
            displayEmpty
            IconComponent={() => null}
            renderValue={(selected) => (
              <ContainerValueInput>
                <SelectValueYear>{selected || 'Ano'}</SelectValueYear>
                <InputAdornment position="end">
                  <ArrowDown sx={{ cursor: 'pointer' }} />
                </InputAdornment>
              </ContainerValueInput>
            )}
            sx={{
              height: '32px',
              width: '90px',
              borderRadius: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 8px',
              marginTop: '18px'
            }}
          >
            {globalDate?.filterDateList?.map((item) => (
              <MenuItem key={item?.year} value={item?.year?.toString()}>
                {item?.year}
              </MenuItem>
            ))}
          </Select>
        )}
      </Container>

      {tabItem == 1 && <Box>{renderTabs}</Box>}
    </>
  )
}

export default HeaderTabsSolar
