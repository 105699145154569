import { HTMLAttributes } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { theme } from 'shared/theme'
import { TooltipContainer } from './styles'

interface CustomTooltipProps extends HTMLAttributes<HTMLDivElement> {
  visible: boolean
  x: number
  y: number
  datas: { label: string; value: number | string }[]
}

export const CustomTooltip = ({ visible, x, y, datas }: CustomTooltipProps) => {
  if (!visible) return null

  return (
    <TooltipContainer x={x} y={y} visible={visible}>
      {datas.map((data, i) => (
        <Box
          key={i}
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            sx={{
              fontSize: theme.designSystem.typography.fontSize.caption,
              color: theme.designSystem.base[300]
            }}
          >
            {data.label}
          </Typography>
          <Typography
            sx={{
              fontWeight: theme.designSystem.typography.fontWeight.medium,
              fontSize: theme.designSystem.typography.fontSize.button
            }}
          >
            {data.value}
          </Typography>
        </Box>
      ))}
    </TooltipContainer>
  )
}
