import { useEffect, useMemo, useState } from 'react'
import { Checkbox } from 'shared/designSystem/components/Checkbox'
import { ListItem } from 'shared/designSystem/components/ListItem'
import { ListItemButton } from 'shared/designSystem/components/ListItemButton/indext'
import { ListItemIcon } from 'shared/designSystem/components/ListItemIcon'
import { ListItemText } from 'shared/designSystem/components/ListItemText'
import Tag from 'shared/designSystem/components/Tag'
import useFilters from 'shared/hook/useFilters'
import useFiltersGlobal from 'shared/hook/useFiltersGlobal'
import { TypeComboGroup, TypeComboUnit } from 'shared/models/Filters'
import { useFinancialStore } from 'shared/store/financial'

const useFiltersFinancial = () => {
  const { filters, setBulkFilterData } = useFilters('filtersAll')
  const { globalProducts } = useFiltersGlobal()
  const {
    state: { tab, selectGroup, selectGroups, selectUnit, selectUnits },
    actions: { setSelectGroup, setSelectGroups, setSelectUnit, setSelectUnits }
  } = useFinancialStore()

  const [comboUnits, setComboUnits] = useState<TypeComboUnit[]>([])
  const [comboGroups, setComboGroups] = useState<TypeComboGroup[]>([])
  const [groupSelected, setGroupSelected] = useState<TypeComboGroup>()
  const [filterList, setFilterList] = useState('')
  const [checkAll, setCheckAll] = useState(false)

  const handleToggle = (
    value: number,
    options: {
      data: TypeComboUnit[] | TypeComboGroup[]
      selected: number[]
      setSelected: (selectGroups: number[]) => void
    }
  ) => {
    const { data, selected, setSelected } = options

    const numberValue = Number(value)
    if (numberValue === 0) {
      const newChecked: number[] = []
      data.forEach((item: any) => newChecked.push(Number(item.value)))
      setSelected(checkAll ? [] : newChecked)
      return setCheckAll((state) => !state)
    }

    const currentIndex = selected.indexOf(numberValue)
    const newChecked = [...selected]

    if (currentIndex === -1) {
      newChecked.push(numberValue)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setCheckAll(false)
    setSelected(newChecked)
  }

  const handleCombos = () => {
    if (!filters?.yearF) {
      const data = new Date()
      setBulkFilterData({ ...filters, yearF: data.getFullYear() })
    }

    if (filters?.product) {
      const groupFilter = globalProducts?.find((item) => item?.value == filters?.product)
      setComboGroups(groupFilter?.groups ?? [])
    }

    if (filters?.group) {
      const unitsFilter = comboGroups.find((item) => item?.value == filters?.group)
      setGroupSelected(unitsFilter)
      setComboUnits(unitsFilter?.units ?? [])
    }
  }

  const handleFilter = (value: number | string, name: string) => {
    setFilterList('')

    if (name === 'group') {
      setSelectGroup(value)
      setSelectUnit('')
    }

    if (name === 'unit') setSelectUnit(value)
  }

  const handleList = useMemo(() => {
    if (!filters?.group || filters?.group == 0) {
      return comboGroups
        .filter((item) => item.name.toLowerCase().includes(filterList.toLocaleLowerCase()))
        .map((item) => (
          <ListItem
            key={item.value}
            disablePadding
            secondaryAction={item?.pending && <Tag label="Pendente" colorTag="#FF5722" />}
          >
            <ListItemButton onClick={() => handleFilter(item.value, 'group')}>
              <ListItemText secondary={selectGroup == item.value ? <b>{item.name}</b> : item.name} />
            </ListItemButton>
          </ListItem>
        ))
    }

    return (
      <>
        <ListItem
          disablePadding
          secondaryAction={groupSelected?.pending && <Tag label="Pendente" colorTag="#FF5722" />}
        >
          <ListItemButton>
            <ListItemText
              onClick={() => handleFilter(groupSelected?.value || '', 'group')}
              primary={selectUnit ? groupSelected?.name : <b>{groupSelected?.name}</b>}
            />
          </ListItemButton>
        </ListItem>
        {comboUnits
          .filter((item) => item.name.toLowerCase().includes(filterList.toLocaleLowerCase()))
          .map((item) => (
            <ListItem
              key={item.value}
              disablePadding
              secondaryAction={item?.pending && <Tag label="Pendente" colorTag="#FF5722" />}
            >
              <ListItemButton onClick={() => handleFilter(item.value, 'unit')}>
                <ListItemText
                  secondary={
                    selectUnit == item.value ? (
                      <b>
                        {item.value} - {item.name}
                      </b>
                    ) : (
                      `${item.value} - ${item.name}`
                    )
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
      </>
    )
  }, [filters, comboGroups, comboUnits, filterList, tab, selectGroup, selectUnit])

  const handleListCheck = useMemo(() => {
    if (!filters?.group || filters?.group == 0) {
      return comboGroups
        .filter((item) => item.name.toLowerCase().includes(filterList.toLocaleLowerCase()))
        .map((item) => (
          <ListItem
            key={item.value}
            disablePadding
            secondaryAction={item?.pending && <Tag label="Pendente" colorTag="#FF5722" />}
          >
            <ListItemButton
              onClick={() =>
                handleToggle(Number(item.value), {
                  data: comboGroups,
                  selected: selectGroups,
                  setSelected: setSelectGroups
                })
              }
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={item.value === 0 ? checkAll : selectGroups.includes(Number(item.value))}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                secondary={selectGroups.includes(Number(item.value)) ? <b>{item.name}</b> : item.name}
              />
            </ListItemButton>
          </ListItem>
        ))
    }

    return (
      <>
        <ListItem
          disablePadding
          secondaryAction={groupSelected?.pending && <Tag label="Pendente" colorTag="#FF5722" />}
        >
          <ListItemButton
            onClick={() =>
              handleToggle(0, {
                data: comboUnits,
                selected: selectUnits,
                setSelected: setSelectUnits
              })
            }
            dense
          >
            <ListItemIcon>
              <Checkbox edge="start" checked={checkAll} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText primary={!checkAll ? groupSelected?.name : <b>{groupSelected?.name}</b>} />
          </ListItemButton>
        </ListItem>
        {comboUnits
          .filter((item) => item?.name?.toLowerCase()?.includes(filterList?.toLocaleLowerCase()))
          .map((item) => (
            <ListItem
              key={item.value}
              disablePadding
              secondaryAction={item?.pending && <Tag label="Pendente" colorTag="#FF5722" />}
            >
              <ListItemButton
                onClick={() =>
                  handleToggle(Number(item.value), {
                    data: comboUnits,
                    selected: selectUnits,
                    setSelected: setSelectUnits
                  })
                }
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectUnits.includes(Number(item.value))}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  secondary={
                    selectUnits.includes(Number(item.value)) ? (
                      <b>
                        {item.value} - {item.name}
                      </b>
                    ) : (
                      `${item.value} - ${item.name}`
                    )
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
      </>
    )
  }, [
    filters,
    comboGroups,
    comboUnits,
    filterList,
    tab,
    selectGroup,
    selectUnit,
    selectUnits,
    selectGroups
  ])

  useEffect(() => {
    handleCombos()
  })

  useEffect(() => {
    setCheckAll(false)
    setSelectUnits([])
    setSelectGroups([])
    setSelectUnit('')
    setSelectGroup('')
  }, [filters?.product, filters?.group, filters?.unit])

  return {
    handleList,
    handleListCheck,
    filterList,
    setFilterList,
    tab
  }
}

export default useFiltersFinancial
