import { AlertSnackbar } from 'shared/designSystem/components/AlertSnackbar'
import { Box } from 'shared/designSystem/components/Box'
import { DotsLoader } from 'shared/designSystem/components/DotsLoader'
import { LogoutTooltip } from 'shared/designSystem/components/LogoutTooltip'
import { TabSidebar } from 'shared/designSystem/components/TabSidebar'
import { TabsSideBar } from 'shared/designSystem/components/TabsSideBar'
import { IconExit } from 'shared/designSystem/Icons/IconExit'
import { Logo } from 'shared/designSystem/Icons/IconLogo'
import { Background, ButtonBurguer, ContainerButtonBurguer, SideContainerStyled } from './style'
import { IconHamburguer } from 'shared/designSystem/Icons/IconHamburger'
import useSidebar from './useSidebar'
import React from 'react'

export const Sidebar = () => {
  const {
    loading,
    error,
    theme,
    location,
    tabs,
    open,
    activeColor,
    inactiveColor,
    toggleSidebar,
    navigate,
    setError,
    handleLogout
  } = useSidebar()

  return (
    <>
      <ContainerButtonBurguer>
        <ButtonBurguer open={open} onClick={toggleSidebar}>
          <IconHamburguer color={open ? '#ffffff' : '#050922'} />
        </ButtonBurguer>
      </ContainerButtonBurguer>

      {open && <Background />}

      <SideContainerStyled open={open}>
        <Box>
          <Logo role="img" />
        </Box>

        <TabsSideBar
          value={tabs.findIndex((tab) => tab.path === location.pathname)}
          onChange={(_, newIndex) => navigate(tabs[newIndex].path)}
        >
          {tabs.map((tab, index) => {
            const iconColor = location.pathname === tab.path ? activeColor : inactiveColor

            return (
              <TabSidebar
                key={index}
                label={
                  <Box
                    style={{
                      color: iconColor
                    }}
                  >
                    {tab.label && React.cloneElement(tab.label, { strokeColor: iconColor })}
                  </Box>
                }
              />
            )
          })}
        </TabsSideBar>

        <LogoutTooltip title="Clique para desconectar">
          <Box
            color={theme.designSystem.deepOrange[400]}
            onClick={handleLogout}
            style={{ cursor: 'pointer' }}
          >
            {loading ? <DotsLoader /> : <IconExit role="exit" />}
          </Box>
        </LogoutTooltip>

        <AlertSnackbar
          title="Falha ao desconectar"
          message="Tente novamente"
          open={error}
          type="error"
          onClose={() => setError(false)}
        />
      </SideContainerStyled>
    </>
  )
}
