import { ChartData, ChartOptions } from 'chart.js'
import { GraphPropsTotal } from 'pages/Reports/types/GraphProps'
import { useMemo, useState } from 'react'
import useFilters from 'shared/hook/useFilters'
import { theme } from 'shared/theme'
import { formatCurrency } from 'shared/utils/formatCurrency'
import { getDesaturatedColor } from 'shared/utils/getDesaturatedColor'
import { getMonths } from 'shared/utils/dates'

const useTotalGraphEnergy = ({ variant, dataInfos }: GraphPropsTotal) => {
  const { filters } = useFilters('filtersAll')

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const isModalVariant = variant === 'modal'
  const hasMonth = filters?.month && filters?.month != 0 && !isModalVariant
  const months = getMonths()
  const hasData = hasMonth
    ? dataInfos?.dataGraphic?.months?.some((item) => item.month === Number(filters?.month) - 1)
    : !!dataInfos

  const percentages = useMemo(() => {
    const data = hasMonth
      ? dataInfos?.dataGraphic?.months?.find((item) => item.month === Number(filters?.month) - 1)
      : dataInfos?.dataGraphic
    return [
      data?.energy?.percentage,
      data?.distribution?.percentage,
      data?.energyManagement?.percentage,
      data?.devecIcms?.percentage,
      data?.ccee?.percentage,
      data?.extraReleases?.percentage
    ]
  }, [filters, dataInfos])

  const dataChart = useMemo(() => {
    const data = hasMonth
      ? dataInfos?.dataGraphic?.months?.find((item) => item.month === Number(filters?.month) - 1)
      : dataInfos?.dataGraphic
    return [
      data?.energy.cost || 0,
      data?.distribution.cost || 0,
      data?.energyManagement.cost || 0,
      data?.devecIcms.cost || 0,
      data?.ccee.cost || 0,
      data?.extraReleases.cost || 0
    ]
  }, [filters, dataInfos])

  const dataInfosHeaderDetail = useMemo(() => {
    const data = dataInfos?.dataGraphic?.months?.find(
      (item) => item.month === Number(filters?.month) - 1
    )
    return [
      {
        titleValue: `Custo total em ${months[Number(filters?.month) - 1]}`,
        value: data?.costPerMonth || 'R$ 0,00',
        tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
      }
    ]
  }, [filters, dataInfos])

  const data: ChartData<'doughnut'> = {
    labels: ['Energia', 'Distribuição', 'Gestão de Energia', 'DEVEC/ICMS', 'CCEE', 'Lançamentos Extras'],
    datasets: [
      {
        label: 'My First Dataset',
        data: dataChart,
        backgroundColor: (context) => {
          const dataIndex = context.dataIndex
          const colors = [
            theme.designSystem.primary[500],
            theme.designSystem.primary[300],
            theme.designSystem.primary[100],
            theme.designSystem.attention[500],
            theme.designSystem.deepOrange[400],
            theme.designSystem.deepOrange[500]
          ]

          return hoveredIndex === null || hoveredIndex === dataIndex
            ? colors[dataIndex]
            : getDesaturatedColor(colors[dataIndex])
        },
        hoverOffset: 0,
        borderWidth: 0
      }
    ]
  }

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        display: false
      }
    },
    onHover: (_, activeElements) => {
      const newIndex = activeElements.length ? activeElements[0].index : null
      setHoveredIndex(newIndex)
      if (!activeElements.length) {
        setTimeout(() => {
          setHoveredIndex(null)
        }, 50)
      }
    },
    cutout: '87%'
  }

  const textCenter = {
    id: 'texteCenter',
    beforeDatasetDraw(chart: any) {
      const { ctx } = chart
      const { x, y } = chart.getDatasetMeta(0).data[0]

      const offsetTop = -30
      const offsetBottom = 30

      ctx.save()
      ctx.clearRect(
        chart.chartArea.left,
        chart.chartArea.top,
        chart.chartArea.right - chart.chartArea.left,
        chart.chartArea.bottom - chart.chartArea.top
      )

      ctx.font = 'bold 20px sans-serif'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'

      const activeElements = chart.getActiveElements()
      if (activeElements.length > 0) {
        const activeIndex = activeElements[0].index
        const datasetIndex = activeElements[0].datasetIndex
        const value = chart.data.datasets[datasetIndex].data[activeIndex]
        const label = chart.data.labels?.[activeIndex]

        ctx.fillStyle = theme.designSystem.base[400]
        ctx.font = `${theme.designSystem.typography.fontWeight['medium']} ${theme.designSystem.typography.fontSize['caption']}px sans-serif`
        ctx.fillText(`${label}`, x, y + offsetTop)

        ctx.fillStyle = theme.designSystem.base[900]
        ctx.font = `${theme.designSystem.typography.fontSize['body1']}px sans-serif`
        ctx.fillText(`${formatCurrency(value)}`, x, y)

        ctx.fillStyle = theme.designSystem.base[400]
        ctx.font = `${theme.designSystem.typography.fontWeight['medium']} ${theme.designSystem.typography.fontSize['caption']}px sans-serif`
        ctx.fillText(percentages[activeIndex], x, y + offsetBottom)
        return
      }

      ctx.fillStyle = theme.designSystem.base[400]
      ctx.fillText('100%', x, y)
    }
  }

  const legends = [
    { color: theme.designSystem.primary[500], text: 'Energia', variant: 'square' },
    { color: theme.designSystem.primary[300], text: 'Distribuição', variant: 'square' },
    { color: theme.designSystem.primary[100], text: 'Gestão de Energia', variant: 'square' },
    { color: theme.designSystem.attention[500], text: 'DEVEC/ICMS', variant: 'square' },
    { color: theme.designSystem.deepOrange[400], text: 'CCEE', variant: 'square' },
    { color: theme.designSystem.deepOrange[500], text: 'Lançamentos extras', variant: 'square' }
  ]

  return { isModalVariant, data, options, textCenter, legends, hasMonth, dataInfosHeaderDetail, hasData }
}

export default useTotalGraphEnergy
