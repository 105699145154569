import { Typography } from 'shared/designSystem/components/Typography'
import { useConsumptionStore } from 'shared/store/consumption'
import { theme } from 'shared/theme'
import { EcoImpactContainer, TypographyStyled } from './styles'

export const EcoImpact = () => {
  const {
    state: { ecoImpact }
  } = useConsumptionStore()
  const { tons, trees } = ecoImpact
  return (
    <EcoImpactContainer>
      <TypographyStyled>{tons} toneladas</TypographyStyled>

      <Typography
        fontSize={theme.designSystem.typography.fontSize.button}
        fontWeight={theme.designSystem.typography.fontWeight.medium}
        color={theme.designSystem.base[400]}
      >
        de CO₂ poupados no ano e que equivalem a
      </Typography>
      <TypographyStyled>{trees} árvores</TypographyStyled>
    </EcoImpactContainer>
  )
}
