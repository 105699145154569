import { DataGrid } from 'shared/designSystem/components/DataGrid'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const StyledDataGrid = styledSystem(DataGrid)(({ theme }) => ({
  backgroundColor: theme.designSystem.white,
  border: 1,
  height: '100%',

  '.MuiDataGrid-columnHeader': {
    backgroundColor: `${theme.designSystem.base[900]}0A`,
    color: `${theme.designSystem.base[900]}99`,
    '*': {
      fontWeight: 600,
      fontSize: '16px'
    }
  },

  '.MuiDataGrid-row, .MuiDataGrid-cell': {
    alignItems: 'center',
    display: 'flex'
  },

  '.MuiDataGrid-columnSeparator': {
    display: 'none'
  }
}))
