import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconCaretLeft } from 'shared/designSystem/Icons/IconCaretLeft'
import { IconCaretRight } from 'shared/designSystem/Icons/IconCaretRight'
import { theme } from 'shared/theme'
import { Header } from '../../../../DetailsModal/Header'
import {
  ButtonExpand,
  DetailsContainer,
  InformationsContainer,
  ModalContainer
} from '../../../../DetailsModal/styles'
import { PDFDocument } from '../../../../PDFDocument'
import { TableWithExpand } from '../../../../DetailsModal/Table'
import { DataTableDetailsCreditBalance } from 'shared/services/Requests/Reports/types'
import { Loading } from 'shared/designSystem/components/Loading'
import useModalConsume from './useModal'
import CreditBalanceGraph from '../..'

export const CreditBalanceModalDetail = () => {
  const {
    pdfRef,
    pdfElements,
    loading,
    stateDataCreditBalance,
    infosHeaderReport,
    isExpanded,
    mutateCreditBalance,
    handleToggleExpand
  } = useModalConsume()

  return (
    <>
      <ModalContainer>
        <Header fetchData={mutateCreditBalance} pdfElements={pdfElements} pdfRef={pdfRef} />

        {loading ? (
          <Loading />
        ) : (
          <Box sx={{ display: 'flex', height: '100%', justifyContent: { md: 'flex-end' } }}>
            <CreditBalanceGraph
              variant="modal"
              dataInfos={stateDataCreditBalance}
              dataInfosHeader={infosHeaderReport}
            />
            <DetailsContainer isExpanded={isExpanded}>
              <ButtonExpand onClick={handleToggleExpand}>
                {isExpanded ? <IconCaretRight /> : <IconCaretLeft />}
              </ButtonExpand>
              {isExpanded &&
                stateDataCreditBalance?.dataTableDetails?.length &&
                stateDataCreditBalance?.dataTableDetails?.map(
                  (item: DataTableDetailsCreditBalance, index) => (
                    <InformationsContainer key={index}>
                      <Typography
                        fontSize={theme.designSystem.typography.fontSize.h3}
                        fontWeight={theme.designSystem.typography.fontWeight.medium}
                        color={theme.designSystem.base[900]}
                        sx={{ mb: theme.designSystem.size[2] }}
                      >
                        {item?.title}
                      </Typography>

                      <Typography
                        fontSize={theme.designSystem.typography.fontSize.p}
                        fontWeight={theme.designSystem.typography.fontWeight.normal}
                        color={theme.designSystem.base[400]}
                      >
                        {item?.description}
                      </Typography>
                      <Box
                        sx={{
                          border: `1px solid ${theme.designSystem.base[200]}`,
                          mt: theme.designSystem.size[5]
                        }}
                      >
                        {item?.data?.map(({ label, value, dataExpand }, i) => (
                          <Box key={i}>
                            <TableWithExpand label={label} value={value} dataExpand={dataExpand} />
                          </Box>
                        ))}
                      </Box>
                    </InformationsContainer>
                  )
                )}
            </DetailsContainer>
          </Box>
        )}
      </ModalContainer>
      <Box
        sx={{
          position: 'absolute',
          top: '-9999px'
        }}
        ref={pdfRef}
      >
        {pdfElements.map((pdf, i) => (
          <PDFDocument
            key={i}
            data={pdf.data}
            columns={pdf.columns}
            name="Relatório de Balanço de Créditos"
            footerTable={pdf?.footerTable}
          >
            {pdf.graph}
          </PDFDocument>
        ))}
      </Box>
    </>
  )
}
