import { useEffect, useState } from 'react'
import { useConsumptionStore } from 'shared/store/consumption'
import { theme } from 'shared/theme'

export const useEconomyGraph = () => {
  const {
    state: { filteredConsumes, filteredEconomy, unit, errorEconomy }
  } = useConsumptionStore()

  const [hoveredBar, setHoveredBar] = useState<null | number>(null)
  const [consumptionValue, setConsumptionValue] = useState(0)

  useEffect(() => {
    if (unit) {
      const selectedConsumption = filteredConsumes.find(
        (consumption: any) => consumption.unit[0].installationNumber === unit.installationNumber
      )
      setConsumptionValue(selectedConsumption?.value ?? 0)
    }
  }, [unit, filteredConsumes])

  const getBarColor = (index: number): string => {
    const { primary, base } = theme.designSystem

    if (hoveredBar === null) {
      const defaultColors = [primary[500], primary[50], primary[200]]
      return defaultColors[index] || primary[200]
    }

    return hoveredBar === index ? primary[500] : base[200]
  }

  const totalCost = (filteredEconomy?.totalGenialCost || 0) + (filteredEconomy?.totalEconomyValue || 0)

  const getFlexValue = (cost: number) => (totalCost ? cost / totalCost : 0)

  useEffect(() => {
    if (!unit) return
    const selectedConsumption = filteredConsumes.find(
      (consumption: any) => consumption.unit[0].installationNumber === unit.installationNumber
    )
    setConsumptionValue(selectedConsumption?.value ?? 0)
  }, [unit, filteredConsumes])

  return { consumptionValue, getBarColor, getFlexValue, errorEconomy, filteredEconomy, setHoveredBar }
}
