import { useEffect, useState } from 'react'
import useFilters from 'shared/hook/useFilters'
import useFiltersGlobal from 'shared/hook/useFiltersGlobal'
import { TypeComboGroup, TypeComboUnit } from 'shared/models/Filters'
import { useApiAllReportsEnergy, useApiAllReportsSolar } from 'shared/services/Requests/Reports'
import { Props } from '.'

const useFilterDefault = ({ setFilterDefault, setDefaultYear, isFinancial }: Props) => {
  const { mutateEnergy, loading: loadingEnergy } = useApiAllReportsEnergy()
  const { mutateSolar, loading: loadingSolar } = useApiAllReportsSolar()
  const { filters, setBulkFilterData } = useFilters('filtersAll')
  const { globalProducts } = useFiltersGlobal()

  const [comboUnits, setComboUnits] = useState<TypeComboUnit[]>([])
  const [comboGroups, setComboGroups] = useState<TypeComboGroup[]>([])
  const [error, setError] = useState<{ status: boolean; message: string }>({
    status: false,
    message: ''
  })

  const loading = loadingEnergy || loadingSolar

  const handleSubmit = async () => {
    handleCloseError()

    if (!filters?.product || !filters?.group)
      return setError({
        status: true,
        message: 'Por favor selecione um produto e uma unidade!'
      })

    if (isFinancial) return setFilterDefault(false)

    const dataFilters = {
      year: filters?.year,
      productId: filters?.product,
      groupId: filters?.group,
      unitId: filters?.unit
    }

    switch (Number(filters?.product)) {
      case 1:
        await mutateEnergy(dataFilters)
        break
      case 2:
        await mutateSolar(dataFilters)
        break
      default:
        break
    }

    setFilterDefault(false)
  }

  const handleCloseError = () => {
    setError({
      status: false,
      message: ''
    })
  }

  const handleChange = (field: string, value: string | number) => {
    if (field === 'product') {
      setDefaultYear?.(true)
      return setBulkFilterData({ ...filters, [field]: value, group: '', unit: '' })
    }

    if (field === 'group') {
      return setBulkFilterData({ ...filters, [field]: value, unit: '' })
    }

    setBulkFilterData({ ...filters, [field]: value })
  }

  const handleInitialParams = () => {
    let datagroup
    if (filters?.product) {
      datagroup = globalProducts?.find((item: any) => item.value == filters?.product)
      if (datagroup) setComboGroups(datagroup?.groups)
    }
    if (filters?.group) {
      const dataUnit = datagroup?.groups?.find((item: any) => item.value == filters?.group)
      if (dataUnit) setComboUnits(dataUnit?.units)
    }
  }

  useEffect(() => {
    if (!filters?.year) {
      const date = new Date()
      setBulkFilterData({ ...filters, year: date.getFullYear() })
    }

    handleInitialParams()
  }, [filters])

  return {
    error,
    handleCloseError,
    globalProducts,
    filters,
    handleChange,
    comboGroups,
    comboUnits,
    handleSubmit,
    loading
  }
}

export default useFilterDefault
