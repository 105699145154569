import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import { GraphBox, GraphContainer } from 'pages/Reports/styles'
import { GraphPropsEconomy } from 'pages/Reports/types/GraphProps'
import { memo } from 'react'
import { Bar } from 'react-chartjs-2'
import { Box } from 'shared/designSystem/components/Box'
import { Legend } from '../../Legend'
import { CustomTooltip } from '../../Tooltip'
import { HeaderReports } from '../../Header'
import { EmptyData } from '../../EmptyData'
import useEconomyGraphSolar from './useEconomyGraphSolar'
import { formatCurrency } from 'shared/utils/formatCurrency'

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Tooltip)

const EconomyGraphSolar = ({ variant, dataInfos, dataInfosHeader }: GraphPropsEconomy) => {
  const {
    isModalVariant,
    hasData,
    setHoveredIndex,
    data,
    options,
    tooltipData,
    traditionalCost,
    costGenial,
    hoveredIndex,
    legends,
    monthlySavings,
    detailedData,
    detailedOptions,
    isMonthView,
    selectedMonth,
    dataInfosHeaderDetail,
    handleEmpty
  } = useEconomyGraphSolar({ variant, dataInfos, dataInfosHeader })

  return (
    <GraphBox isModalVariant={isModalVariant}>
      {dataInfosHeader?.length && (
        <HeaderReports
          infos={isMonthView ? dataInfosHeaderDetail : dataInfosHeader}
          graphic={2}
          graphicName="Economia"
        />
      )}
      {hasData ? (
        <>
          <GraphContainer isModalVariant={isModalVariant} onMouseLeave={() => setHoveredIndex(null)}>
            <Bar
              data={isMonthView ? detailedData : data}
              options={isMonthView ? detailedOptions : options}
            />
            {tooltipData.visible && (
              <CustomTooltip
                x={tooltipData!.x}
                y={tooltipData!.y}
                visible={tooltipData.visible}
                style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                datas={[
                  {
                    label: 'Custo tradicional',
                    value: formatCurrency(traditionalCost[selectedMonth ?? hoveredIndex!])
                  },
                  {
                    label: 'Custo na Genial',
                    value: formatCurrency(costGenial[selectedMonth ?? hoveredIndex!])
                  },
                  {
                    label: `Economia ${isMonthView ? 'mensal' : 'no mês'}`,
                    value: formatCurrency(monthlySavings[selectedMonth ?? hoveredIndex!])
                  }
                ]}
              />
            )}
          </GraphContainer>

          <Box display="flex" flexWrap="wrap" gap={1} width="90%" sx={{ mt: 2 }}>
            {legends
              .filter((item) => !item.show)
              .map((item, index) => (
                <Legend
                  key={index}
                  color={item?.color}
                  text={item?.text}
                  variant={item?.variant}
                  secondaryColor={item?.secondaryColor}
                />
              ))}
          </Box>
        </>
      ) : (
        <EmptyData
          onclick={handleEmpty}
          text="Não há dados disponíveis para a visualização do gráfico."
        />
      )}
    </GraphBox>
  )
}

export default memo(EconomyGraphSolar)
