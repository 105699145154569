import { StyledButton } from 'pages/Reports/styles'
import { InfosHeaderType } from 'pages/Reports/types/GraphProps'
import { Box } from 'shared/designSystem/components/Box'
import { InfoTooltip } from 'shared/designSystem/components/InfoTooltip'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconExpand } from 'shared/designSystem/Icons/IconExpand'
import IconInfo from 'shared/designSystem/Icons/IconInfo'
import { useTheme } from 'shared/hook/useTheme'
import { useReportStore } from 'shared/store/reports'
import { ContainerRenderTitle, StyledTypographyParaghraph, StyledTypographyTitle } from './style'

interface Props {
  infos: InfosHeaderType[]
  graphic: 0 | 1 | 2 | 3 | 4 | 5 | 6
  graphicName: string
}

export const HeaderReports = ({ infos, graphic, graphicName }: Props) => {
  const {
    state: { isOpen },
    actions: { openModal }
  } = useReportStore()

  const theme = useTheme()

  const renderTitle = (title?: string, value?: string, longText?: string, index?: number) => (
    <Box key={index}>
      <StyledTypographyTitle>{title}</StyledTypographyTitle>
      <Box display="flex" alignItems="center" gap={1}>
        <StyledTypographyParaghraph
          fontSize={theme.designSystem.typography.fontSize.h3}
          fontWeight={theme.designSystem.typography.fontWeight.medium}
          color={theme.designSystem.base[900]}
        >
          {value}
        </StyledTypographyParaghraph>
        {longText && (
          <InfoTooltip title={longText}>
            <Box display="inline-flex" id="hide-in-pdf">
              <IconInfo />
            </Box>
          </InfoTooltip>
        )}
      </Box>
    </Box>
  )

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
      {infos?.map(
        (item, index) =>
          item?.title1 && (
            <Box key={index} display="flex">
              {item?.icon && (
                <Box display="flex" justifyContent="space-between" alignItems="flex-start" mr={2}>
                  {item?.icon}
                </Box>
              )}

              <Box sx={{ flexDirection: 'column', display: 'flex', gap: 0.5 }}>
                {item?.title1 && (
                  <Typography
                    fontSize={theme.designSystem.typography.fontSize.h5}
                    fontWeight={theme.designSystem.typography.fontWeight.medium}
                    color={theme.designSystem.base[900]}
                  >
                    {item?.title1}
                  </Typography>
                )}

                {item?.title2 && <StyledTypographyTitle>{item?.title2}</StyledTypographyTitle>}
              </Box>
            </Box>
          )
      )}

      <ContainerRenderTitle>
        {infos?.map(
          (item, index) =>
            item?.value && renderTitle(item?.titleValue, item?.value, item?.tooltipInfos, index)
        )}
      </ContainerRenderTitle>

      {!isOpen && (
        <StyledButton
          variant="outlined"
          size="small"
          id="hide-in-pdf"
          onClick={() => openModal(graphic, graphicName, true)}
        >
          <IconExpand />
        </StyledButton>
      )}
    </Box>
  )
}
