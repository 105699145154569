interface Props {
  color?: 'inherit' | 'primary'
}

export const WindowOutlinedIcon = ({ color = 'inherit' }: Props) => {
  const fillColor = color === 'inherit' ? '#000000' : '#FFFFFF'
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33464 3.66699H3.66797V6.33366H6.33464V3.66699ZM2.66797 2.66699V7.33366H7.33464V2.66699H2.66797Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3346 3.66699H9.66797V6.33366H12.3346V3.66699ZM8.66797 2.66699V7.33366H13.3346V2.66699H8.66797Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3346 9.66699H9.66797V12.3337H12.3346V9.66699ZM8.66797 8.66699V13.3337H13.3346V8.66699H8.66797Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33464 9.66699H3.66797V12.3337H6.33464V9.66699ZM2.66797 8.66699V13.3337H7.33464V8.66699H2.66797Z"
        fill={fillColor}
      />
    </svg>
  )
}
