import { memo } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'

import { GraphBox, GraphContainer } from 'pages/Reports/styles'
import { GraphPropsEconomyAlert } from 'pages/Reports/types/GraphProps'
import { Bar } from 'react-chartjs-2'
import { Legend } from '../../Legend'
import { CustomTooltip } from '../../Tooltip'
import { HeaderReports } from '../../Header'
import { EmptyData } from '../../EmptyData'
import useEconomyAlertGraph from './useEconomyAlertGraph'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  annotationPlugin
)

const EconomyAlertGraph = ({ variant, dataInfos, dataInfosHeader }: GraphPropsEconomyAlert) => {
  const {
    isModalVariant,
    hasData,
    data,
    options,
    tooltipData,
    demandMeasuredAtPeak,
    demandMeasuredOffPeak,
    setHoveredIndex,
    hoveredIndex,
    legends,
    contractedMeasuredAtPeak,
    contractedMeasuredOffPeak,
    detailedData,
    detailedOptions,
    isMonthView,
    selectedMonth,
    handleEmpty
  } = useEconomyAlertGraph({ variant, dataInfos, dataInfosHeader })

  return (
    <GraphBox isModalVariant={isModalVariant}>
      <HeaderReports infos={dataInfosHeader} graphic={4} graphicName="Demanda" />

      {hasData ? (
        <>
          <GraphContainer isModalVariant={isModalVariant} onMouseLeave={() => setHoveredIndex(null)}>
            <Bar
              data={isMonthView ? detailedData : data}
              options={isMonthView ? detailedOptions : options}
            />
            {tooltipData.visible && (
              <CustomTooltip
                x={tooltipData!.x}
                y={tooltipData!.y}
                visible={tooltipData.visible}
                datas={[
                  {
                    label: 'Demanda medida em ponta',
                    value: `${demandMeasuredAtPeak[selectedMonth ?? hoveredIndex!]} MWh`
                  },
                  {
                    label: 'Demanda medida fora ponta',
                    value: `${demandMeasuredOffPeak[selectedMonth ?? hoveredIndex!]} MWh`
                  },
                  {
                    label: 'Demanda contratada em ponta',
                    value: `${contractedMeasuredAtPeak || 0} MWh`
                  },
                  {
                    label: 'Demanda contratada fora ponta',
                    value: `${contractedMeasuredOffPeak || 0} MWh`
                  }
                ]}
              />
            )}
          </GraphContainer>

          <Box display="flex" flexWrap="wrap" gap={1} width="90%">
            {legends.map((item, index) => (
              <Legend key={index} color={item?.color} text={item?.text} variant={item?.variant} />
            ))}
          </Box>
        </>
      ) : (
        <EmptyData
          onclick={handleEmpty}
          text="Não há dados disponíveis para a visualização do gráfico."
        />
      )}
    </GraphBox>
  )
}

export default memo(EconomyAlertGraph)
