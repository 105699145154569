export default {
  unit: null,
  units: [],
  consume: 0,
  consumes: [],
  filteredConsumes: [],
  filteredEconomy: {
    totalTraditionalCost: 0,
    totalGenialCost: 0,
    totalEconomyValue: 0
  },
  filteredPayment: [],
  groups: [],
  selectedGroup: null,
  products: [
    { value: 0, name: 'Todos os produtos' },
    { value: 1, name: 'Energy' }
  ],
  selectedProduct: '',
  filteredGroups: [],
  error: false,
  errorConsumes: false,
  errorEconomy: false,
  errorPayments: false,
  ecoImpact: {
    trees: 0,
    tons: 0
  }
}
