import { useEffect, useMemo, useRef, useState } from 'react'
import { columnsTotalGraph } from 'shared/mocks/columnsPdf'
import TotalGraph from '../..'
import { ReportDataTotal } from 'shared/services/Requests/Reports/types'
import { useApiTotal } from 'shared/services/Requests/Reports'
import { useReportStore } from 'shared/store/reports'
import useFilters from 'shared/hook/useFilters'

const useModalTotalEnergy = () => {
  const { filters } = useFilters('filtersAll')
  const { mutateTotal, apiTotal, loading } = useApiTotal()
  const {
    state: { graphic }
  } = useReportStore()

  const [stateDataTotal, setStateDataTotal] = useState<ReportDataTotal | null>(apiTotal || null)
  const [isExpanded, setIsExpanded] = useState(false)
  const pdfRef = useRef(null)

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const infosHeaderReports = [
    {
      titleValue: 'Custo total no ano',
      value: stateDataTotal?.dataGraphic?.costPerYear || 'R$ 0,00',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    }
  ]

  const pdfElements = useMemo(
    () => [
      {
        graph: (
          <TotalGraph variant="modal" dataInfos={stateDataTotal} dataInfosHeader={infosHeaderReports} />
        ),
        data: stateDataTotal?.dataTable,
        columns: columnsTotalGraph,
        footerTable: stateDataTotal?.tableTotals
      }
    ],
    [stateDataTotal]
  )

  useEffect(() => {
    if (graphic === 3 && filters?.year)
      mutateTotal({
        year: filters?.year,
        productId: filters?.product,
        groupId: filters?.group,
        unitId: filters?.unit
      })
  }, [graphic, filters])

  useEffect(() => {
    setStateDataTotal(apiTotal)
  }, [apiTotal])

  return {
    pdfRef,
    pdfElements,
    loading,
    stateDataTotal,
    infosHeaderReports,
    isExpanded,
    mutateTotal,
    handleToggleExpand
  }
}

export default useModalTotalEnergy
