import { useState } from 'react'
import { AlertSnackbar } from 'shared/designSystem/components/AlertSnackbar'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { useTheme } from 'shared/hook/useTheme'
import { useApiEmail, useApiSendSecurityCode } from 'shared/services/Requests/Login'
import { DotsLoader } from '../../../../shared/designSystem/components/DotsLoader'
import { DividerContainer, TextFieldInput } from '../../styles'
import { PasswordRecoveryButton } from '../PasswordRecoveryButton'
import { RecoveryTitle } from '../PasswordRecoveryTitle'
import { BoxContainer } from '../styles'

type Props = {
  handleClose: () => void
  handleSwitchToReset: (email: string) => void
  email: string
  setEmail: (email: string) => void
}

export const RecoverPassword = ({ handleClose, handleSwitchToReset, email, setEmail }: Props) => {
  const theme = useTheme()

  const [error, setError] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)

  const { mutateEmail, loading } = useApiEmail()
  const { mutateSendSecurityCode } = useApiSendSecurityCode()

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedEmail = event.target.value.trim().toLowerCase()

    setEmail(trimmedEmail)
    if (error) setError('')
    if (alertOpen) setAlertOpen(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ') {
      event.preventDefault()
    }
  }

  const verifyEmail = () => {
    if (!email) {
      setError('Este campo é obrigatório')
      return
    }

    if (!validateEmail(email)) {
      setError('Por favor, insira um e-mail válido')
      return
    }
  }

  const handleSubmit = async () => {
    verifyEmail()

    try {
      const exists = await mutateEmail(email)
      const sendSecurityCode = await mutateSendSecurityCode({
        email,
        securityCodeType: 'RecoveryPassword'
      })

      if (!exists || !sendSecurityCode) {
        setAlertOpen(true)
        return
      }

      handleSwitchToReset(email)
    } catch (error) {
      setAlertOpen(true)
    }
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  return (
    <Box height="100vh" mt={20}>
      <BoxContainer>
        <RecoveryTitle text="Recuperação de senha" handleClose={handleClose} />

        <DividerContainer />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            mt: 5
          }}
        >
          <Typography
            fontWeight={theme.designSystem.typography.fontWeight.regular}
            fontSize={theme.designSystem.typography.fontSize.body2}
            color={theme.designSystem.base[500]}
          >
            Para começar, digite seu e-mail:
          </Typography>

          <Box>
            <TextFieldInput
              role="textbox"
              variant="standard"
              label="E-mail"
              fullWidth
              type="email"
              value={email}
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
              error={!!error}
              helperText={error}
              slotProps={{
                htmlInput: { maxLength: 256 },
                formHelperText: { 'aria-label': 'email-error-message' }
              }}
            />
          </Box>

          <Typography
            fontWeight={theme.designSystem.typography.fontWeight.regular}
            fontSize={theme.designSystem.typography.fontSize.body2}
            color={theme.designSystem.base[500]}
          >
            Em caso de dúvidas, entre em contato com nosso atendimento:
          </Typography>

          <Typography
            fontSize={theme.designSystem.typography.fontSize.body2}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            sx={{ mb: 7.3 }}
          >
            nao-definido@genial.com.vc
          </Typography>
        </Box>

        <PasswordRecoveryButton
          onClick={handleSubmit}
          role="button"
          disabled={!email || !validateEmail(email) || loading}
        >
          {loading ? (
            <DotsLoader />
          ) : error || alertOpen ? (
            'Tentar novamente'.toUpperCase()
          ) : (
            'Continuar'.toUpperCase()
          )}
        </PasswordRecoveryButton>
      </BoxContainer>

      <AlertSnackbar
        type="error"
        open={alertOpen}
        onClose={handleAlertClose}
        title="Falha ao continuar"
        message="Tente novamente"
      />
    </Box>
  )
}
