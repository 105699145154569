import { StyledButton, StyledTypography } from 'pages/Home/styles'
import { useNavigate } from 'react-router-dom'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import IconArrowRight from 'shared/designSystem/Icons/IconArrowRight'
import IconArrowUp from 'shared/designSystem/Icons/IconArrowUp'
import { useTheme } from 'shared/hook/useTheme'
import { formatCurrencyManual } from 'shared/utils/formatCurrency'
import { ContainerContentPayment } from './style'

interface Props {
  totalValue: number
  comparisonValue: number
}

export const PaymentTitle = ({ totalValue, comparisonValue }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const noData = totalValue === 0 && comparisonValue === 0

  const handleViewMoreClick = () => {
    navigate('/finances')
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      paddingRight={3}
      paddingLeft={3}
    >
      <Box>
        <StyledTypography>Para pagar este mês</StyledTypography>

        <Box display="flex" alignItems="center">
          <Typography
            fontSize={theme.designSystem.typography.fontSize.h3}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            color={theme.designSystem.base[900]}
          >
            {formatCurrencyManual(totalValue)}
          </Typography>
        </Box>

        <ContainerContentPayment>
          <Box display="flex" alignItems="center">
            <Box mr={0.5}>{noData ? <IconArrowRight width={10} height={11} /> : <IconArrowUp />}</Box>
            <Typography
              fontSize={theme.designSystem.typography.fontSize.caption}
              fontWeight={theme.designSystem.typography.fontWeight.medium}
              color={noData ? theme.designSystem.base[400] : theme.designSystem.deepOrange[500]}
            >
              {formatCurrencyManual(comparisonValue)}
            </Typography>
          </Box>

          <Typography
            fontSize={theme.designSystem.typography.fontSize.caption}
            fontWeight={theme.designSystem.typography.fontWeight.regular}
            color={theme.designSystem.base[400]}
          >
            comparado ao mês passado
          </Typography>
        </ContainerContentPayment>
      </Box>

      <StyledButton variant="outlined" size="small" onClick={handleViewMoreClick}>
        Ver mais
      </StyledButton>
    </Box>
  )
}
