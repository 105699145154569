import { DarkTooltip } from './styles'

interface CustomTooltipProps {
  title: React.ReactNode
  children: React.ReactNode
}

export const InfoTooltip = ({ title, children }: CustomTooltipProps) => {
  return (
    <DarkTooltip title={title} arrow placement="top">
      {children}
    </DarkTooltip>
  )
}
