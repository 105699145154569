import { createTheme } from 'shared/designSystem/components/createTheme'
import { light } from './light'

export const theme = createTheme({
  typography: {
    fontFamily: light.typography.fontFamily
  },
  palette: {
    primary: {
      main: '#050922',
      contrastText: '#FFFFFF'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          border: `8px solid ${light.base[900]}`,
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
          height: '100vh',
          overflow: 'hidden'
        }
      }
    }
  },
  designSystem: light
})
