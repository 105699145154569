import useDataList from 'pages/Financial/hooks/useDataList'
import { Box } from 'shared/designSystem/components/Box'
import useFilters from 'shared/hook/useFilters'
import { formatCurrency } from 'shared/utils/formatCurrency'
import CardData from '../../../components/cardData'
import DrawerDetails from '../../../components/drawerDetails'
import ViewCardTable from '../../../components/viewCardTable'
import useDrawer from 'pages/Financial/hooks/useDrawer'
import { columnsGenialInvoice } from '../columns'
import { getColorsByStatus } from 'shared/utils/colorsStatus'

const TabGenialInvoice = ({ Month = 1 }: { Month?: number }) => {
  const { currentItem, isOpenedDrawer, onCloseDrawer, onOpenDrawerDetails } = useDrawer()

  const { filters } = useFilters('filtersAll')
  const isUnit = filters && filters.hasOwnProperty('unit')

  const { dataFiltered, loading } = useDataList(Month, 'FATURA_GENIAL')

  return (
    <Box>
      <ViewCardTable
        data={dataFiltered}
        columns={columnsGenialInvoice((row) => onOpenDrawerDetails(row))}
        loading={loading}
        cardComponent={(item) => (
          <CardData
            title={item?.unitName}
            subtitle={item?.group}
            tagText={item?.status}
            tagColor={getColorsByStatus(item?.status)}
            text={item?.payment}
            footerTextLeft={item.dueDate}
            footerTextRigth={(item.value && formatCurrency(item.value)) || ''}
            onClick={() => onOpenDrawerDetails(item)}
          />
        )}
      />

      <DrawerDetails
        isOpened={isOpenedDrawer}
        onClose={onCloseDrawer}
        id={currentItem?.id}
        title="Fatura Genial"
        subTitle={isUnit ? currentItem?.unitName : currentItem?.group}
      />
    </Box>
  )
}

export default TabGenialInvoice
