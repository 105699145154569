import { LegendContainer } from 'pages/Reports/styles'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { theme } from 'shared/theme'

interface Props {
  text: string
  variant: string
  color: string
  secondaryColor?: string
}

export const Legend = ({ text, variant, color, secondaryColor }: Props) => {
  const getBoxStyle = () => ({
    width: 12,
    height:
      variant === 'line-thin' ? `${theme.designSystem.size[1]}px` : variant === 'line-thick' ? 3 : 12,
    background:
      variant === 'line-thin'
        ? color
        : secondaryColor
          ? `linear-gradient(to right, ${color} 50%, ${secondaryColor} 50%)`
          : color
  })

  return (
    <LegendContainer>
      <Box
        sx={{
          ...getBoxStyle()
        }}
      />
      <Typography
        fontWeight={theme.designSystem.typography.fontWeight.medium}
        fontSize={theme.designSystem.typography.fontSize.caption}
        color={theme.designSystem.base[900]}
      >
        {text}
      </Typography>
    </LegendContainer>
  )
}
