import { useEffect, useState } from 'react'
import { Props, sizes } from '.'
import { theme } from 'shared/theme'

const useFlag = ({ type }: Props) => {
  const colorMap: Record<string, { bgColor: string; color: string }> = {
    green: { bgColor: theme.designSystem.green[700], color: theme.designSystem.white },
    yellow: { bgColor: theme.designSystem.attention[500], color: theme.designSystem.brown[500] },
    red2: { bgColor: theme.designSystem.deepOrange[900], color: theme.designSystem.white },
    red1: { bgColor: theme.designSystem.deepOrange[600], color: theme.designSystem.white }
  }

  const [size, setSize] = useState<sizes>(sizes.NORMAL)
  const [colors, setColors] = useState<{
    bgColor: string
    color: string
  }>({ ...colorMap[type] })

  useEffect(() => {
    const colors = colorMap[type]
    if (!colors) return

    setColors(colors)
  }, [type])

  const handleExpand = () => {
    setSize(sizes.LARGE)
  }

  const handleMinimize = () => {
    setSize(sizes.SMALL)
  }

  const handleResize = () => {
    setSize(sizes.NORMAL)
  }

  return { size, colors, handleExpand, handleResize, handleMinimize }
}

export default useFlag
