import { IconClose } from 'shared/designSystem/Icons/IconClose'
import useFilters from 'shared/hook/useFilters'
import { ReportDataFilters } from 'shared/services/Requests/Reports/types'
import {
  BoxMessage,
  BoxScreen,
  ErrorButton,
  ErrorCardContainer,
  ErrorCircle,
  TypographyBody,
  TypographyButton,
  TypographyMessage
} from './style'

interface Props {
  message: string
  buttonVariant?: 'outlined' | 'contained'
  onRetry: (dataFilters: ReportDataFilters) => void
}

export const HomeError = ({ message, onRetry }: Props) => {
  const { filters } = useFilters('filtersAll')

  return (
    <ErrorCardContainer>
      <BoxScreen>
        <ErrorCircle>
          <IconClose color="white" width={17} height={17} />
        </ErrorCircle>
        <TypographyBody>Falha ao carregar</TypographyBody>
        <BoxMessage>
          <TypographyMessage>{message}</TypographyMessage>
        </BoxMessage>
        <ErrorButton
          onClick={() =>
            onRetry({
              year: filters?.year,
              productId: filters?.product,
              groupId: filters?.group,
              unitId: filters?.unit
            })
          }
        >
          <TypographyButton>Tentar novamente</TypographyButton>
        </ErrorButton>
      </BoxScreen>
    </ErrorCardContainer>
  )
}
