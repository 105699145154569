import { useEffect, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { InputAdornment } from 'shared/designSystem/components/InputAdornment'
import { Menu } from 'shared/designSystem/components/Menu'
import { MenuItem } from 'shared/designSystem/components/MenuItem'
import { Stack } from 'shared/designSystem/components/Stack'
import { TextField } from 'shared/designSystem/components/TextField'
import { ArrowDown } from 'shared/designSystem/Icons/ArrowDown'
import { ArrowRight } from 'shared/designSystem/Icons/ArrowRight'
import { ArrowUp } from 'shared/designSystem/Icons/ArrowUp'
import useFilters from 'shared/hook/useFilters'
import useFiltersGlobal from 'shared/hook/useFiltersGlobal'
import type { TypePropsPageHeader } from 'shared/layout/Header/interface'
import { TypeComboGroup } from 'shared/models/Filters'
import { useReportStore } from 'shared/store/reports'
import { theme } from 'shared/theme'

const MultiLevelSelectProduct = ({ fetchData }: TypePropsPageHeader) => {
  const { filters, setBulkFilterData } = useFilters('filtersAll')
  const { globalProducts } = useFiltersGlobal()
  const {
    actions: { setDataReportsReset }
  } = useReportStore()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null)
  const [comboGroups, setComboGroups] = useState<TypeComboGroup[]>([])

  const isMenuOpen = Boolean(anchorEl)

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event?.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSubmenuAnchorEl(null)
  }

  const handleCloseSubmenu = () => {
    setSubmenuAnchorEl(null)
  }

  const handleProductClick = (product: number, event: any) => {
    setDataReportsReset()
    setBulkFilterData({ ...filters, product: product, group: '', unit: '' })
    setSubmenuAnchorEl(event?.currentTarget)
  }

  const handleGroupClick = (group: number | string, unit: number | string) => {
    const dataFilters = {
      year: filters?.year,
      productId: filters?.product,
      groupId: group,
      unitId: unit
    }

    setBulkFilterData({ ...filters, group: group, unit: unit })
    handleCloseMenu()

    fetchData(dataFilters)
  }

  const handleInitialParams = () => {
    if (filters?.product) {
      const datagroup = globalProducts?.find((item: any) => item.value == filters?.product)
      if (datagroup) setComboGroups(datagroup?.groups)
    }
  }

  const handleTitle = () => {
    if (filters?.unit) {
      const dataGroup = comboGroups.find((item: any) => item.value == filters?.group)
      const dataUnit = dataGroup?.units?.find((item: any) => item.value == filters?.unit)
      return dataUnit?.name
    }

    if (filters?.group) {
      const dataGroup = comboGroups.find((item: any) => item.value == filters?.group)
      return dataGroup?.name
    }
    if (filters?.product) {
      const dataProduct = globalProducts?.find((item: any) => item.value == filters?.product)
      return dataProduct?.name
    }

    return 'Selecione um produto'
  }

  useEffect(() => {
    handleInitialParams()
  }, [filters])

  return (
    <>
      <TextField
        size="small"
        onClick={handleOpenMenu}
        variant="outlined"
        value={handleTitle()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isMenuOpen ? (
                <ArrowUp sx={{ cursor: 'pointer' }} />
              ) : (
                <ArrowDown sx={{ cursor: 'pointer' }} />
              )}
            </InputAdornment>
          ),
          style: { fontWeight: 'bold', cursor: 'pointer' }
        }}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiMenu-paper': {
            width: anchorEl ? `${anchorEl.offsetWidth}px` : 'auto',
            height: 'auto',
            border: 'none',
            borderRadius: '0',
            marginBottom: '10px',
            borderTop: '1px solid #E0E0E0'
          }
        }}
      >
        {globalProducts?.map((item, index) => (
          <MenuItem
            key={index}
            sx={{
              fontWeight: filters?.product == item.value ? 'bold' : ''
            }}
            onClick={(event) => handleProductClick(item.value, event)}
          >
            <Stack
              display="flex"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <div>{item.name}</div>
              <div>{item?.groups?.length > 0 && <ArrowRight />}</div>
            </Stack>
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={submenuAnchorEl}
        open={Boolean(submenuAnchorEl)}
        onClose={handleCloseSubmenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{
          '& .MuiMenu-paper': {
            width: 'auto',
            maxHeight: '201px',
            border: 'none',
            marginBottom: '10px',
            borderTop: '1px solid #E0E0E0',
            borderRadius: 0,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          },
          '& .MuiList-root': {
            padding: 0
          }
        }}
      >
        <Box
          sx={{
            maxHeight: '200px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#05092229',
              borderRadius: '4px'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1'
            }
          }}
        >
          {comboGroups.map((itemSub, index) => (
            <Box key={itemSub.value}>
              <MenuItem
                onClick={() => handleGroupClick(itemSub.value, '')}
                sx={{
                  fontWeight:
                    index === 0
                      ? theme.designSystem.typography.fontWeight.regular
                      : theme.designSystem.typography.fontWeight.medium,
                  fontSize: theme.designSystem.size[14],
                  marginTop: `${theme.designSystem.size[10]}px`,
                  color: index === 0 ? '#05092299' : 'inherit',
                  '&:hover': {
                    backgroundColor: index === 0 ? '#ddd' : 'transparent'
                  }
                }}
              >
                {itemSub.name}
              </MenuItem>
              {itemSub.units.map((unit) => (
                <MenuItem
                  key={unit.value}
                  onClick={() => handleGroupClick(itemSub.value, unit.value)}
                  sx={{
                    fontSize: theme.designSystem.size[14],
                    color: theme.designSystem.base[500],
                    marginTrim: 'none',
                    marginLeft: `${theme.designSystem.size[8]}px`
                  }}
                >
                  {unit.name}
                </MenuItem>
              ))}
            </Box>
          ))}
        </Box>
      </Menu>
    </>
  )
}

export default MultiLevelSelectProduct
