import useFilters from 'shared/hook/useFilters'
import FiltersFinancial from '../components/filters'
import useTabsEnergy from '../useTabsEnergy'
import { ContainerFilters, ContainerFiltersRenders, ContainerTabs, ContentContainer } from './style'

const FinancialEnergy = () => {
  const { filters } = useFilters('filtersAll')
  const { renderTabs, renderTabsContent } = useTabsEnergy()

  return (
    <ContentContainer data-testid="content-container">
      <ContainerFiltersRenders>
        {!filters?.unit && (
          <ContainerFilters>
            <FiltersFinancial />
          </ContainerFilters>
        )}
        <ContainerTabs>
          {renderTabs}
          {renderTabsContent}
        </ContainerTabs>
      </ContainerFiltersRenders>
    </ContentContainer>
  )
}

export default FinancialEnergy
