import { IconButton } from 'shared/designSystem/components/IconButton'
import { Stack } from 'shared/designSystem/components/Stack'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconClose } from 'shared/designSystem/Icons/IconClose'
import { ChipStyle, DrawerContent, DrawerHeader, StyledDrawer } from './styles'

interface Props {
  isOpened: boolean
  onClose: VoidFunction
  children: React.ReactNode
  title?: string
  subTitle?: string
  width?: string
}

const DrawerStructure = ({ isOpened, onClose, children, width, title, subTitle }: Props) => {
  return (
    <StyledDrawer anchor="right" open={isOpened} onClose={onClose} width={width}>
      <DrawerHeader>
        <Stack flexDirection="row" alignItems="center" gap="20px">
          <IconButton onClick={onClose}>
            <IconClose />
          </IconButton>

          {title && <Typography variant="h5">{title}</Typography>}
        </Stack>

        {subTitle && <ChipStyle label={subTitle} variant="outlined" />}
      </DrawerHeader>

      <DrawerContent>{children}</DrawerContent>
    </StyledDrawer>
  )
}

export default DrawerStructure
