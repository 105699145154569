import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { Button, FilterDefaultContainer, ProductBadgeContainer, QuestionContainer } from './styles'
import { DotsLoader } from '../../../../shared/designSystem/components/DotsLoader'
import { AlertSnackbar } from 'shared/designSystem/components/AlertSnackbar'
import { TypeComboGroup, TypeComboUnit, TypeComboProduct } from 'shared/models/Filters'
import useFilterDefault from './useFilterDefault'

export interface Props {
  setFilterDefault: (arg: boolean) => void
  setDefaultYear?: (arg: boolean) => void
  isFinancial?: boolean
}
export const FilterDefault = ({ setFilterDefault, setDefaultYear, isFinancial }: Props) => {
  const {
    error,
    handleCloseError,
    globalProducts,
    filters,
    handleChange,
    comboGroups,
    comboUnits,
    handleSubmit,
    loading
  } = useFilterDefault({ setFilterDefault, setDefaultYear, isFinancial })

  const renderActions = () => {
    if (loading) return <DotsLoader />
    if (error.status) return 'Tentar novamente'.toUpperCase()
    return isFinancial ? 'Detalhamento financeiro' : 'Gerar relatórios'
  }
  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <AlertSnackbar
        type="error"
        open={error.status}
        onClose={handleCloseError}
        title="Falha ao gerar relatórios"
        message={error.message}
      />
      <FilterDefaultContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '80%',
            height: '80%',
            marginInline: 'auto'
          }}
        >
          <Box>
            <QuestionContainer>
              <Typography>Para começar, escolha um produto:</Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                {globalProducts?.map((element: TypeComboProduct) => (
                  <ProductBadgeContainer
                    key={element.value}
                    selected={filters?.product == element.value}
                    onClick={() => handleChange('product', element.value)}
                  >
                    {element.name}
                  </ProductBadgeContainer>
                ))}
              </Box>
            </QuestionContainer>

            {filters?.product && (
              <QuestionContainer>
                <Typography>Tem preferência por algum grupo?</Typography>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  {comboGroups?.map((element: TypeComboGroup) => (
                    <ProductBadgeContainer
                      key={element.value}
                      selected={filters?.group == element.value}
                      onClick={() => handleChange('group', element.value)}
                    >
                      {element.name}
                    </ProductBadgeContainer>
                  ))}
                </Box>
              </QuestionContainer>
            )}
            {filters?.group && filters?.group != 0 && (
              <QuestionContainer>
                <Typography>E a unidade consumidora?</Typography>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  {comboUnits.map((element: TypeComboUnit) => (
                    <ProductBadgeContainer
                      key={element.value}
                      selected={filters?.unit == element.value}
                      onClick={() => handleChange('unit', element.value)}
                    >
                      {element.name}
                    </ProductBadgeContainer>
                  ))}
                </Box>
              </QuestionContainer>
            )}
          </Box>
          <Button onClick={handleSubmit}>{renderActions()}</Button>
        </Box>
      </FilterDefaultContainer>
    </Box>
  )
}
