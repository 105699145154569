import { Grid } from 'shared/designSystem/components/Grid'
import { GridColDef } from 'shared/designSystem/components/GridColDef'
import { useFinancialStore } from 'shared/store/financial'
import LoadingContentAdvanced from 'shared/designSystem/components/LoadingContentAdvanced'
import { IfinancesItemList } from 'shared/services/Requests/finances/types'
import { StyledDataGrid } from './styles'

interface Props {
  columns: GridColDef[]
  data: IfinancesItemList[]
  cardComponent: (item: IfinancesItemList) => React.ReactNode
  loading?: boolean
}

const ViewCardTable = ({ columns, data, cardComponent, loading }: Props) => {
  const {
    state: { isTable }
  } = useFinancialStore()

  return (
    <LoadingContentAdvanced loading={loading} noData={data?.length === 0}>
      {isTable ? (
        <StyledDataGrid
          columns={columns}
          rows={data}
          disableColumnFilter
          hideFooterSelectedRowCount
          hideFooterPagination
          disableRowSelectionOnClick
          disableColumnMenu
          hideFooter
        />
      ) : (
        <Grid container spacing={2}>
          {data?.map((item) => <Grid size={{ xs: 12, sm: 6, md: 4 }}>{cardComponent(item)}</Grid>)}
        </Grid>
      )}
    </LoadingContentAdvanced>
  )
}

export default ViewCardTable
