import { Box } from 'shared/designSystem/components/Box'
import { Header } from './components/Header'
import { Table } from './components/Table'
import { PropsPage } from './interface'
import { Typography } from 'shared/designSystem/components/Typography'

export const PDFDocument = ({
  children,
  data,
  page,
  columns,
  numberOfPages,
  name,
  footerTable
}: PropsPage) => {
  return (
    <Box>
      <Header page={page || 1} numberOfPages={numberOfPages || 1} />
      <Typography margin={2} variant="h5" fontWeight="bold">
        {name}
      </Typography>

      {children}
      <Table footerTable={footerTable} data={data || []} columns={columns} />
    </Box>
  )
}
