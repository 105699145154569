import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Tooltip } from 'chart.js'
import { ConsumptionDataPageProps } from 'pages/Home/models/ConsumptionData'
import { Bar } from 'react-chartjs-2'
import { Box } from 'shared/designSystem/components/Box'
import { ErrorCard } from '../Error/ErrorCard/index'
import { CustomTooltip } from '../Tooltip'
import { EmptyBars } from './EmptyBars'
import { Header } from './Header'
import { Legend } from './Legend'
import { ConsumeGraphBox } from './styles'
import { useConsumeGraph } from './useConsumeGraph'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

const ConsumeGraph = ({ fetchDataConsumes }: ConsumptionDataPageProps) => {
  const { data, errorConsumes, hasData, options, tooltipData, hoveredIndex } = useConsumeGraph()

  return (
    <>
      {errorConsumes ? (
        <Box height={384} display="flex" justifyContent="center" bgcolor="white">
          <ErrorCard message="de consumo" onRetry={fetchDataConsumes} />
        </Box>
      ) : (
        <ConsumeGraphBox>
          <Header />
          {hasData ? (
            <>
              <Box display="flex" height="100%" alignItems="flex-end">
                <Bar data={data} options={options} height={120} />
              </Box>
              {tooltipData.visible && (
                <CustomTooltip
                  x={tooltipData.x}
                  y={tooltipData.y}
                  visible={tooltipData.visible}
                  datas={[
                    {
                      label: 'Consumo total',
                      value: `${data.datasets[0].data[hoveredIndex!]?.toLocaleString()} kWh`
                    }
                  ]}
                />
              )}
            </>
          ) : (
            <EmptyBars />
          )}
          {hasData && <Legend />}
        </ConsumeGraphBox>
      )}
    </>
  )
}

export default ConsumeGraph
