import { ChartOptions } from 'chart.js'
import { useMemo, useRef, useState } from 'react'
import { useConsumptionStore } from 'shared/store/consumption'
import { theme } from 'shared/theme'
import { getMonthFirstLetter } from 'shared/utils/dates'

export const useConsumeGraph = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const {
    state: { errorConsumes, filteredConsumes }
  } = useConsumptionStore()

  const [tooltipData, setTooltipData] = useState<{
    x: number
    y: number
    visible: boolean
    value?: number
  }>({ x: 0, y: 0, visible: false })

  const dataConsume = useMemo(() => {
    if (!filteredConsumes.length) return

    return filteredConsumes[0].consumes
  }, [filteredConsumes])

  const hasData = dataConsume

  const data = {
    labels: getMonthFirstLetter(),
    datasets: [
      {
        label: 'Consumo',
        data: dataConsume! || [],
        backgroundColor: theme.designSystem.primary[500],
        barThickness: 22,
        categoryPercentage: 1
      }
    ]
  }

  const prevTooltipData = useRef(tooltipData)
  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        external: (context: any) => {
          const { chart, tooltip } = context

          if (tooltip.opacity === 0) {
            if (tooltipData.visible) {
              setTooltipData((prev) => ({ ...prev, visible: false }))
            }
            return
          }

          const { offsetLeft, offsetTop } = chart.canvas

          if (
            prevTooltipData.current.x !== offsetLeft + tooltip.caretX ||
            prevTooltipData.current.y !== offsetTop + tooltip.caretY ||
            !tooltipData.visible
          ) {
            const newTooltipData = {
              x: offsetLeft + tooltip.caretX,
              y: offsetTop + tooltip.caretY,
              visible: true,
              value: tooltip.dataPoints[0].raw as number
            }
            prevTooltipData.current = newTooltipData
            setTooltipData(newTooltipData)
          }
        }
      },
      datalabels: {
        display: false
      }
    },
    onHover: (_, activeElements) => {
      const newIndex = activeElements.length ? activeElements[0].index : null
      setHoveredIndex(newIndex)
      if (!activeElements.length) {
        setTimeout(() => {
          setHoveredIndex(null)
        }, 50)
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false
        },
        ticks: {
          display: false
        },
        border: {
          display: false
        }
      }
    }
  }
  return { hasData, data, errorConsumes, options, tooltipData, hoveredIndex }
}
