import { useEffect, useMemo, useRef, useState } from 'react'
import useFilters from 'shared/hook/useFilters'
import usePdfElements from 'shared/hook/usePdfElements'
import { useApiAllReportsEnergy, useApiAllReportsSolar } from 'shared/services/Requests/Reports'
import { ReportDataFilters } from 'shared/services/Requests/Reports/types'
import { useReportStore } from 'shared/store/reports'
import { ConsumeModalDetailEnergy } from './components/Energy/ConsumeGraph/components/Modal'
import { ConsumeModalDetailSolar } from './components/Solar/ConsumeGraph/components/Modal'
import { EconomyModalDetailSolar } from './components/Solar/EconomyGraph/components/Modal'
import { EconomyModalDetailEnergy } from './components/Energy/EconomyGraph/components/Modal'
import { TotalModalDetailEnergy } from './components/Energy/TotalGraph/components/Modal'
import { EconomyAlertModalDetailEnergy } from './components/Energy/EconomyAlertGraph/components/Modal'
import { CO2ModalDetail } from './components/CO2Graph/components/Modal'
import { CreditBalanceModalDetail } from './components/Solar/CreditBalanceGraph/components/Modal'

const useReports = () => {
  const hasFetchedData = useRef(false)
  const reportPDFRef = useRef<any>(null)

  const pdfElements = usePdfElements()
  const { filters } = useFilters('filtersAll')
  const { mutateEnergy, loading: loadingEnergy } = useApiAllReportsEnergy()
  const { mutateSolar, loading: loadingSolar } = useApiAllReportsSolar()
  const {
    state: { graphic },
    actions: { setPdfRef }
  } = useReportStore()
  const loading = loadingEnergy || loadingSolar

  const [globalError, setGlobalError] = useState(false)
  const [filterDefault, setFilterDefault] = useState(false)
  const [defaultYear, setDefaultYear] = useState(false)

  const fetchData = async (dataFilters: ReportDataFilters) => {
    try {
      switch (Number(filters?.product)) {
        case 1:
          await mutateEnergy(dataFilters)
          break
        case 2:
          await mutateSolar(dataFilters)
          break
        default:
          break
      }

      setGlobalError(false)
    } catch (error: any) {
      setGlobalError(error.response?.status !== 400)
    }
  }

  const useModalComponents = useMemo(() => {
    const modalComponents = {
      0: null,
      1: filters?.product == 1 ? <ConsumeModalDetailEnergy /> : <ConsumeModalDetailSolar />,
      2: filters?.product == 1 ? <EconomyModalDetailEnergy /> : <EconomyModalDetailSolar />,
      3: <TotalModalDetailEnergy />,
      4: <EconomyAlertModalDetailEnergy />,
      5: <CO2ModalDetail />,
      6: <CreditBalanceModalDetail />
    }

    return modalComponents?.[graphic] || null
  }, [graphic, filters])

  useEffect(() => {
    setPdfRef(reportPDFRef)

    if (filters?.product && !hasFetchedData.current && !defaultYear) {
      setFilterDefault(false)
      hasFetchedData.current = true
      const dataFilters = {
        year: filters?.year,
        productId: filters?.product,
        groupId: filters?.group,
        unitId: filters?.unit
      }

      fetchData(dataFilters)
    } else if (!filters?.product) {
      setFilterDefault(true)
    }
  }, [filters])

  return {
    loading,
    fetchData,
    filterDefault,
    setFilterDefault,
    setDefaultYear,
    globalError,
    pdfElements,
    graphic,
    reportPDFRef,
    filters,
    useModalComponents
  }
}

export default useReports
