import { Chip } from 'shared/designSystem/components/Chip'
import { Drawer } from 'shared/designSystem/components/Drawer'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const DrawerHeader = styledSystem('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '14px 20px 14px 12px',
  borderBottom: '2px solid #0509220A',
  ...theme.mixins.toolbar,
  justifyContent: 'space-between'
}))

export const DrawerContent = styledSystem('div')(() => ({
  padding: '30px 25px'
}))

export const ChipStyle = styledSystem(Chip)(() => ({
  textTransform: 'uppercase',
  fontSize: '18px'
}))

export const StyledDrawer = styledSystem(Drawer)<{ width?: string }>(({ theme, width }) => ({
  '& .MuiDrawer-paper': {
    maxWidth: '1000px',
    boxSizing: 'border-box',
    width: width || '30%',
    [theme.breakpoints.down('sm')]: {
      width: '95%'
    }
  }
}))
