export type Idata = string | boolean | number | null | undefined

const isEmpty = (data: Idata) => {
  return data === null || data === undefined || data === ''
}

export const clearEmptyData = (data: { [x: string]: string }) => {
  for (const key in data) {
    if (isEmpty(data[key])) delete data[key]
  }

  return data
}

export const getParamsJson = (searchParams: URLSearchParams) => {
  return Object.fromEntries(searchParams.entries())
}
