import { create } from 'zustand'

type StateProps = {
  tab: number
  isTable: boolean
  viewHistoryTab: React.ReactNode
  selectGroup?: number | string
  selectUnit?: number | string
  selectGroups: number[]
  selectUnits: number[]
}

type ActionsProps = {
  selectTab: (tab: number) => void
  setSelectGroup: (selectGroup: number | string) => void
  setSelectUnit: (selectUnit: number | string) => void
  setSelectGroups: (selectGroups: number[]) => void
  setSelectUnits: (selectUnits: number[]) => void
  setIsTable: (isTable: boolean) => void
  setViewHistoryTab: (viewHistoryTab: React.ReactNode) => void
}

type StoreProps = {
  state: StateProps
  actions: ActionsProps
}

export const useFinancialStore = create<StoreProps>((set) => ({
  state: {
    tab: 0,
    selectGroups: [],
    selectUnits: [],
    selectGroup: undefined,
    selectUnit: undefined,
    isTable: false,
    viewHistoryTab: undefined
  },
  actions: {
    selectTab: (tab: number) => {
      set((store) => ({
        state: { ...store.state, tab }
      }))
    },
    setSelectGroup: (selectGroup: number | string) => {
      set((store) => ({
        state: { ...store.state, selectGroup }
      }))
    },
    setSelectUnit: (selectUnit: number | string) => {
      set((store) => ({
        state: { ...store.state, selectUnit }
      }))
    },
    setSelectGroups: (selectGroups: number[]) => {
      set((store) => ({
        state: { ...store.state, selectGroups }
      }))
    },
    setSelectUnits: (selectUnits: number[]) => {
      set((store) => ({
        state: { ...store.state, selectUnits }
      }))
    },
    setIsTable: (isTable: boolean) => {
      set((store) => ({
        state: { ...store.state, isTable }
      }))
    },
    setViewHistoryTab: (viewHistoryTab: React.ReactNode) => {
      set((store) => ({
        state: { ...store.state, viewHistoryTab }
      }))
    }
  }
}))
