import { IfinancesItemList, IfinancesResponseList } from 'shared/services/Requests/finances/types'

export const formatDataListFinances = (
  data: IfinancesResponseList | null,
  Month: number,
  yearF?: number
) => {
  if (!data) return []

  const newArray: IfinancesItemList[] = []
  const currentYear: number = yearF || new Date().getFullYear()
  const currentMonth = Month < 10 ? `0${Month}` : Month

  data.data?.map((item) => {
    item?.units?.map((unit) => {
      unit?.invoices?.map((invoice) => {
        if (invoice.invoiceMonthReference === `${currentYear}-${currentMonth}`) {
          newArray.push(invoice)
        }
      })
    })
  })

  return newArray
}
