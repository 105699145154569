export const cleanCpf = (cpf: string): string => cpf.replace(/\D/g, '')

export const formatCpf = (cpf: string): string => {
  const cleaned = cleanCpf(cpf).slice(0, 11)

  if (cleaned.length <= 3) return cleaned
  if (cleaned.length <= 6) return `${cleaned.slice(0, 3)}.${cleaned.slice(3)}`
  if (cleaned.length <= 9) return `${cleaned.slice(0, 3)}.${cleaned.slice(3, 6)}.${cleaned.slice(6)}`

  return `${cleaned.slice(0, 3)}.${cleaned.slice(3, 6)}.${cleaned.slice(6, 9)}-${cleaned.slice(9, 11)}`
}

export const isValidCpf = (cpf: string): boolean => {
  cpf = cpf.replace(/\D/g, '')

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return false
  }

  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf[i]) * (10 - i)
  }

  let firstDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (firstDigit !== parseInt(cpf[9])) {
    return false
  }

  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf[i]) * (11 - i)
  }

  let secondDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (secondDigit !== parseInt(cpf[10])) {
    return false
  }

  return true
}
