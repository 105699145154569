import { Box } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const ButtonActivation = styledSystem(Button)(({ theme }) => ({
  height: theme.designSystem.size[32],
  padding: theme.designSystem.size[16],
  gap: theme.designSystem.size[8],
  color: theme.designSystem.deepOrange[500],
  borderColor: theme.designSystem.deepOrange[500],
  border: `${theme.spacing(0.2)} solid ${theme.designSystem.deepOrange[500]}`,
  borderRadius: theme.designSystem.size[16]
}))

export const HeaderMiddleContent = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-start'
  }
}))
