import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Box } from 'shared/designSystem/components/Box'
import { Legend } from '../Legend'
import { GraphBox, GraphContainer } from 'pages/Reports/styles'
import { GraphPropsCO2 } from 'pages/Reports/types/GraphProps'
import { memo } from 'react'
import { CustomTooltip } from '../Tooltip'
import { HeaderReports } from '../Header'
import { EmptyData } from '../EmptyData'
import useCO2Graph from './useCO2Graph'

ChartJS.register(Filler, CategoryScale, LinearScale, LineElement, PointElement, Tooltip)

const CO2Graph = ({ variant, dataInfos, dataInfosHeader }: GraphPropsCO2) => {
  const {
    isModalVariant,
    data,
    options,
    verticalHoverFill,
    tooltipData,
    months,
    hoveredIndex,
    treePlanting,
    legends,
    estimatedTreePlanting,
    hasData,
    dataInfosHeaderEmpty
  } = useCO2Graph({ variant, dataInfos, dataInfosHeader })
  return (
    <GraphBox isModalVariant={isModalVariant}>
      {dataInfosHeader?.length && (
        <HeaderReports
          graphic={5}
          graphicName="Sustentabilidade"
          infos={hasData ? dataInfosHeader : dataInfosHeaderEmpty}
        />
      )}

      {hasData ? (
        <>
          <GraphContainer isModalVariant={isModalVariant}>
            <Line data={data} options={options} plugins={[verticalHoverFill]} />
            {tooltipData.visible && (
              <CustomTooltip
                x={tooltipData!.x}
                y={tooltipData!.y}
                visible={tooltipData.visible}
                datas={[
                  {
                    label: `Plantio de árvores em ${months[hoveredIndex!]}`,
                    value: treePlanting[hoveredIndex!]
                  },
                  {
                    label: `Plantio de árvores até ${months[hoveredIndex!]}`,
                    value: estimatedTreePlanting[hoveredIndex!]
                  }
                ]}
              />
            )}
          </GraphContainer>

          <Box display="flex" flexWrap="wrap" gap={1} width="90%" sx={{ mt: 2 }}>
            {legends.map((item, index) => (
              <Legend key={index} color={item?.color} text={item?.text} variant={item?.variant} />
            ))}
          </Box>
        </>
      ) : (
        <EmptyData text="Não há dados disponíveis para a visualização do gráfico." />
      )}
    </GraphBox>
  )
}

export default memo(CO2Graph)
