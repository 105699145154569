import { StatusTag } from './styles'

export interface TagProps {
  label: string
  colorTag?: string
}

const Tag = ({ label, colorTag }: TagProps) => {
  return <StatusTag label={label} colorTag={colorTag} />
}

export default Tag
