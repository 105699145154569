import { useEffect, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import useFilters from 'shared/hook/useFilters'
import { ReportDataFilters } from 'shared/services/Requests/Reports/types'
import MultiLevelSelectProduct from './components/MultiLevelSelectProduct'
import MultiLevelSelectYear from './components/MultiLevelSelectYear'

export const ContainerFiltersModalDetails = ({ fetchData }: any) => {
  const { filters } = useFilters('filtersAll')
  const [filtersModal, setFiltersModal] = useState(filters)

  const handleFetchData = (filters: ReportDataFilters) => {
    fetchData(filters)
  }

  useEffect(() => {
    setFiltersModal({
      year: filters?.year,
      product: filters?.product,
      group: filters?.group,
      unit: filters?.unit
    })
  }, [filters])

  return (
    <Box display="flex" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        sx={{
          border: '1px solid #E6E6E9',
          marginRight: 2,
          boxShadow: '0px 4px 10px rgba(5, 9, 34, 0.1)'
        }}
      >
        <MultiLevelSelectProduct
          handleFetchData={handleFetchData}
          filtersModal={filtersModal}
          setFiltersModal={setFiltersModal}
        />
      </Box>
      <Box
        sx={{
          border: '1px solid #E6E6E9',
          boxShadow: '0px 4px 10px rgba(5, 9, 34, 0.1)'
        }}
      >
        <MultiLevelSelectYear
          handleFetchData={handleFetchData}
          filtersModal={filtersModal}
          setFiltersModal={setFiltersModal}
        />
      </Box>
    </Box>
  )
}
