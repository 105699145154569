import { SelectChangeEvent } from '@mui/material/Select'
import React from 'react'
import { CustomSelect } from './styles'

type Props = {
  size?: string
  value?: string | null
  open?: boolean
  onClose?: () => void
  onOpen?: () => void
  onChange?: (event: SelectChangeEvent<any>, child: React.ReactNode) => void
  onClick?: () => void
  children: React.ReactNode
  renderValue?: (value: any) => React.ReactNode
  disabled?: any
  IconComponent?: React.ElementType
  sx?: any
  variant?: 'standard' | 'outlined' | 'filled'
  displayEmpty?: boolean
}

export const Select = ({
  value,
  onChange,
  children,
  renderValue,
  open,
  onClick,
  onClose,
  disabled,
  IconComponent,
  sx,
  variant = 'outlined',
  displayEmpty
}: Props) => {
  return (
    <CustomSelect
      disabled={disabled}
      displayEmpty={displayEmpty}
      open={open}
      renderValue={renderValue}
      value={value}
      variant={variant}
      onChange={onChange}
      onClick={onClick}
      onBlur={onClose}
      IconComponent={IconComponent}
      sx={sx}
    >
      {children}
    </CustomSelect>
  )
}
