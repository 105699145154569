import { GridColDef } from '@mui/x-data-grid'
import ButtonDetails from 'pages/Financial/components/buttonDetails'
import Tag from 'shared/designSystem/components/Tag'
import { IfinancesItemList } from 'shared/services/Requests/finances/types'
import { getColorsByStatus } from 'shared/utils/colorsStatus'
import { formatDateShort } from 'shared/utils/dates'
import { formatCurrency } from 'shared/utils/formatCurrency'

export const columnsCCEE = (action: (row: IfinancesItemList) => void): GridColDef[] => [
  {
    field: 'obligation',
    headerName: 'Obrigação',
    flex: 1
  },
  {
    field: 'value',
    headerName: 'Valor',
    flex: 1,
    renderCell: ({ row }) => (row.value ? formatCurrency(row.value) : '-')
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    flex: 1,
    renderCell: ({ row }) => (row.dueDate ? formatDateShort(row.dueDate) : '-')
  },
  {
    field: 'payment',
    headerName: 'Pagamento',
    flex: 1,
    renderCell: () => 'CCEE'
  },
  {
    field: 'responsible',
    headerName: 'Responsável',
    flex: 1,
    renderCell: () => <Tag label="CCEE" colorTag="#5D65F7" />
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    align: 'right',
    renderCell: ({ row }) => <ButtonDetails onClick={() => action(row)} />
  }
]

export const columnsDistribuctor = (action: (row: IfinancesItemList) => void): GridColDef[] => [
  {
    field: 'unitName',
    headerName: 'Unidade consumidora',
    flex: 1
  },
  {
    field: 'value',
    headerName: 'Valor',
    flex: 1,
    renderCell: ({ row }) => (row.value ? formatCurrency(row.value) : '-')
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    flex: 1,
    renderCell: ({ row }) => (row.dueDate ? formatDateShort(row.dueDate) : '-')
  },
  {
    field: 'responsible',
    headerName: 'Responsável',
    flex: 1,
    renderCell: ({ row }) => <Tag label={row.responsible} colorTag="#5D65F7" />
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    align: 'right',
    renderCell: ({ row }) => <ButtonDetails onClick={() => action(row)} />
  }
]

export const columnsEnergyNote = (action: (row: IfinancesItemList) => void): GridColDef[] => [
  {
    field: 'unitName',
    headerName: 'Unidade Consumidora',
    flex: 1
  },
  {
    field: 'value',
    headerName: 'Valor',
    flex: 1,
    renderCell: ({ row }) => (row.value ? formatCurrency(row.value) : '-')
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    flex: 1,
    renderCell: ({ row }) => (row.dueDate ? formatDateShort(row.dueDate) : '-')
  },
  {
    field: 'payment',
    headerName: 'Pagamento',
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row }) => <Tag label={row.status} colorTag={getColorsByStatus(row?.status)} />
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    align: 'right',
    renderCell: ({ row }) => <ButtonDetails onClick={() => action(row)} />
  }
]

export const columnsGenialInvoice = (action: (row: IfinancesItemList) => void): GridColDef[] => [
  {
    field: 'unitName',
    headerName: 'Unidade Consumidora',
    flex: 1
  },
  {
    field: 'value',
    headerName: 'Valor',
    flex: 1,
    renderCell: ({ row }) => (row.value ? formatCurrency(row.value) : '-')
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    flex: 1,
    renderCell: ({ row }) => (row.dueDate ? formatDateShort(row.dueDate) : '-')
  },
  {
    field: 'payment',
    headerName: 'Pagamento',
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row }) => <Tag label={row.status} colorTag={getColorsByStatus(row?.status)} />
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    align: 'right',
    renderCell: ({ row }) => <ButtonDetails onClick={() => action(row)} />
  }
]

export const columnsManagementNote = (action: (row: IfinancesItemList) => void): GridColDef[] => [
  {
    field: 'unitName',
    headerName: 'Unidade Consumidora',
    flex: 1
  },
  {
    field: 'group',
    headerName: 'Unidade Consumidora',
    flex: 1
  },
  {
    field: 'value',
    headerName: 'Valor',
    flex: 1,
    renderCell: ({ row }) => (row.value ? formatCurrency(row.value) : '-')
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    flex: 1,
    renderCell: ({ row }) => (row.dueDate ? formatDateShort(row.dueDate) : '-')
  },
  {
    field: 'payment',
    headerName: 'Pagamento',
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row }) => <Tag label={row.status} colorTag={getColorsByStatus(row?.status)} />
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    align: 'right',
    renderCell: ({ row }) => <ButtonDetails onClick={() => action(row)} />
  }
]

export const columnsObligations = (action: (row: IfinancesItemList) => void): GridColDef[] => [
  {
    field: 'obligation',
    headerName: 'Obrigação',
    flex: 1
  },
  {
    field: 'value',
    headerName: 'Valor',
    flex: 1,
    renderCell: ({ row }) => (row.value ? formatCurrency(row.value) : '-')
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    flex: 1
  },
  {
    field: 'payment',
    headerName: 'Pagamento',
    flex: 1
  },
  {
    field: 'responsible',
    headerName: 'Responsável',
    flex: 1,
    renderCell: ({ row }) => (
      <Tag label={row.responsible} colorTag={getColorsByStatus(row.responsible)} />
    )
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    align: 'right',
    renderCell: ({ row }) => <ButtonDetails onClick={() => action(row)} />
  }
]
