import { useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconArrowFullDown } from 'shared/designSystem/Icons/IconArrowFullDown'
import IconArrowUp from 'shared/designSystem/Icons/IconArrowUp'
import { theme } from 'shared/theme'
import { formatCurrency } from 'shared/utils/formatCurrency'
import { PeriodBadgeContainer, PeriodContainer } from '../style'
import useDataGraphTotals from 'pages/Financial/hooks/useDataGraphTotals'

export const Header = () => {
  const [selection, setSelection] = useState(0)
  const { data } = useDataGraphTotals()

  const totalGroupCurrentPeriod = data?.data?.totalGroupCurrentPeriod || 0
  const totalGroupPreviousPeriod = data?.data?.totalGroupPreviousPeriod || 0

  const isValueGreaterThanDiff = totalGroupCurrentPeriod > totalGroupPreviousPeriod

  const color = isValueGreaterThanDiff ? theme.designSystem.success : theme.designSystem.deepOrange[900]

  const valueFormatted = formatCurrency(totalGroupCurrentPeriod)
  const diffFormatted = formatCurrency(totalGroupPreviousPeriod)

  const periodOpions = [
    {
      label: 2024,
      value: 2024
    },
    {
      label: 'Últimos 12 meses',
      value: 0
    }
  ]

  const handleSelect = (value: number) => {
    setSelection(value)
  }

  return (
    <Box
      sx={{
        mb: '8px'
      }}
    >
      <PeriodContainer>
        {periodOpions.map(({ label, value }) => (
          <PeriodBadgeContainer
            sx={{ mt: '24px' }}
            key={value}
            selected={selection === value}
            onClick={() => handleSelect(value)}
          >
            {label}
          </PeriodBadgeContainer>
        ))}
      </PeriodContainer>
      <Typography
        fontSize={theme.designSystem.typography.fontSize.button}
        fontWeight={theme.designSystem.typography.fontWeight.medium}
        color="#05092261"
        mb="8px"
      >
        Valor total dos grupos no ano
      </Typography>
      <Typography
        fontSize={theme.designSystem.typography.fontSize.h3}
        fontWeight={theme.designSystem.typography.fontWeight.medium}
        mb="10px"
      >
        {valueFormatted}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: '10px' }}>
        {isValueGreaterThanDiff ? <IconArrowUp color={color} /> : <IconArrowFullDown color={color} />}

        <Typography
          fontSize={theme.designSystem.typography.fontSize.caption}
          fontWeight={theme.designSystem.typography.fontWeight.regular}
          color={color}
          sx={{
            display: 'inline',
            ml: '4px',
            mr: '16px'
          }}
        >
          {diffFormatted}
        </Typography>
        <Typography
          fontSize={theme.designSystem.typography.fontSize.caption}
          fontWeight={theme.designSystem.typography.fontWeight.regular}
          color="#05092261"
          sx={{
            display: 'inline'
          }}
        >
          comparado ao mesmo período do ano anterior
        </Typography>
      </Box>
    </Box>
  )
}
