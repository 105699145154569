import { useMemo, useState } from 'react'
import { Tab } from 'shared/designSystem/components/Tab'
import { Tabs } from 'shared/designSystem/components/Tabs'
import { a11yProps } from 'shared/utils/financialTab'

const useTabData = () => {
  const [tabItem, setTabItem] = useState(0)

  const TABS = [
    { label: 'Grupos', value: 0 },
    { label: 'Consolidado', value: 1 }
  ]

  const handleSelectTab = (_: React.SyntheticEvent, value: number) => {
    setTabItem(value)
  }

  const renderTabs = useMemo(
    () => (
      <Tabs
        sx={{
          borderBottom: '1px solid #05092261',
          marginBottom: '20px'
        }}
        value={tabItem}
        onChange={handleSelectTab}
        aria-label="table financial"
      >
        {TABS.map((item) => (
          <Tab label={item.label} {...a11yProps(item.value)} key={item.value} />
        ))}
      </Tabs>
    ),
    [tabItem]
  )

  return { renderTabs, tabItem }
}

export default useTabData
