import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'
import { theme } from 'shared/theme'
import { sizes } from '.'

interface Props {
  backgroundColor?: string
  isExpanded?: sizes
}

export const FlagContainer = styledSystem(Box, {
  shouldForwardProp: (prop) => prop !== 'isExpanded'
})<Props>(({ backgroundColor, isExpanded }) => ({
  position: 'fixed',
  bottom: theme.designSystem.size[18],
  right: theme.designSystem.size[22],
  backgroundColor: backgroundColor || theme.designSystem.deepOrange[900],
  borderRadius: theme.designSystem.size[4],
  transition: 'height 0.3s ease, width 0.3s ease',
  width:
    isExpanded === sizes.LARGE
      ? theme.designSystem.size[369]
      : isExpanded === sizes.NORMAL
        ? theme.designSystem.size[255]
        : 'fit-content',
  height: isExpanded === sizes.LARGE ? theme.designSystem.size[271] : theme.designSystem.size[48],
  display: 'flex',
  alignItems: 'center',
  padding:
    isExpanded === sizes.LARGE
      ? theme.designSystem.size[24]
      : isExpanded === sizes.NORMAL
        ? theme.designSystem.size[8]
        : theme.designSystem.size[8]
}))
