import React, { useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { AdornmentInput } from '../../AdornmentInputBox'
import { Button, Description, FormboxContainer, MainBox, Title, TypographyBox } from './styles'

interface PasswordBoxProps {
  role?: string
  onPasswordChange: (phone: string) => void
}

export const PasswordBox = ({ role, onPasswordChange }: PasswordBoxProps) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState({ password: '', confirmPassword: '' })

  const validatePassword = (value: string) => {
    const hasLetter = /[a-zA-Z]/.test(value)
    const hasNumber = /[0-9]/.test(value)
    const hasSpecialChar = /[!@#$%^&*]/.test(value)

    if (!value) return 'Este campo é obrigatório'

    if (value.length < 8) return 'o número mínimo de caracteres é 8'

    if (!hasLetter || !hasNumber || !hasSpecialChar)
      return 'A senha deve ter pelo menos 1 letra, 1 número e 1 caractere especial'

    return ''
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    const passwordError = validatePassword(e.target.value)
    setErrors((prev) => ({ ...prev, password: passwordError }))
  }

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setConfirmPassword(value)
    const confirmPasswordError = value !== password ? 'As senhas devem ser iguais' : ''
    setErrors((prev) => ({ ...prev, confirmPassword: confirmPasswordError }))
  }

  const handleSubmit = () => {
    const passwordError = validatePassword(password)
    const confirmPasswordError = confirmPassword !== password ? 'As senhas devem ser iguais' : ''
    setErrors({ password: passwordError, confirmPassword: confirmPasswordError })

    if (!passwordError && !confirmPasswordError) {
      onPasswordChange(password)
    }
  }

  return (
    <MainBox role={role}>
      <FormboxContainer role="form-box">
        <TypographyBox role="typography-box">
          <Title>Definição de senha</Title>
          <Description>
            Para finalizar, você precisa informar uma senha para que o acesso com CPF seja habilitado.
          </Description>

          <Box role="input-boxes">
            <AdornmentInput
              inputTitle="Senha"
              value={password}
              error={!!errors.password}
              helperText={errors.password}
              onChange={handlePasswordChange}
              showIconButton={true}
            />
            <AdornmentInput
              inputTitle="Confirme a senha"
              value={confirmPassword}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              onChange={handleConfirmPasswordChange}
              showIconButton={true}
            />
          </Box>
        </TypographyBox>
        <Button role="button" onClick={handleSubmit}>
          Confirmar
        </Button>
      </FormboxContainer>
    </MainBox>
  )
}
