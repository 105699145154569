import { buildQueryString as bqs } from 'shared/utils/queryString'

const endpoints = {
  login: {
    getLogin: () => `/login`,
    getCode: () => `/token/validar`
  },
  passwordRecovery: {
    getEmail: (email: string) => `/user/get-user-by-email?Email=${email}`,
    sendSecurityCode: () => `/security-code/email`,
    resetPassword: () => `/user/password-recovery`
  },
  home: {
    getConsumes: (q: object) => `/consumption${bqs(q)}`,
    getEconomy: (q: object) => `/economy${bqs(q)}`,
    getPayments: (q: object) => `/invoice/get-invoices-by-user-id${bqs(q)}`
  },
  finances: {
    listAll: (q: object) => `/Invoice/get-invoices-by-consumer-group-id${bqs(q)}`,
    details: (q: object) => `/Invoice/get-invoice-detail-by-group-consumer-unit-id${bqs(q)}`,
    graphDataUnit: (q: object) => `Invoice/get-invoice-by-group-consumer-unit-id${bqs(q)}`,
    graphDataGroup: (q: object) => `/Invoice/get-invoice-history-aggregate${bqs(q)}`,
    graphTotals: (q: object) => `/Invoice/get-invoice-history-totals${bqs(q)}`
  },
  reports: {
    economyReport: (q: object) => `/economy-report${bqs(q)}`,
    averageConsumptionReport: (q: object) => `/average-consumption-report${bqs(q)}`,
    economyAlertreport: (q: object) => `/economy-alert-report${bqs(q)}`,
    sustainabilityReport: (q: object) => `/sustainability-report${bqs(q)}`,
    flag: (q: object) => `/flag${bqs(q)}`,
    totalCosts: (q: object) => `/total-costs${bqs(q)}`,
    creditBalanceReport: (q: object) => `/credit-balance-report${bqs(q)}`
  },
  filters: {
    filterGroups: (q: object) => `/filter-groups${bqs(q)}`
  }
}

export default endpoints
