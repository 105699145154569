import { Box } from 'shared/designSystem/components/Box'
import { InputAdornment } from 'shared/designSystem/components/InputAdornment'
import { List } from 'shared/designSystem/components/List'
import { IconSearch } from 'shared/designSystem/Icons/IconSearch'
import { FinancialTextField, SideContainer } from './styles'
import useFiltersFinancial from './useFiltersFinancial'

const FiltersFinancial = () => {
  const { handleList, handleListCheck, setFilterList, filterList, tab } = useFiltersFinancial()

  return (
    <Box>
      <FinancialTextField
        type="search"
        value={filterList}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterList(event.target.value)}
        placeholder="Buscar unidade consumidora"
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <IconSearch />
              </InputAdornment>
            )
          }
        }}
        variant="standard"
      />
      <SideContainer>
        <List>{tab === 5 ? handleListCheck : handleList}</List>
      </SideContainer>
    </Box>
  )
}

export default FiltersFinancial
