import { useEffect, useMemo, useRef, useState } from 'react'
import { columnsEconomyGraphEnergy } from 'shared/mocks/columnsPdf'
import EconomyGraph from '../..'
import { ReportDataEconomy } from 'shared/services/Requests/Reports/types'
import { useApiEconomy } from 'shared/services/Requests/Reports'
import useFilters from 'shared/hook/useFilters'
import { useReportStore } from 'shared/store/reports'

const useModalEconomyEnergy = () => {
  const { filters } = useFilters('filtersAll')
  const { mutateEconomy, apiEconomy, loading } = useApiEconomy()
  const {
    state: { graphic }
  } = useReportStore()

  const [stateDataEconomy, setStateDataEconomy] = useState<ReportDataEconomy | null>(apiEconomy || null)

  const pdfRef = useRef(null)

  const infosHeaderReport = [
    {
      titleValue: 'Custo médio cativo',
      value: stateDataEconomy?.dataGraphic?.averageCaptiveCost || 'R$ 0,00',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    },
    {
      titleValue: 'Custo médio livre',
      value: stateDataEconomy?.dataGraphic?.averageFreeCost || 'R$ 0,00',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    },
    {
      titleValue: 'Economia acumulada no ano',
      value: stateDataEconomy?.dataGraphic?.savingsPerYear || 'R$ 0,00',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    }
  ]

  const pdfElements = useMemo(
    () => [
      {
        graph: (
          <EconomyGraph
            variant="modal"
            dataInfos={stateDataEconomy}
            dataInfosHeader={infosHeaderReport}
          />
        ),
        data: stateDataEconomy?.dataTable,
        columns: columnsEconomyGraphEnergy,
        footerTable: stateDataEconomy?.tableTotals
      }
    ],
    [stateDataEconomy]
  )

  useEffect(() => {
    if (graphic === 2 && filters?.year)
      mutateEconomy({
        year: filters?.year,
        productId: filters?.product,
        groupId: filters?.group,
        unitId: filters?.unit
      })
  }, [graphic, filters])

  useEffect(() => {
    setStateDataEconomy(apiEconomy)
  }, [apiEconomy])

  return {
    loading,
    mutateEconomy,
    pdfRef,
    pdfElements,
    stateDataEconomy,
    infosHeaderReport
  }
}

export default useModalEconomyEnergy
