import CO2Graph from 'pages/Reports/components/CO2Graph'
import ConsumeGraphEnergy from 'pages/Reports/components/Energy/ConsumeGraph'
import EconomyAlertGraphEnergy from 'pages/Reports/components/Energy/EconomyAlertGraph'
import EconomyGraphEnergy from 'pages/Reports/components/Energy/EconomyGraph'
import TotalGraphEnergy from 'pages/Reports/components/Energy/TotalGraph'
import ConsumeGraphSolar from 'pages/Reports/components/Solar/ConsumeGraph'
import EconomyGraphSolar from 'pages/Reports/components/Solar/EconomyGraph'
import CreditBalanceGraphSolar from 'pages/Reports/components/Solar/CreditBalanceGraph'
import { InfosHeaderType } from 'pages/Reports/types/GraphProps'
import { useEffect, useState } from 'react'
import {
  columnsCO2Graph,
  columnsConsumeGraphEnergy,
  columnsConsumeGraphSolar,
  columnsCreditBalance,
  columnsEconomyAlertGraph,
  columnsEconomyGraphEnergy,
  columnsEconomyGraphSolar,
  columnsTotalGraph
} from 'shared/mocks/columnsPdf'
import { useReportStore } from 'shared/store/reports'
import useFilters from '../useFilters'

export interface PdfElement {
  id: number
  name: string
  product: number[]
  Graph: React.ComponentType<any>
  data: any
  columns: any
  infosHeader?: InfosHeaderType[]
}

const usePdfElements = () => {
  const { filters } = useFilters('filtersAll')
  const {
    state: { dataEconomy, dataConsumer, dataEconomyAlert, dataCO2, dataTotal, dataCreditBalance }
  } = useReportStore()
  const [pdfElements, setPdfElements] = useState<PdfElement[]>([])

  const infosHeaderConsume = [
    {
      titleValue: 'Consumo médio no ano',
      value:
        (filters?.product == 1
          ? dataConsumer?.dataGraphic?.averageConsumptionPerYear
          : dataConsumer?.dataGraphic?.averageEnergyConsumedYear) || '0KWh',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    }
  ]

  const infosHeaderEconomy = [
    {
      titleValue: `Economia ${filters?.product == 1 ? 'acumulada' : 'média'} no ano`,
      value:
        (filters?.product == 1
          ? dataEconomy?.dataGraphic?.savingsPerYear
          : dataEconomy?.dataGraphic?.averageEconomyYear) || 'R$ 0,00',
      tooltipInfos:
        filters?.product == 1
          ? 'Atualizado conforme medições da distribuidora para todas as unidades'
          : 'A economia média no ano é calculada na diferença do custo tradicional com o custo genial em cada mês'
    }
  ]

  const infosHeaderTotal = [
    {
      titleValue: 'Custo total no ano',
      value: dataTotal?.dataGraphic?.costPerYear || 'R$ 0,00',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    }
  ]

  const infosHeaderCO2 = [
    {
      titleValue: 'CO₂ poupado no ano',
      value: dataCO2?.dataGraphic?.treeEquivalence
        ? `${dataCO2?.dataGraphic?.treeEquivalence} Kg`
        : '0 Kg',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    },
    {
      titleValue: 'Equivalência em árvores',
      value: dataCO2?.dataGraphic?.treeEquivalence || '0',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    }
  ]

  const infosHeaderCreditBalance = [
    {
      titleValue: 'Média de utilização no ano',
      value: dataCreditBalance?.dataGraphic?.averageUsePerYear || '0%',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    }
  ]

  useEffect(() => {
    setPdfElements([
      {
        id: 1,
        name: 'Relatório de Consumo',
        product: [1, 2],
        Graph: filters?.product == 1 ? ConsumeGraphEnergy : ConsumeGraphSolar,
        data: dataConsumer,
        columns: filters?.product == 1 ? columnsConsumeGraphEnergy : columnsConsumeGraphSolar,
        infosHeader: infosHeaderConsume
      },
      {
        id: 2,
        name: 'Relatório de Economia',
        product: [1, 2],
        Graph: filters?.product == 1 ? EconomyGraphEnergy : EconomyGraphSolar,
        data: dataEconomy,
        columns: filters?.product == 1 ? columnsEconomyGraphEnergy : columnsEconomyGraphSolar,
        infosHeader: infosHeaderEconomy
      },
      {
        id: 6,
        name: 'Relatório de Balanço de Créditos',
        product: [2],
        Graph: CreditBalanceGraphSolar,
        data: dataCreditBalance,
        columns: columnsCreditBalance,
        infosHeader: infosHeaderCreditBalance
      },
      {
        id: 3,
        name: 'Relatório de Centro de Custos',
        product: [1],
        Graph: TotalGraphEnergy,
        data: dataTotal,
        columns: columnsTotalGraph,
        infosHeader: infosHeaderTotal
      },
      {
        id: 4,
        name: '',
        product: [1],
        Graph: EconomyAlertGraphEnergy,
        data: dataEconomyAlert,
        columns: columnsEconomyAlertGraph,
        infosHeader: []
      },
      {
        id: 5,
        name: 'Relatório de Sustentabilidade',
        product: [1, 2],
        Graph: CO2Graph,
        data: dataCO2,
        columns: columnsCO2Graph,
        infosHeader: infosHeaderCO2
      }
    ])
  }, [dataConsumer, dataEconomy, dataEconomyAlert, dataCO2, dataTotal, dataCreditBalance, filters])

  return pdfElements
}

export default usePdfElements
