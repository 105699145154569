import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { Grid } from 'shared/designSystem/components/Grid'
import { ArrowUpwardIcon } from 'shared/designSystem/components/Icons/ArrowUpwardIcon'
import LoadingContentAdvanced from 'shared/designSystem/components/LoadingContentAdvanced'
import { Tab } from 'shared/designSystem/components/Tab'
import { Tabs } from 'shared/designSystem/components/Tabs'
import Tag from 'shared/designSystem/components/Tag'
import { Tooltip } from 'shared/designSystem/components/Tooltip'
import { useFinancesDetails } from 'shared/services/Requests/finances'
import { formatCurrency } from 'shared/utils/formatCurrency'
import { formatDateShort } from 'shared/utils/dates'
import { BigValueText, CodeBarText, LabelText, StyledBarcode, ValueText } from './style'
import useColumns from './useColumns'
import DrawerStructure from '../drawerStructure'
import { StyledDataGrid } from '../viewCardTable/styles'

interface DrawerDetailsProps {
  isOpened: boolean
  onClose: VoidFunction
  id: any
  title: string
  subTitle?: string
}

const DrawerDetails = ({ isOpened, onClose, id, title, subTitle = '' }: DrawerDetailsProps) => {
  const [openedTooltip, setOpenedTooltip] = useState(false)
  const [value, setValue] = useState(0)
  const { columnsDetails, CustomTabPanel, a11yProps } = useColumns()
  const { mutate, data, loading } = useFinancesDetails()

  useEffect(() => {
    if (isOpened) mutate(id)
  }, [isOpened])

  const dataBillingDetails = data?.dueDate ? formatDateShort(data.dueDate) : ''

  return (
    <DrawerStructure
      isOpened={isOpened}
      onClose={onClose}
      title={title}
      width="60%"
      subTitle={loading ? '' : subTitle}
    >
      <LoadingContentAdvanced loading={loading} noData={!data}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <LabelText variant="body1">Custo mensal</LabelText>
            <BigValueText variant="h2">{data?.value && formatCurrency(data.value)}</BigValueText>
          </Grid>

          {data?.valueLastMonth && (
            <Grid size={{ xs: 12, md: 3 }}>
              <LabelText variant="body1">Comparado ao mês passado</LabelText>
              <ValueText variant="body1" color="#FF5722">
                <ArrowUpwardIcon /> {data?.valueLastMonth && formatCurrency(data.valueLastMonth)}
              </ValueText>
            </Grid>
          )}

          <Grid size={{ xs: 12, md: 3 }}>
            <LabelText variant="body1">Pagamento</LabelText>
            <ValueText variant="body1">{data?.payment || '-'}</ValueText>
          </Grid>

          <Grid size={{ xs: 12, md: 3 }} mb={4}>
            <LabelText variant="body1">Vencimento</LabelText>
            <ValueText variant="body1">{dataBillingDetails}</ValueText>
          </Grid>

          <Grid size={{ xs: 12, md: 3 }} mb={4}>
            <LabelText variant="body1">Status</LabelText>
            <ValueText variant="body1">
              <Tag
                label={data?.status || ''}
                colorTag={data?.status === 'Pago' ? '#8BC34A' : '#FF5722'}
              />
            </ValueText>
          </Grid>

          {data?.responsible && (
            <Grid size={{ xs: 12, md: 3 }} mb={4}>
              <LabelText variant="body1">Distribuidora</LabelText>
              <ValueText variant="body1">{data?.responsible}</ValueText>
            </Grid>
          )}
        </Grid>

        {data?.boletoUrl && (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={(_, val) => setValue(val)}>
                {data?.boletoUrl && <Tab label="Descritivo" {...a11yProps(0)} />}
                {data?.boletoNumber && <Tab label="Código boleto" {...a11yProps(1)} />}
              </Tabs>
            </Box>

            {data?.boletoUrl && (
              <CustomTabPanel value={value} index={0}>
                <Button variant="outlined" color="inherit" sx={{ borderRadius: 10 }}>
                  <Link
                    to={data?.boletoUrl || ''}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    download
                    target="_blank"
                  >
                    Baixar Fatura
                  </Link>
                </Button>

                {data?.detailsList && (
                  <StyledDataGrid
                    columns={columnsDetails}
                    rows={data?.detailsList}
                    style={{ width: '100%', marginTop: '30px', height: '100px' }}
                    scrollbarSize={0}
                    disableColumnFilter
                    hideFooterSelectedRowCount
                    hideFooterPagination
                    disableRowSelectionOnClick
                    disableColumnMenu
                    hideFooter
                  />
                )}
              </CustomTabPanel>
            )}
            {data?.boletoNumber && (
              <CustomTabPanel value={value} index={1}>
                <Tooltip
                  title="Código de barras copiado"
                  onClose={() => setOpenedTooltip(false)}
                  open={openedTooltip}
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{ mb: 2, borderRadius: 10 }}
                    onClick={() => {
                      setOpenedTooltip(true)
                      navigator.clipboard.writeText(data?.boletoNumber || '')
                    }}
                  >
                    Copiar linha digitável
                  </Button>
                </Tooltip>
                <CodeBarText>{data?.boletoNumber}</CodeBarText>
                <StyledBarcode displayValue={false} value={data?.boletoNumber || ''} />
              </CustomTabPanel>
            )}
          </Box>
        )}
      </LoadingContentAdvanced>
    </DrawerStructure>
  )
}

export default DrawerDetails
