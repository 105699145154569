import { useState } from 'react'
import { IconArrowFullDown } from 'shared/designSystem/Icons/IconArrowFullDown'
import IconArrowUp from 'shared/designSystem/Icons/IconArrowUp'
import { theme } from 'shared/theme'
import { formatCurrency } from 'shared/utils/formatCurrency'
import {
  ContainerComparetedMonth,
  ContainerHeader,
  ParagraphComparetedYear,
  ParagraphDiffFormatted,
  ParagraphValueFormatted,
  ParagraphValueTotal,
  PeriodBadgeContainer,
  PeriodContainer
} from './style'
import useDataGraphTotals from 'pages/Financial/hooks/useDataGraphTotals'

export const Header = () => {
  const [selection, setSelection] = useState(0)
  const { data: dataTotals } = useDataGraphTotals()

  const totalGroupCurrentPeriod = dataTotals?.data?.totalGroupCurrentPeriod || 0
  const totalGroupPreviousPeriod = dataTotals?.data?.totalGroupPreviousPeriod || 0

  const isValueGreaterThanDiff = totalGroupCurrentPeriod > totalGroupPreviousPeriod

  const color = isValueGreaterThanDiff ? theme.designSystem.success : theme.designSystem.deepOrange[900]

  const valueFormatted = formatCurrency(totalGroupCurrentPeriod)
  const diffFormatted = formatCurrency(totalGroupPreviousPeriod)

  const periodOpions = [
    {
      label: 2024,
      value: 2024
    },
    {
      label: 'Últimos 12 meses',
      value: 0
    }
  ]

  const handleSelect = (value: number) => {
    setSelection(value)
  }

  return (
    <ContainerHeader>
      <PeriodContainer>
        {periodOpions.map(({ label, value }) => (
          <PeriodBadgeContainer
            key={value}
            selected={selection === value}
            onClick={() => handleSelect(value)}
          >
            {label}
          </PeriodBadgeContainer>
        ))}
      </PeriodContainer>
      <ParagraphValueTotal>Valor total dos grupos no ano</ParagraphValueTotal>
      <ParagraphValueFormatted>{valueFormatted}</ParagraphValueFormatted>
      <ContainerComparetedMonth>
        {isValueGreaterThanDiff ? <IconArrowUp color={color} /> : <IconArrowFullDown color={color} />}

        <ParagraphDiffFormatted color={color}>{diffFormatted}</ParagraphDiffFormatted>
        <ParagraphComparetedYear>comparado ao mesmo período do ano anterior</ParagraphComparetedYear>
      </ContainerComparetedMonth>
    </ContainerHeader>
  )
}
