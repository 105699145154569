import { jwtDecode } from 'jwt-decode'
import { ActivationStatus } from 'pages/MigrationSteps/models/ActivationStatus'
import { UnityApi } from 'pages/MigrationSteps/service/Unity'
import { useEffect, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Error } from 'shared/designSystem/components/Error'
import { Unity } from 'shared/models/Unity'
import { TOKEN_KEY } from 'shared/services/Api'
import { ErrorHandler } from 'shared/utils/ErrorHandler'
import { Card } from '../Card'
import { BoardLoading } from './BoardLoading'
import { Liststatus } from './status'
import { Column, ColumnHeader, ColumnTitle, CustomCounter, GridContainer, MainContainer } from './styles'

interface Props {
  role?: string
  selectedGroup: string
}

export const Board = ({ role, selectedGroup }: Props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [cardData, setCardData] = useState<Unity[]>([])
  const [listStatusData, setListStatusData] = useState<ActivationStatus[]>([])

  const getMigrationStepsCardData = async () => {
    try {
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      const migrationStepsDataResponse = await UnityApi.getMigrationsStepsData(id)
      const filteredCardData = filterCardsByUnitType(migrationStepsDataResponse.data, selectedGroup)
      setCardData(filteredCardData)
      setError(false)
    } catch (error) {
      const errorMessage = ErrorHandler.getErrorMessage(error)
      console.log(errorMessage)
      setError(true)
    }
  }

  const getMigrationStepsListData = async () => {
    try {
      const activationStatusDataResponse = await UnityApi.getActivationStatusData()
      setListStatusData(activationStatusDataResponse.data)
      setError(false)
    } catch (error) {
      const errorMessage = ErrorHandler.getErrorMessage(error)
      console.log(errorMessage)
      setError(true)
    }
  }

  const filterCardsByStatus = (status: string) => {
    return cardData.filter((cardData) => cardData.status === status)
  }

  const filterCardsByUnitType = (cardData: Unity[], selectedGroup: string) => {
    if (!(selectedGroup === 'Energy' || selectedGroup === 'Solar')) return cardData
    return cardData.filter((cardData) => cardData.unitTypeName === selectedGroup)
  }

  const filterListByCardType = (cardDataItem: Unity): ActivationStatus[] => {
    const statusMap: Record<string, string[]> = {
      'Energy-Varejo': Liststatus.energyVarejoStatus,
      'Energy-Atacado': Liststatus.energyAtacadoStatus,
      Solar: Liststatus.solarStatus
    }

    const key = `${cardDataItem.unitTypeName}-${cardDataItem.distributorTypeName}`

    const statuses = statusMap[key] || []

    return listStatusData.filter((status) => statuses.includes(status.name))
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        await Promise.all([getMigrationStepsCardData(), getMigrationStepsListData()])
        setError(false)
      } catch (err) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <MainContainer role={role}>
      {error ? (
        <Error />
      ) : loading ? (
        <Box role="loading-box">
          <BoardLoading />
        </Box>
      ) : (
        <GridContainer role="grid-container">
          <Column role="column">
            <ColumnHeader>
              <ColumnTitle role="column-title">Em preparação</ColumnTitle>
              <CustomCounter>{filterCardsByStatus('EmPreparacao').length}</CustomCounter>
            </ColumnHeader>
            {filterCardsByStatus('EmPreparacao').map((item, index) => (
              <Card role="card" key={index} cardData={item} listData={filterListByCardType(item)} />
            ))}
          </Column>

          <Column>
            <ColumnHeader>
              <ColumnTitle>Em migração</ColumnTitle>
              <CustomCounter>{filterCardsByStatus('EmMigracao').length}</CustomCounter>
            </ColumnHeader>
            {filterCardsByStatus('EmMigracao').map((item, index) => (
              <Card key={index} cardData={item} listData={filterListByCardType(item)} />
            ))}
          </Column>

          <Column>
            <ColumnHeader>
              <ColumnTitle>Migração concluída</ColumnTitle>
              <CustomCounter>{filterCardsByStatus('MigracaoConcluida').length}</CustomCounter>
            </ColumnHeader>
            {filterCardsByStatus('MigracaoConcluida').map((item, index) => (
              <Card key={index} cardData={item} listData={filterListByCardType(item)} />
            ))}
          </Column>
        </GridContainer>
      )}
    </MainContainer>
  )
}

export default Board
