import { HomeError } from 'pages/Home/components/Error/HomeError'
import { Footer } from 'pages/Home/components/Footer'
import { ContentContainer, ContentWrapper, MainContainer } from 'pages/Home/styles'
import { Box } from 'shared/designSystem/components/Box'
import { Grid } from 'shared/designSystem/components/Grid'
import { Loading } from 'shared/designSystem/components/Loading'
import { Header } from 'shared/layout/Header'
import { Sidebar } from 'shared/layout/Sidebar'
import { theme } from 'shared/theme'
import { BackgroundLogo } from './components/BackgroundLogo'
import { FilterDefault } from './components/FilterDefault'
import { Flag } from './components/Flag'
import { PDFDocument } from './components/PDFDocument'
import useReports from './useReports'
import { PdfElement } from 'shared/hook/usePdfElements'

export const Reports = () => {
  const {
    loading,
    fetchData,
    filterDefault,
    setFilterDefault,
    setDefaultYear,
    globalError,
    pdfElements,
    useModalComponents,
    reportPDFRef,
    filters
  } = useReports()

  return (
    <MainContainer bgcolor={theme.designSystem.base[100]}>
      <Box id="hide-in-pdf">
        <Sidebar data-testid="sidebar" />
      </Box>

      <ContentWrapper>
        <Header data-testid="header" fetchData={fetchData} filterDefault={filterDefault} />

        <ContentContainer mt={0.3} data-testid="content-container">
          {loading && (
            <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
              <Grid size={12} sx={{ height: '100%' }}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Loading />
                </Box>
              </Grid>
            </Grid>
          )}

          {filterDefault && (
            <FilterDefault setFilterDefault={setFilterDefault} setDefaultYear={setDefaultYear} />
          )}

          {globalError && (
            <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
              <Grid size={12} sx={{ height: '100%' }}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <HomeError
                    message="Um erro aconteceu e não foi possível carregar dados dos relatórios. Tente novamente."
                    onRetry={fetchData}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {!loading && !filterDefault && !globalError && (
            <>
              <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
                <Grid size={{ xs: 12, md: 'grow' }} display="flex" flexDirection="column">
                  <Grid container spacing={0.5}>
                    {pdfElements
                      .filter((item) => item?.product.includes(Number(filters?.product)))
                      .map(({ Graph, id, data, infosHeader }: PdfElement) => (
                        <Grid
                          key={id}
                          size={{ xs: 12, sm: 12, md: 6 }}
                          minHeight={560}
                          sx={{ flexGrow: 1 }}
                        >
                          <Graph variant="grid" dataInfos={data} dataInfosHeader={infosHeader} />
                        </Grid>
                      ))}
                    {Number(filters?.product) === 1 && (
                      <Grid size={{ xs: 12, sm: 12, md: 6 }} minHeight={560} sx={{ flexGrow: 1 }}>
                        <BackgroundLogo />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid width="100vw" mt={0.5}>
                <Footer />
              </Grid>
              {useModalComponents}
              <Flag type="green" />

              <Box
                sx={{
                  position: 'absolute',
                  top: '-9999px',
                  width: '270mm',
                  height: '367mm',
                  overflow: 'hidden',
                  visibility: 'hidden'
                }}
                ref={reportPDFRef}
              >
                {pdfElements
                  .filter((item) => item?.product.includes(Number(filters?.product)) && item?.data)
                  .map(({ Graph, columns, data, id, infosHeader, name }: any, index) => (
                    <PDFDocument
                      key={id}
                      name={name}
                      data={data?.dataTable}
                      page={index + 1}
                      footerTable={data?.tableTotals}
                      columns={columns}
                      numberOfPages={
                        pdfElements.filter(
                          (item) => item?.product.includes(Number(filters?.product)) && item?.data
                        ).length
                      }
                    >
                      <Graph variant="modal" dataInfos={data} dataInfosHeader={infosHeader} />
                    </PDFDocument>
                  ))}
              </Box>
            </>
          )}
        </ContentContainer>
      </ContentWrapper>
    </MainContainer>
  )
}
