import useFilters from 'shared/hook/useFilters'
import FinancialSolar from './financialSolar'
import FinancialEnergy from './financialEnergy'

import { MainContainer } from './styles'
import { ContentContainer, ContentWrapper } from 'pages/Home/styles'
import { Header } from 'shared/layout/Header'
import { Sidebar } from 'shared/layout/Sidebar'
import { theme } from 'shared/theme'
import { useEffect, useState } from 'react'
import { FilterDefault } from 'pages/Reports/components/FilterDefault'

const Financial = () => {
  const { filters } = useFilters('filtersAll')
  const [filterDefault, setFilterDefault] = useState(false)

  useEffect(() => {
    if (filters && !filters?.product) setFilterDefault(true)
  }, [filters])

  const renderProduct = () => {
    if (filters?.product === '2') return <FinancialSolar />
    return <FinancialEnergy />
  }

  return (
    <MainContainer bgcolor={theme.designSystem.white}>
      <Sidebar data-testid="sidebar" />
      <ContentWrapper>
        <Header data-testid="header" fetchData={() => null} />
        <ContentContainer mt={0.3} data-testid="content-container">
          {filterDefault ? (
            <FilterDefault setFilterDefault={setFilterDefault} isFinancial />
          ) : (
            renderProduct()
          )}
        </ContentContainer>
      </ContentWrapper>
    </MainContainer>
  )
}

export default Financial
