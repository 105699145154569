import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const HeaderContainer = styledSystem(Box)(({ theme }) => ({
  height: theme.designSystem.size[119],
  backgroundColor: theme.designSystem.base[900],
  padding: theme.designSystem.size[16],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

export const LogoContainer = styledSystem(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
}))

export const Title = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.caption,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[50]
}))

export const InfoContainer = styledSystem(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
}))

export const BoxIcon = styledSystem(Box, {
  shouldForwardProp: (prop) => prop !== 'hasBackground'
})<{ hasBackground?: boolean }>(({ theme, hasBackground }) => ({
  borderRadius: theme.designSystem.size[2],
  width: theme.designSystem.size[24],
  height: theme.designSystem.size[24],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: hasBackground ? theme.designSystem.base[700] : 'transparent'
}))

export const InfoItem = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.caption,
  fontWeight: theme.designSystem.typography.fontWeight.regular,
  color: '#FAFBFFB2'
}))

export const InfoDynamic = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.caption,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[50]
}))

export const TableContainer = styledSystem(Box)(({ theme }) => ({
  width: '100%',
  overflowX: 'auto',
  padding: theme.spacing(2)
}))

export const StyledTable = styledSystem('table')(({ theme }) => ({
  width: '100%',
  borderCollapse: 'collapse',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden'
}))

export const TableHeader = styledSystem('thead')(({ theme }) => ({
  color: theme.designSystem.base[400]
}))

export const TableCell = styledSystem('td')(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'left',
  border: `1px solid ${theme.designSystem.base[200]}`,
  fontSize: theme.designSystem.typography.fontSize.caption
}))

export const TableHeaderCell = styledSystem(TableCell)(({ theme }) => ({
  fontWeight: theme.designSystem.typography.fontWeight.medium
}))
