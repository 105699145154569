import Barcode from 'react-barcode'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const LabelText = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.body2,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[400],
  lineHeight: theme.designSystem.typography.fontSize[22],
  marginBottom: theme.designSystem.size[10]
}))

export const BigValueText = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.h3,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[900],
  lineHeight: theme.designSystem.typography.fontSize[32]
}))

export const ValueText = styledSystem(Typography)(({ theme, color }) => ({
  fontSize: theme.designSystem.typography.fontSize.body1,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: color || theme.designSystem.base[900],
  lineHeight: theme.designSystem.typography.fontSize[22],
  display: 'flex',
  alignItems: 'center',
  gap: theme.designSystem.size[5]
}))

export const StyledBarcode = styledSystem(Barcode)(() => ({
  maxWidth: '100%',
  svg: {
    maxWidth: '100%'
  }
}))

export const CodeBarText = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.body2,
  lineHeight: theme.designSystem.typography.fontSize[22],
  padding: theme.designSystem.size[16],
  width: '100%',
  color: theme.designSystem.base[500],
  backgroundColor: theme.designSystem.base[100]
}))

export const ContainerCodeTicket = styledSystem(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flexDirection: 'column'
}))
