import { Stack } from '@mui/material'
import { useState } from 'react'
import { InputAdornment } from 'shared/designSystem/components/InputAdornment'
import { Menu } from 'shared/designSystem/components/Menu'
import { MenuItem } from 'shared/designSystem/components/MenuItem'
import { TextField } from 'shared/designSystem/components/TextField'
import { ArrowDown } from 'shared/designSystem/Icons/ArrowDown'
import { ArrowUp } from 'shared/designSystem/Icons/ArrowUp'
import useFiltersGlobal from 'shared/hook/useFiltersGlobal'

const MultiLevelSelectYear = ({ handleFetchData, filtersModal, setFiltersModal }: any) => {
  const { globalDate } = useFiltersGlobal()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const isMenuOpen = Boolean(anchorEl)

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event?.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleYear = (year: number) => {
    const dataFilters = {
      year: year,
      productId: filtersModal?.product,
      groupId: filtersModal?.group,
      unitId: filtersModal?.unit
    }
    handleFetchData(dataFilters)
    setFiltersModal({ ...filtersModal, year: year })
    handleCloseMenu()
  }

  const handleTitle = () => {
    if (filtersModal?.year) return filtersModal?.year
    return 'Selecione uma data'
  }

  return (
    <>
      <TextField
        size="small"
        onClick={handleOpenMenu}
        variant="outlined"
        value={handleTitle()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isMenuOpen ? (
                <ArrowUp sx={{ cursor: 'pointer' }} />
              ) : (
                <ArrowDown sx={{ cursor: 'pointer' }} />
              )}
            </InputAdornment>
          ),
          style: { fontWeight: 'bold', cursor: 'pointer' }
        }}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiMenu-paper': {
            width: anchorEl ? `${anchorEl.offsetWidth}px` : 'auto',
            height: 'auto',
            border: 'none',
            borderRadius: '0',
            marginBottom: '10px',
            borderTop: '1px solid #E0E0E0'
          }
        }}
      >
        {globalDate?.filterDateList?.map((item) => (
          <MenuItem
            key={item.year}
            sx={{ fontWeight: filtersModal?.year == item.year ? 'bold' : '' }}
            onClick={() => handleYear(item.year)}
          >
            <Stack
              display="flex"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              {item.year}
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default MultiLevelSelectYear
