import { useEffect, useRef, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Grid } from 'shared/designSystem/components/Grid'
import { Loading } from 'shared/designSystem/components/Loading'
import useFilters from 'shared/hook/useFilters'
import { Header } from 'shared/layout/Header'
import { Sidebar } from 'shared/layout/Sidebar'
import {
  useApiConsumes,
  useApiEconomy,
  useApiPayments,
  useEcoImpact
} from 'shared/services/Requests/home'
import { ReportDataFilters } from 'shared/services/Requests/Reports/types'
import { theme } from 'shared/theme'
import ConsumeGraph from './components/ConsumeGraph'
import { EcoImpact } from './components/EcoImpact'
import EconomyGraph from './components/EconomyGraph'
import { ErrorCard } from './components/Error/ErrorCard/index'
import { Footer } from './components/Footer'
import PaymentOverview from './components/PaymentOverview'
import { TutorialOverview } from './components/TutorialOverview'
import WeekHighlights from './components/WeekHighlights'
import { ContentContainer, ContentWrapper, MainContainer } from './styles'

const Home = () => {
  const { filters } = useFilters('filtersAll')
  const { mutateConsumes } = useApiConsumes()
  const { mutateEconomy } = useApiEconomy()
  const { mutatePayments } = useApiPayments()
  const { mutateImpact } = useEcoImpact()

  const hasFetchedData = useRef(false)
  const dataFilters = {
    productId: filters?.product,
    groupId: filters?.group,
    unitId: filters?.unit
  }

  const [loading, setLoading] = useState(false)
  const [errorPage, setErrorPage] = useState({
    page: false
  })

  const handleComponentError = () => {
    setErrorPage({ ...errorPage, page: true })
  }

  const handleRetryButterCMS = async () => {
    setErrorPage({ ...errorPage, page: false })
  }

  const fetchDataRetry = async (typePage: string) => {
    try {
      switch (typePage) {
        case 'consumes':
          await mutateConsumes(dataFilters)
          break
        case 'economy':
          await mutateEconomy(dataFilters)
          break
        case 'payments':
          await mutatePayments(dataFilters)
          break
        default:
          break
      }
    } catch (error) {
      console.log('fetchDataConsumes', error)
    }
  }

  const fetchData = async (filtersApi: ReportDataFilters) => {
    try {
      setLoading(true)
      await Promise.all([
        mutateConsumes(filtersApi),
        mutateEconomy(filtersApi),
        mutatePayments(filtersApi),
        mutateImpact(filtersApi)
      ])
    } catch (error) {
      console.log('Erro ao obter parâmetros iniciais', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (filters && Object.keys(filters).length > 0 && !hasFetchedData.current) {
      hasFetchedData.current = true
      fetchData(dataFilters)
    }
  }, [filters])

  return (
    <MainContainer bgcolor={theme.designSystem.base[100]}>
      <Sidebar data-testid="sidebar" />
      <ContentWrapper>
        <Header data-testid="header" fetchData={fetchData} />
        <ContentContainer mt={0.3} data-testid="content-container">
          {loading && (
            <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
              <Grid size={12} sx={{ height: '100%' }}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Loading />
                </Box>
              </Grid>
            </Grid>
          )}

          {!loading && (
            <>
              <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
                <Grid size={{ xs: 12, md: 'grow' }} display="flex" flexDirection="column">
                  <Grid container spacing={0.5}>
                    <Grid size={{ xs: 12, sm: 'grow' }} minHeight={384} sx={{ flexGrow: 1 }}>
                      <ConsumeGraph fetchDataConsumes={() => fetchDataRetry('consumes')} />
                    </Grid>

                    <Grid size={{ xs: 12, sm: 'grow' }} sx={{ flexGrow: 1 }}>
                      <EconomyGraph fetchDataEconomy={() => fetchDataRetry('economy')} />
                    </Grid>
                  </Grid>

                  <Grid container mt={0.5}>
                    <Grid size="grow">
                      <PaymentOverview fetchDataPayments={() => fetchDataRetry('payments')} />
                    </Grid>
                  </Grid>
                  <Grid size="grow" mt={0.5}>
                    <EcoImpact />
                  </Grid>
                </Grid>

                <Grid size={{ xs: 12, md: 4 }} display="flex" flexDirection="column">
                  {errorPage.page ? (
                    <Box
                      sx={{
                        bgcolor: 'white',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <ErrorCard
                        message="de conteúdo e ajuda"
                        height={2}
                        onRetry={handleRetryButterCMS}
                      />
                    </Box>
                  ) : (
                    <>
                      <Grid container spacing={0.5}>
                        <Grid size="grow">
                          <WeekHighlights onError={handleComponentError} />
                        </Grid>
                      </Grid>

                      <Grid container spacing={0.5} pt={0.5}>
                        <Grid size="grow">
                          <TutorialOverview onError={handleComponentError} />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid width="100vw" mt={0.5}>
                <Footer />
              </Grid>
            </>
          )}
        </ContentContainer>
      </ContentWrapper>
    </MainContainer>
  )
}

export default Home
