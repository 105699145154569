type Props = {
  color?: string
}

export default function IconArrowUp({ color }: Props) {
  return (
    <svg width={10} height={11} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 5L5 1M5 1L1.5 5M5 1V10"
        stroke={color || '#FF5722'}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
