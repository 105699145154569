import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const StyledTypographyTitle = styledSystem(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[400],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.designSystem.typography.fontSize.button
  }
}))

export const StyledTypographyParaghraph = styledSystem(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[900],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.designSystem.typography.fontSize.h3
  }
}))

export const ContainerRenderTitle = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  gap: '10px',

  [theme.breakpoints.up('sm')]: {
    gap: '64px'
  }
}))
