import { useEffect, useMemo, useRef, useState } from 'react'
import { columnsEconomyAlertGraph } from 'shared/mocks/columnsPdf'
import EconomyAlertGraph from '../..'
import useFilters from 'shared/hook/useFilters'
import { useApiEconomyAlert } from 'shared/services/Requests/Reports'
import { useReportStore } from 'shared/store/reports'
import { ReportDataEconomyAlert } from 'shared/services/Requests/Reports/types'

const useModalEconomyAlert = () => {
  const { filters } = useFilters('filtersAll')
  const { mutateEconomyAlert, apiEconomyAlert, loading } = useApiEconomyAlert()
  const {
    state: { graphic }
  } = useReportStore()

  const [stateDataEconomyAlert, setStateDataEconomyAlert] = useState<ReportDataEconomyAlert | null>(
    apiEconomyAlert
  )

  const pdfRef = useRef(null)

  const pdfElements = useMemo(
    () => [
      {
        graph: (
          <EconomyAlertGraph dataInfosHeader={[]} variant="modal" dataInfos={stateDataEconomyAlert} />
        ),
        data: stateDataEconomyAlert?.dataTable,
        columns: columnsEconomyAlertGraph,
        footerTable: stateDataEconomyAlert?.tableTotals
      }
    ],
    [stateDataEconomyAlert]
  )

  useEffect(() => {
    if (graphic === 4 && filters?.year)
      mutateEconomyAlert({
        year: filters?.year,
        productId: filters?.product,
        groupId: filters?.group,
        unitId: filters?.unit
      })
  }, [graphic, filters])

  useEffect(() => {
    setStateDataEconomyAlert(apiEconomyAlert)
  }, [apiEconomyAlert])

  return {
    loading,
    pdfRef,
    pdfElements,
    stateDataEconomyAlert,
    mutateEconomyAlert
  }
}

export default useModalEconomyAlert
