import { useMediaQuery } from '@mui/material'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IconChartPie } from 'shared/designSystem/Icons/IconChartPie'
import { IconHome } from 'shared/designSystem/Icons/IconHome'
import { IconMoney } from 'shared/designSystem/Icons/IconMoney'
import { IconPageAlt } from 'shared/designSystem/Icons/IconPageAlt'
import { IconPlay } from 'shared/designSystem/Icons/IconPlay'
import { useTheme } from 'shared/hook/useTheme'
import { useAuthStore } from 'shared/store/auth'
import { useConsumptionStore } from 'shared/store/consumption'

const useSidebar = () => {
  const { actions: consumptionActions } = useConsumptionStore()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const toggleSidebar = () => setOpen((prev) => !prev)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const activeColor = theme.designSystem.base[50]
  const inactiveColor = theme.designSystem.base[400]

  const { actions } = useAuthStore()

  const handleLogout = async () => {
    setLoading(true)
    try {
      await actions.loggout()
      consumptionActions.resetState()
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const tabs = [
    { label: <IconHome role="home" />, path: '/home' },
    { label: <IconMoney role="money" />, path: '/finances' },
    { label: <IconChartPie role="chart-pie" />, path: '/reports' },
    { label: <IconPageAlt role="page-alt" />, path: '/docs' },
    { label: <IconPlay role="play" />, path: '/tutorials' }
  ]

  return {
    loading,
    error,
    theme,
    location,
    tabs,
    open,
    isSmallScreen,
    activeColor,
    inactiveColor,
    toggleSidebar,
    navigate,
    setError,
    handleLogout
  }
}

export default useSidebar
