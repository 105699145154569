import { Box } from 'shared/designSystem/components/Box'
import { Chip } from 'shared/designSystem/components/Chip'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const CardContentBox = styledSystem(Box)(({ theme }) => ({
  width: theme.designSystem.size[256],
  height: theme.designSystem.size[200],
  margin: theme.designSystem.size[10],
  boxShadow: 'none',
  border: `1px solid ${theme.designSystem.base[200]}`,
  borderRadius: 0
}))

export const ChipBox = styledSystem(Chip)(({
  theme,
  status
}: {
  theme?: any
  status: 'Pago' | 'Pendente' | 'Não disponível'
}) => {
  const statusColor =
    status === 'Pago'
      ? theme.designSystem.green[50]
      : status === 'Pendente'
        ? theme.designSystem.deepOrange[50]
        : theme.designSystem.base[100]

  const textColor =
    status === 'Pago'
      ? theme.designSystem.green[500]
      : status === 'Pendente'
        ? theme.designSystem.deepOrange[500]
        : theme.designSystem.base[300]

  return {
    fontSize: theme.designSystem.typography.fontSize.caption,
    fontWeight: theme.designSystem.typography.fontWeight.medium,
    backgroundColor: statusColor,
    color: textColor,
    borderRadius: theme.designSystem.size[4],
    padding: 0,
    height: theme.designSystem.size[20],
    minHeight: 'unset'
  }
})

export const CardUCName = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.caption,
  fontWeight: theme.designSystem.typography.fontWeight.regular,
  color: theme.designSystem.base[500]
}))

export const CardName = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.button,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[900]
}))

export const CardDescription = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.caption,
  fontWeight: theme.designSystem.typography.fontWeight.regular,
  color: theme.designSystem.base[400]
}))

export const CardDate = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.caption,
  fontWeight: theme.designSystem.typography.fontWeight.regular,
  color: theme.designSystem.base[400]
}))

export const CardPrice = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.button,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[900]
}))
