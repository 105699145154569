export const IconExpand = () => {
  return (
    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 3.72727V1H3.72727M8.27273 1H11V3.72727M11 8.27273V11H8.27273M3.72727 11H1V8.27273"
        stroke="#050922"
        strokeOpacity={0.38}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
