export const IconSearch = () => {
  return (
    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity={0.5}
        d="M14.3333 14.6663L10.3333 10.6663M11.6667 6.66634C11.6667 9.61167 9.27867 11.9997 6.33333 11.9997C3.388 11.9997 1 9.61167 1 6.66634C1 3.72101 3.388 1.33301 6.33333 1.33301C9.27867 1.33301 11.6667 3.72101 11.6667 6.66634Z"
        stroke="#01192C"
        strokeWidth={1.1}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
