import { useState } from 'react'
import { HomeDataFilters } from './types'
import api, { TOKEN_KEY } from 'shared/services/Api'
import endpoints from 'shared/services/Api/endpoints'
import { jwtDecode } from 'jwt-decode'
import { useConsumptionStore } from 'shared/store/consumption'
import ecoImpact from '../../../mocks/ecoImpact.json'

const useApiConsumes = () => {
  const {
    state: { filteredConsumes },
    actions: { setFilteredConsumes, setErrorConsumes }
  } = useConsumptionStore()

  const [loading, setLoading] = useState(false)

  const mutateConsumes = async (filters: HomeDataFilters) => {
    try {
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      const date = new Date()
      const year = date.getFullYear()

      setLoading(true)
      setErrorConsumes(false)

      const response = await api.get(endpoints.home.getConsumes({ ...filters, userId: id, year }))
      setFilteredConsumes([response?.data?.data])
    } catch (error: any) {
      console.error('Erro ao buscar consumo home:', error)
      if (error?.status != 400) setErrorConsumes(true)

      setFilteredConsumes([])
    } finally {
      setLoading(false)
    }
  }

  return { mutateConsumes, filteredConsumes, loading }
}

const useApiEconomy = () => {
  const {
    state: { filteredEconomy },
    actions: { setFilteredEconomy, setErrorEconomy }
  } = useConsumptionStore()

  const [loading, setLoading] = useState(false)

  const mutateEconomy = async (filters: HomeDataFilters) => {
    try {
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      const date = new Date()
      const year = date.getFullYear()

      setLoading(true)
      setErrorEconomy(false)

      const response = await api.get(endpoints.home.getEconomy({ ...filters, userId: id, year }))

      setFilteredEconomy(response?.data?.data)
    } catch (error) {
      console.error('Erro ao buscar economia home:', error)
      setErrorEconomy(true)
      setFilteredEconomy({
        totalTraditionalCost: 0,
        totalGenialCost: 0,
        totalEconomyValue: 0
      })
    } finally {
      setLoading(false)
    }
  }

  return { mutateEconomy, filteredEconomy, loading }
}

const useApiPayments = () => {
  const {
    state: { filteredPayment },
    actions: { setFilteredPayment, setErrorPayments }
  } = useConsumptionStore()

  const [loading, setLoading] = useState(false)

  const mutatePayments = async (filters: HomeDataFilters) => {
    try {
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      const date = new Date()
      const year = date.getFullYear()

      setLoading(true)
      setErrorPayments(false)

      const response = await api.get(endpoints.home.getPayments({ ...filters, userId: id, year }))

      setFilteredPayment(response?.data)
    } catch (error) {
      console.error('Erro ao buscar pagamentos home:', error)

      setErrorPayments(true)
      setFilteredPayment([])
    } finally {
      setLoading(false)
    }
  }

  return { mutatePayments, filteredPayment, loading }
}

const useEcoImpact = () => {
  const {
    actions: { setEcoImpact }
  } = useConsumptionStore()

  const mutateImpact = async (filters: HomeDataFilters) => {
    try {
      // TODO: tirar mock e utilizar do futuro endpoint
      // const response = await api.get(endpoints.home.getEcoImpact({ ...filters })) // <- futuro endpoint
      const response = ecoImpact.data.find((item: any) => item.id === filters.unitId)
      setEcoImpact({ tons: response!.tons, trees: response!.trees })
    } catch (error) {
      setEcoImpact({ tons: 0, trees: 0 })
    }
  }

  return {
    mutateImpact
  }
}

export { useApiConsumes, useApiEconomy, useApiPayments, useEcoImpact }
