import { ReactNode } from 'react'
import { StyledTooltip } from './styles'

interface Props {
  title: ReactNode
  children: ReactNode
}

export const LogoutTooltip = ({ title, children, ...props }: Props) => {
  return (
    <StyledTooltip {...props} title={title} arrow placement="right">
      {children}
    </StyledTooltip>
  )
}
