import { useSearchParams } from 'react-router-dom'
import useFilters from 'shared/hook/useFilters'
import useFiltersGlobal from 'shared/hook/useFiltersGlobal'
import { dataReports } from 'shared/mocks/downloadPdf.json'
import { getMonths } from 'shared/utils/dates'

const useHeader = () => {
  const { globalProducts } = useFiltersGlobal()
  const { filters } = useFilters('filtersAll')

  const title = dataReports.dataTableHeader.title
  const product = filters?.product == 1 ? 'Energy' : 'Solar'

  const [searchParams] = useSearchParams()

  const unitSelected = searchParams.has('unit')
  const groupSelected = searchParams.has('group')
  const monthSelected = searchParams.get('month')

  const getUnitName = () => {
    return globalProducts?.flatMap((product) =>
      product.groups.flatMap((group) =>
        group.units.filter((unit) => unit.value === parseInt(searchParams.get('unit')!, 10))
      )
    )[0]?.name
  }
  const getGroupName = () => {
    return globalProducts?.flatMap((product) =>
      product.groups.filter((group) => group.value === parseInt(searchParams.get('group')!, 10))
    )[0]?.name
  }
  const getProducName = () => {
    return globalProducts?.filter(
      (product) => product.value === parseInt(searchParams.get('product')!, 10)
    )[0]?.name
  }

  const months = getMonths()

  const selection = unitSelected ? getUnitName() : groupSelected ? getGroupName() : getProducName()
  const yearSelection =
    monthSelected !== '0'
      ? `${monthSelected ? `${months[parseInt(monthSelected!) - 1]} -` : ''} ${searchParams.get('year')}`
      : searchParams.get('year')

  return { product, title, selection, yearSelection }
}

export default useHeader
