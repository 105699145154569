import { ConsumptionData } from 'pages/Home/models/ConsumptionData'
import { EconomyData } from 'pages/Home/models/EconomyData'
import { Group, Unit } from 'pages/Home/models/Group'
import { create } from 'zustand'
import state from './state'
import { StoreProps } from './types'
import { EcoImpactData } from 'pages/Home/models/EcoImpactData'

export const useConsumptionStore = create<StoreProps>((set) => ({
  state,
  actions: {
    resetState: () => set({ state }),
    setGroups: (groups: Group[]) => {
      set((store) => ({
        state: { ...store.state, groups }
      }))
    },
    selectUnit: (unit: Unit) => {
      set((store) => {
        const consume = store.state.consumes.find(
          (consumption) => consumption.unit[0].installationNumber === unit.installationNumber
        )?.energy

        return {
          ...store,
          state: { ...store.state, unit, consume: consume ?? 0 }
        }
      })
    },
    setSelectedGroup: (group: Group | null) => {
      set((store) => ({
        state: { ...store.state, selectedGroup: group }
      }))
    },
    selectConsume: (consume: ConsumptionData) => {
      set((store) => ({
        state: { ...store.state, consume: consume.energy }
      }))
    },
    setConsumes: (consumes: ConsumptionData[]) => {
      set((store) => ({
        state: { ...store.state, consumes }
      }))
    },
    setEcoImpact: (ecoImpact: EcoImpactData) => {
      set((store) => ({
        state: { ...store.state, ecoImpact }
      }))
    },
    setSelectedProduct: (product: string) => {
      set((store) => {
        const filteredGroups = store.state.groups.filter(
          (group) => group.units[0].unitType.unitTypeName === product
        )
        return {
          state: { ...store.state, filteredGroups, selectedProduct: product }
        }
      })
    },
    setError: (error: boolean) => {
      set((store) => {
        return {
          state: { ...store.state, error }
        }
      })
    },
    setFilteredConsumes: (filteredConsumes: ConsumptionData[]) => {
      set((store) => ({
        state: { ...store.state, filteredConsumes }
      }))
    },
    setFilteredEconomy: (filteredEconomy: EconomyData) => {
      set((store) => ({
        state: { ...store.state, filteredEconomy }
      }))
    },
    setFilteredPayment: (filteredPayment: any[]) => {
      set((store) => ({
        state: { ...store.state, filteredPayment }
      }))
    },
    setErrorConsumes: (error: boolean) => {
      set((store) => ({
        state: { ...store.state, errorConsumes: error }
      }))
    },
    setErrorEconomy: (error: boolean) => {
      set((store) => ({
        state: { ...store.state, errorEconomy: error }
      }))
    },
    setErrorPayments: (error: boolean) => {
      set((store) => ({
        state: { ...store.state, errorPayments: error }
      }))
    }
  }
}))
