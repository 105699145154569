import { ChartOptions } from 'chart.js'
import { GraphPropsCO2 } from 'pages/Reports/types/GraphProps'
import { useRef, useState } from 'react'
import useFilters from 'shared/hook/useFilters'
import { theme } from 'shared/theme'
import { getMonthAbbreviations, getMonthFirstLetter, getMonths } from 'shared/utils/dates'

const useCO2Graph = ({ variant, dataInfos }: GraphPropsCO2) => {
  const { filters } = useFilters('filtersAll')

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [tooltipData, setTooltipData] = useState<{
    x: number
    y: number
    visible: boolean
  }>({ x: 0, y: 0, visible: false })

  const isModalVariant = variant === 'modal'
  const months = getMonths()
  const treePlanting = dataInfos?.dataGraphic?.treePlanting || []
  const estimatedTreePlanting = dataInfos?.dataGraphic?.estimatedTreePlanting || []
  const hasData = !isModalVariant ? (!filters?.month || filters?.month == 0) && !!dataInfos : !!dataInfos

  const data = {
    labels: isModalVariant ? getMonthAbbreviations() : getMonthFirstLetter(),
    datasets: [
      {
        label: 'Line Dataset',
        data: treePlanting,
        borderColor: theme.designSystem.primary[500],
        borderWidth: 2,
        pointBackgroundColor: theme.designSystem.white,
        pointBorderColor: theme.designSystem.primary[500],
        backgroundColor: theme.designSystem.primary[100],
        fill: true
      },
      {
        label: 'Line Dataset',
        data: estimatedTreePlanting,
        borderColor: theme.designSystem.base[200],
        borderWidth: 2,
        pointBackgroundColor: theme.designSystem.base[50],
        pointBorderColor: theme.designSystem.base[200]
      }
    ]
  }

  const prevTooltipData = useRef(tooltipData)

  const options: ChartOptions<'line'> = {
    responsive: true,
    animation: false,
    onHover: (_, activeElements) => {
      const newIndex = activeElements.length ? activeElements[0].index : null
      setHoveredIndex(newIndex)
      if (!activeElements.length) {
        setTimeout(() => {
          setHoveredIndex(null)
        }, 50)
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        grid: {
          display: isModalVariant
        },
        ticks: {
          display: isModalVariant
        },
        border: {
          display: false
        }
      },
      x: {
        type: 'category',
        grid: { display: false },
        border: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          const { chart, tooltip } = context

          if (tooltip.opacity === 0) {
            if (tooltipData.visible) {
              setTooltipData((prev) => ({ ...prev, visible: false }))
            }
            return
          }

          const { offsetLeft, offsetTop } = chart.canvas

          if (
            prevTooltipData.current.x !== offsetLeft + tooltip.caretX ||
            prevTooltipData.current.y !== offsetTop + tooltip.caretY ||
            !tooltipData.visible
          ) {
            const newTooltipData = {
              x: offsetLeft + tooltip.caretX,
              y: offsetTop + tooltip.caretY,
              visible: true
            }
            prevTooltipData.current = newTooltipData
            setTooltipData(newTooltipData)
          }
        }
      },
      datalabels: {
        display: false
      }
    }
  }

  const verticalHoverFill = {
    id: 'verticalHoverFill ',
    beforeDatasetDraw(chart: any) {
      const {
        ctx,
        chartArea: { top, bottom, left },
        scales: { x, y }
      } = chart
      ctx.save()

      const activePoint = chart.getDatasetMeta(0).data.find((dataPoint: any) => dataPoint.active)

      if (activePoint) {
        const hoverX = activePoint.x
        ctx.beginPath()
        ctx.moveTo(left, bottom)

        chart.data.datasets[0].data.forEach((value: number, index: number) => {
          const xPosition = x.getPixelForValue(index)
          const yPosition = y.getPixelForValue(value)

          if (xPosition <= hoverX) {
            ctx.lineTo(xPosition, yPosition)
          }
        })
        ctx.lineTo(hoverX, bottom)
        ctx.closePath()
        ctx.fillStyle = theme.designSystem.primary[100]
        ctx.fill()
      }

      chart.getDatasetMeta(0).data.forEach((dataPoint: any) => {
        if (dataPoint.active === true) {
          const x = dataPoint.x
          ctx.beginPath()
          ctx.strokeStyle = theme.designSystem.primary[500]
          ctx.moveTo(x, top)
          ctx.lineTo(x, bottom)
          ctx.stroke()
        }
      })
      ctx.restore()
    }
  }

  const legends = [
    { color: theme.designSystem.primary[500], text: 'Plantio de árvores', variant: 'square' },
    { color: theme.designSystem.base[200], text: 'Plantio estimado de árvores', variant: 'square' }
  ]

  const dataInfosHeaderEmpty = [
    {
      titleValue: 'CO₂ poupado no ano',
      value: '0 Kg',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    },
    {
      titleValue: 'Equivalência em árvores',
      value: '0',
      tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
    }
  ]

  return {
    isModalVariant,
    data,
    options,
    verticalHoverFill,
    tooltipData,
    months,
    hoveredIndex,
    treePlanting,
    legends,
    estimatedTreePlanting,
    hasData,
    dataInfosHeaderEmpty
  }
}

export default useCO2Graph
