import { Box } from 'shared/designSystem/components/Box'
import { Stack } from 'shared/designSystem/components/Stack'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const ContentContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  overflow: 'auto',
  padding: theme.designSystem.size[2],
  width: '100%',
  flexWrap: 'wrap'
}))

export const ContainerTabs = styledSystem(Box)(() => ({
  width: '100%'
}))

export const ContainerFiltersHeader = styledSystem(Stack)(({ theme }) => ({
  flexDirection: 'column',
  padding: '16px 16px 0 16px',
  gap: '24px',
  width: '100%',
  background: theme.designSystem.white
}))
