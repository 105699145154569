type Props = {
  sx?: React.CSSProperties
}
export const ArrowDown = ({ sx = {} }: Props) => {
  return (
    <svg
      width={12}
      height={8}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={sx}
    >
      <path
        d="M1.33594 1.33301L6.0026 6.66634L10.6693 1.33301"
        stroke="#050922"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
