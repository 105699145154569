import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from 'axios'

export const v1 = '/api/v1'

const API_URL = import.meta.env.VITE_API_URL as string

export const TOKEN_KEY = '@genial-energia'
export const api: AxiosInstance = axios.create({
  baseURL: `${API_URL}${v1}`
})

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem(TOKEN_KEY)
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.clear()
      window.location.href = '/'
    }
    return Promise.reject(error)
  }
)

export default api
