import { useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Fade } from 'shared/designSystem/components/Fade'
import { Modal } from 'shared/designSystem/components/Modal'
import { Slide } from 'shared/designSystem/components/Slide'
import { RecoverPassword } from './components/RecoverPassword'
import { SuccessChangePassword } from './components/SuccessChangePassword'
import { ResetPassword } from './ResetPassword'

type Props = {
  open: boolean
  onClose: () => void
}

export const PasswordRecoveryFlow = ({ open, onClose }: Props) => {
  const [currentModal, setCurrentModal] = useState<'recover' | 'reset' | 'success'>('recover')
  const [showContent, setShowContent] = useState(true)
  const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('left')
  const [email, setEmail] = useState<string>('')

  const handleCloseModal = () => {
    setShowContent(false)
    setTimeout(() => {
      onClose()
      setCurrentModal('recover')
      setShowContent(true)
      setSlideDirection('left')
    }, 300)
  }

  const handleSwitchToReset = () => {
    setShowContent(false)
    setTimeout(() => {
      setCurrentModal('reset')
      setShowContent(true)
    }, 300)
  }

  const handleSwitchToSuccess = () => {
    setShowContent(false)
    setTimeout(() => {
      setCurrentModal('success')
      setShowContent(true)
    }, 300)
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      slotProps={{
        backdrop: {
          style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
        }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          zIndex: 1300
        }}
      >
        {currentModal === 'recover' ? (
          <Fade in={showContent} timeout={300}>
            <Box>
              <RecoverPassword
                handleClose={handleCloseModal}
                handleSwitchToReset={handleSwitchToReset}
                email={email}
                setEmail={setEmail}
              />
            </Box>
          </Fade>
        ) : (
          <Slide in={showContent} direction={slideDirection} timeout={300}>
            <Box>
              {currentModal === 'reset' ? (
                <ResetPassword
                  handleClose={handleCloseModal}
                  handleSwitchToSuccess={handleSwitchToSuccess}
                  email={email}
                />
              ) : (
                <SuccessChangePassword handleClose={handleCloseModal} />
              )}
            </Box>
          </Slide>
        )}
      </Box>
    </Modal>
  )
}
