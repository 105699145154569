import { ChartOptions } from 'chart.js'
import { GraphPropsConsumer } from 'pages/Reports/types/GraphProps'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useFilters from 'shared/hook/useFilters'
import { DataMonthsConsumer } from 'shared/services/Requests/Reports/types'
import { theme } from 'shared/theme'
import { getDesaturatedColor } from 'shared/utils/getDesaturatedColor'
import {
  generateProjection,
  getDaysInMonth,
  getMonthAbbreviations,
  getMonthFirstLetter,
  getMonths
} from 'shared/utils/dates'

const useConsumeGraphEnergy = ({ variant, dataInfos }: GraphPropsConsumer) => {
  const { filters } = useFilters('filtersAll')

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [isMonthView, setIsMonthView] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState<DataMonthsConsumer | null>(null)
  const [tooltipData, setTooltipData] = useState<{
    x: number
    y: number
    visible: boolean
  }>({ x: 0, y: 0, visible: false })

  const isModalVariant = variant === 'modal'
  const contractedVolume = dataInfos?.dataGraphic?.contractedVolume || []
  const consumedVolume = dataInfos?.dataGraphic?.consumedVolume || []
  const minFlexibility = dataInfos?.dataGraphic?.minFlexibility || 0
  const maxFlexibility = dataInfos?.dataGraphic?.maxFlexibility || 0
  const hasData = isMonthView ? selectedMonth : !!dataInfos
  const months = getMonths()

  useEffect(() => {
    if (filters?.month && filters?.month != 0 && !isModalVariant) {
      const dataMonth = dataInfos?.dataGraphic?.months?.find(
        (item) => item?.month === Number(filters?.month) - 1
      )
      setIsMonthView(true)
      if (dataMonth) return setSelectedMonth(dataMonth)
      setSelectedMonth(null)
    } else {
      setSelectedMonth(null)
      setIsMonthView(false)
    }
  }, [filters?.month])

  const data = {
    labels: isModalVariant ? getMonthAbbreviations() : getMonthFirstLetter(),
    datasets: [
      {
        label: 'Volume contratado / Energia consumida',
        data: contractedVolume,
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.primary[100]
            : getDesaturatedColor(theme.designSystem.primary[100]),
        barPercentage: 1,
        barThickness: window.innerWidth <= 768 ? 8 : 22,
        categoryPercentage: 1,
        stack: 'stack1'
      },
      {
        label: 'Volume consumido / Energia compensada',
        data: consumedVolume.map((value) => {
          if (maxFlexibility) {
            return Math.min(value, maxFlexibility)
          } else if (minFlexibility) {
            return Math.max(value, minFlexibility)
          }
          return value
        }),
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.primary[500]
            : getDesaturatedColor(theme.designSystem.primary[500]),
        barPercentage: 1,
        barThickness: window.innerWidth <= 768 ? 8 : 22,
        categoryPercentage: 1,
        stack: 'stack2'
      },
      {
        label: 'Volume consumido (excesso)',
        data: consumedVolume.map(
          (value) => maxFlexibility && (value > maxFlexibility ? value - maxFlexibility : 0)
        ),
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.deepOrange[500]
            : getDesaturatedColor(theme.designSystem.deepOrange[500]),
        barPercentage: 1,
        barThickness: window.innerWidth <= 768 ? 8 : 22,
        categoryPercentage: 1,
        stack: 'stack2'
      },
      {
        label: 'Volume consumido (credito)',
        data: consumedVolume.map(
          (value) => minFlexibility && (value < minFlexibility ? minFlexibility - value : 0)
        ),
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.green[500]
            : getDesaturatedColor(theme.designSystem.green[500]),
        barPercentage: 1,
        barThickness: window.innerWidth <= 768 ? 8 : 22,
        categoryPercentage: 1,
        stack: 'stack2'
      }
    ]
  }

  const detailedData = {
    labels: getDaysInMonth(selectedMonth?.month || 0, filters?.year),
    datasets: [
      {
        label: 'Line Dataset',
        data: selectedMonth?.consumedDay,
        borderColor: theme.designSystem.primary[500],
        borderWidth: 2,
        pointBackgroundColor: theme.designSystem.white,
        pointBorderColor: theme.designSystem.primary[500],
        backgroundColor: theme.designSystem.primary[100],
        fill: true
      },
      {
        label: 'Line Dataset',
        data: generateProjection(selectedMonth?.consumedDay || [], 30),
        borderColor: theme.designSystem.base[200],
        borderWidth: 2,
        pointBackgroundColor: theme.designSystem.base[50],
        pointBorderColor: theme.designSystem.base[200]
      }
    ]
  }

  const prevTooltipData = useRef(tooltipData)

  const handleTooltip = useCallback(
    ({ chart, tooltip }: any) => {
      if (tooltip.opacity === 0) {
        if (tooltipData.visible) setTooltipData({ ...tooltipData, visible: false })
        return
      }

      const { offsetLeft, offsetTop } = chart.canvas
      const newTooltipData = {
        x: offsetLeft + tooltip.caretX,
        y: offsetTop + tooltip.caretY,
        visible: true
      }

      if (
        prevTooltipData.current.x !== newTooltipData.x ||
        prevTooltipData.current.y !== newTooltipData.y ||
        !tooltipData.visible
      ) {
        prevTooltipData.current = newTooltipData
        setTooltipData(newTooltipData)
      }
    },
    [tooltipData]
  )

  const baseOptions: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    animation: false,
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
      annotation: {
        annotations: {
          line1:
            maxFlexibility !== 0 || isMonthView
              ? {
                  type: 'line' as const,
                  yMin: isMonthView ? selectedMonth?.maxFlexibilityMonth || 0 : maxFlexibility,
                  yMax: isMonthView ? selectedMonth?.maxFlexibilityMonth || 0 : maxFlexibility,
                  borderColor: theme.designSystem.deepOrange[500],
                  borderWidth: 2,
                  drawTime: 'afterDatasetsDraw'
                }
              : undefined,
          line2:
            maxFlexibility !== 0 || isMonthView
              ? {
                  type: 'line' as const,
                  yMin: isMonthView ? selectedMonth?.minFlexibilityMonth || 0 : minFlexibility,
                  yMax: isMonthView ? selectedMonth?.minFlexibilityMonth || 0 : minFlexibility,
                  borderColor: theme.designSystem.attention[500],
                  borderWidth: 2,
                  drawTime: 'afterDatasetsDraw'
                }
              : undefined,
          line3: isMonthView
            ? {
                type: 'line' as const,
                yMin: selectedMonth?.contractedVolumeMonth || 0,
                yMax: selectedMonth?.contractedVolumeMonth || 0,
                borderColor: theme.designSystem.green[500],
                borderWidth: 2,
                drawTime: 'afterDatasetsDraw'
              }
            : undefined
        }
      },
      tooltip: {
        enabled: false,
        external: handleTooltip
      }
    },
    onHover: (_, activeElements) => {
      const newIndex = activeElements.length ? activeElements[0].index : null
      setHoveredIndex(newIndex)
      if (!activeElements.length) {
        setTimeout(() => {
          setHoveredIndex(null)
        }, 50)
      }
    }
  }

  const options: ChartOptions<'bar'> = {
    ...baseOptions,
    onClick: (_, activeElements) => {
      if (activeElements.length > 0 && !isModalVariant) {
        const index = activeElements[0].index
        const dataMonth = dataInfos?.dataGraphic?.months?.find((item) => item?.month === index)
        setIsMonthView(true)
        if (dataMonth) return setSelectedMonth(dataMonth)
        setSelectedMonth(null)
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: isModalVariant
        },
        ticks: {
          display: isModalVariant
        },
        border: {
          display: false
        }
      }
    }
  }

  const detailedOptions: ChartOptions<'line'> = {
    ...baseOptions,

    onClick: () => {
      setSelectedMonth(null)
      setIsMonthView(false)
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        grid: {
          display: isModalVariant
        },
        ticks: {
          display: isModalVariant
        },
        border: {
          display: false
        }
      },
      x: {
        type: 'category',
        grid: { display: false },
        border: {
          display: false
        },
        ticks: {
          display: true,
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          callback: function (_, index: number) {
            const days = this.getLabels()
            const totalDays = days.length
            if (index === totalDays - 1) {
              return days[index]
            }
            if ((index + 1) % 5 === 0 && index !== totalDays - 2) {
              return days[index]
            }
            return ''
          }
        }
      }
    }
  }

  const verticalHoverFill = {
    id: 'verticalHoverFill ',
    beforeDatasetDraw(chart: any) {
      const {
        ctx,
        chartArea: { top, bottom, left },
        scales: { x, y }
      } = chart
      ctx.save()

      const activePoint = chart.getDatasetMeta(0).data.find((dataPoint: any) => dataPoint.active)

      if (activePoint) {
        const hoverX = activePoint.x
        ctx.beginPath()
        ctx.moveTo(left, bottom)

        chart.data.datasets[0].data.forEach((value: number, index: number) => {
          const xPosition = x.getPixelForValue(index)
          const yPosition = y.getPixelForValue(value)

          if (xPosition <= hoverX) {
            ctx.lineTo(xPosition, yPosition)
          }
        })
        ctx.lineTo(hoverX, bottom)
        ctx.closePath()
        ctx.fillStyle = theme.designSystem.primary[100]
        ctx.fill()
      }

      chart.getDatasetMeta(0).data.forEach((dataPoint: any) => {
        if (dataPoint.active === true) {
          const x = dataPoint.x
          ctx.beginPath()
          ctx.strokeStyle = theme.designSystem.primary[500]
          ctx.moveTo(x, top)
          ctx.lineTo(x, bottom)
          ctx.stroke()
        }
      })
      ctx.restore()
    }
  }

  const legends = [
    {
      show: isMonthView,
      color: theme.designSystem.primary[100],
      text: 'Volume contratado',
      variant: 'square'
    },
    {
      color: theme.designSystem.primary[500],
      text: 'Volume consumido',
      variant: 'square'
    },
    {
      show: isMonthView,
      color: theme.designSystem.green[500],
      secondaryColor: theme.designSystem.deepOrange[500],
      text: 'Curto prazo',
      variant: 'square'
    },
    {
      show: !isMonthView,
      color: theme.designSystem.green[500],
      text: 'Volume contratado',
      variant: 'line-thin'
    },
    {
      color: theme.designSystem.attention[500],
      text: 'Flexibilidade mínima',
      variant: 'line-thin'
    },
    {
      color: theme.designSystem.deepOrange[500],
      text: 'Flexibilidade máxima',
      variant: 'line-thin'
    },
    {
      show: isMonthView,
      color: theme.designSystem.primary[100],
      secondaryColor: theme.designSystem.primary[200],
      text: 'Aguardando medição',
      variant: 'line-thick'
    }
  ]

  const dataInfosHeaderDetail = useMemo(() => {
    return [
      {
        titleValue: `Consumo em ${months[Number(selectedMonth?.month || filters?.month) - 1]}`,
        value: `${consumedVolume.find((_, i) => i === selectedMonth?.month) ?? 0} KWh`,
        tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
      }
    ]
  }, [selectedMonth, filters?.month])

  const labels = [
    {
      label: 'Volume contratado',
      value: `${contractedVolume[hoveredIndex!] ?? 0} MWh`
    },
    {
      label: 'Volume consumido',
      value: `${consumedVolume[hoveredIndex!] ?? 0} MWh`
    }
  ]

  const labelsMonth = [
    {
      label: `Consumido em ${months[selectedMonth?.month ?? 0]}`,
      value: `${consumedVolume.find((_, i) => i === selectedMonth?.month) ?? 0} KWh`
    },
    {
      label: `Consumido até o dia ${Number(hoveredIndex) + 1}`,
      value: `${
        selectedMonth?.consumedDay
          ?.slice(0, Number(hoveredIndex) + 1)
          .reduce((total, value) => total + value, 0) ?? 0
      } KWh`
    },
    {
      label: `Consumido no dia ${Number(hoveredIndex) + 1}`,
      value: `${selectedMonth?.consumedDay[hoveredIndex!] ?? 0} KWh`
    }
  ]

  const handleEmpty = () => {
    setSelectedMonth(null)
    setIsMonthView(false)
  }

  return {
    isModalVariant,
    hasData,
    setHoveredIndex,
    data,
    options,
    tooltipData,
    legends,
    labels,
    isMonthView,
    detailedOptions,
    detailedData,
    verticalHoverFill,
    labelsMonth,
    dataInfosHeaderDetail,
    handleEmpty
  }
}

export default useConsumeGraphEnergy
