type Props = {
  role?: string
  color?: string
}

export const IconArrowFullDown = ({ role, color }: Props) => {
  return (
    <svg
      role={role}
      width={12}
      height={13}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 7L6 11M6 11L9.5 7M6 11V2"
        stroke={color || '#FAFBFF'}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
