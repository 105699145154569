import { Box } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const CardContainer = styledSystem(Box)(({ theme }) => ({
  height: theme.designSystem.size[143],
  minWidth: theme.designSystem.size[277],
  marginBottom: theme.designSystem.size[24],
  borderRadius: theme.designSystem.size[2],
  padding: theme.designSystem.size[16],
  borderStyle: 'solid',
  borderWidth: theme.designSystem.size[2],
  borderColor: theme.designSystem.base[100],
  position: 'relative'
}))

export const CardName = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.button,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  marginBottom: theme.designSystem.size[5]
}))

export const CardNumber = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.button,
  marginBottom: theme.designSystem.size[5],
  textAlign: 'right',
  color: theme.designSystem.base[300]
}))

export const CardHeader = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.designSystem.size[8]
}))

export const CardBodyTypography = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.caption,
  color: theme.designSystem.base[400],
  marginBottom: theme.designSystem.size[8]
}))

export const CardFooter = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.designSystem.size[8],
  marginBottom: theme.designSystem.size[8],
  marginRight: theme.designSystem.size[12],
  position: 'absolute',
  bottom: 0
}))

export const CardEnterprise = styledSystem(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const CardLogo = styledSystem('img')(() => ({
  width: '24px',
  height: '24px',
  marginRight: '8px'
}))

export const CardButton = styledSystem(Button)(({ theme }) => ({
  height: theme.designSystem.size[24],
  width: theme.designSystem.size[44],
  borderRadius: theme.designSystem.size[20],
  marginLeft: theme.designSystem.size[5],
  backgroundColor: theme.designSystem.base[50],
  borderStyle: 'solid',
  borderWidth: theme.designSystem.size[2],
  borderColor: theme.designSystem.base[200],
  color: theme.designSystem.base[400],
  fontSize: theme.designSystem.typography.fontSize.caption,
  boxShadow: 'none'
}))
