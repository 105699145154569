import { useEffect, useState, useCallback, useRef } from 'react'
import { useApiGlobalFilters } from 'shared/services/Requests/Filters'
import { FilterDate, Product } from 'shared/services/Requests/Filters/types'

const useFiltersGlobal = () => {
  const { mutate, data, loading } = useApiGlobalFilters()
  const [globalProducts, setGlobalProducts] = useState<Product[] | undefined>(undefined)
  const [globalDate, setGlobalDate] = useState<FilterDate | undefined>(undefined)
  const hasFetched = useRef(false)

  const fetchFilters = useCallback(() => {
    if (!loading && !hasFetched.current) {
      hasFetched.current = true
      mutate()
    }
  }, [loading, mutate])

  const saveToLocalStorage = useCallback((key: string, value: unknown) => {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.error(`Erro ao salvar ${key} no LocalStorage:`, error)
    }
  }, [])

  useEffect(() => {
    const loadFiltersFromLocalStorage = () => {
      try {
        const storedFiltersProducts = localStorage.getItem('filterProducts')
        const storedFiltersDate = localStorage.getItem('filterDate')

        if (storedFiltersProducts && storedFiltersDate) {
          setGlobalProducts(JSON.parse(storedFiltersProducts))
          setGlobalDate(JSON.parse(storedFiltersDate))
        } else {
          fetchFilters()
        }
      } catch (error) {
        console.error('Erro ao carregar filtros do LocalStorage:', error)
        fetchFilters()
      }
    }

    loadFiltersFromLocalStorage()
  }, [])

  useEffect(() => {
    if (data) {
      saveToLocalStorage('filterProducts', data.products)
      setGlobalProducts((prev) =>
        JSON.stringify(prev) !== JSON.stringify(data.products) ? data.products : prev
      )

      saveToLocalStorage('filterDate', data.filterDate)
      setGlobalDate((prev) =>
        JSON.stringify(prev) !== JSON.stringify(data.filterDate) ? data.filterDate : prev
      )
    }
  }, [data, saveToLocalStorage])

  return {
    globalProducts,
    globalDate,
    fetchFilters
  }
}

export default useFiltersGlobal
