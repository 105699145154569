import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { theme } from 'shared/theme'
import { getMonthsFirstLetter } from 'shared/utils/dates'
import { Bar, BarContainer, EmptyBarsComponent, MonthsContainer, MonthText } from './styles'

export const EmptyBars = () => {
  return (
    <EmptyBarsComponent>
      <BarContainer>
        {Array.from({ length: 12 }).map((_, index) => (
          <Bar key={index} />
        ))}
      </BarContainer>

      <MonthsContainer>
        {getMonthsFirstLetter().map((month, index) => (
          <MonthText key={index}>{month}</MonthText>
        ))}
      </MonthsContainer>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          pt: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Box
            sx={{
              width: 12,
              height: 12,
              bgcolor: `${theme.designSystem.base[100]}`
            }}
          />
          <Typography
            fontSize={theme.designSystem.size[12]}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
          >
            Consumo confirmado
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Typography
            fontSize={theme.designSystem.size[12]}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
          >
            Aguardando medição
          </Typography>
          <Box
            sx={{
              width: 12,
              height: 12,
              bgcolor: `${theme.designSystem.base[100]}`
            }}
          />
        </Box>
      </Box>
    </EmptyBarsComponent>
  )
}

export default EmptyBars
