import React from 'react'
type Props = {
  sx?: React.CSSProperties
}
export const ArrowUp = ({ sx = {} }: Props) => {
  return (
    <svg
      width={12}
      height={8}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={sx}
    >
      <path
        d="M10.6654 6.66732L5.9987 1.33398L1.33203 6.66732"
        stroke="#050922"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
