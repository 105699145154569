import { Box } from 'shared/designSystem/components/Box'
import { IconPdf } from 'shared/designSystem/Icons/IconPdf'
import { IconPrinter } from 'shared/designSystem/Icons/IconPrinter'
import { ReportOptionButton } from './styles'

interface Props {
  handlePrint: () => void
  handleDownload: () => void
  disabledActions?: boolean
}

export const ReportOptions = ({ handlePrint, handleDownload, disabledActions }: Props) => {
  return (
    <Box display="flex">
      <ReportOptionButton disabled={disabledActions} title="Download PDF" onClick={handleDownload}>
        <IconPdf />
      </ReportOptionButton>

      <ReportOptionButton disabled={disabledActions} title="Impressão" onClick={handlePrint}>
        <IconPrinter />
      </ReportOptionButton>
    </Box>
  )
}
