import { useState } from 'react'
import api, { TOKEN_KEY } from 'shared/services/Api'
import endpoints from 'shared/services/Api/endpoints'
import { DataStructure } from './types'
import { jwtDecode } from 'jwt-decode'

const useApiGlobalFilters = () => {
  const [data, setData] = useState<DataStructure | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const mutate = async () => {
    try {
      setLoading(true)
      setError(null)
      const { id } = jwtDecode(localStorage.getItem(TOKEN_KEY)!) as { id: string }
      const response = await api.get(endpoints.filters.filterGroups({ userId: id }))
      setData(response?.data?.data)
    } catch (error) {
      console.error('Erro ao buscar filtros:', error)
      setError('Ocorreu um erro ao buscar os filtros. Tente novamente mais tarde.')
      setData(null)
    } finally {
      setLoading(false)
    }
  }

  return { mutate, data, loading, error }
}

export { useApiGlobalFilters }
