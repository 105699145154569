import { styledSystem } from 'shared/designSystem/styles/StyledSystem'
import { Box } from 'shared/designSystem/components/Box'
import { Accordion } from 'shared/designSystem/components/Accordion'

interface DetailsContainerProps {
  isExpanded: boolean
}

export const ModalContainer = styledSystem(Box)(({ theme }) => ({
  position: 'absolute',
  inset: 7,
  zIndex: 999,
  backgroundColor: theme.designSystem.white,
  overflow: 'scroll'
}))

export const ButtonClose = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: theme.designSystem.size[30],
  minHeight: theme.designSystem.size[30],
  cursor: 'pointer'
}))

export const ContainerIconsSelectsHeader = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  width: '85%',

  [theme.breakpoints.up(700)]: {
    width: '35%',
    margin: '-10px'
  }
}))

export const DetailsContainer = styledSystem(Box)<DetailsContainerProps>(({ theme, isExpanded }) => ({
  position: isExpanded ? 'absolute' : 'relative',
  borderLeft: `1px solid ${theme.designSystem.base[200]}`,
  backgroundColor: 'white',
  maxHeight: '100%',
  height: '100%',
  zIndex: 999,
  width: isExpanded ? '100%' : '0%',

  [theme.breakpoints.up('md')]: {
    width: isExpanded ? '40%' : '0%'
  }
}))
export const InformationsContainer = styledSystem(Box)(({ theme }) => ({
  padding: theme.designSystem.size[40],
  backgroundColor: 'white'
}))

export const AccordionOption = styledSystem(Accordion)(() => ({
  boxShadow: 'none',
  '&:before': {
    display: 'none'
  },
  '&.MuiAccordion-root.Mui-expanded': {
    margin: '0'
  }
}))

export const ButtonExpand = styledSystem(Box)(({ theme }) => ({
  height: '50px',
  width: '50px',
  border: `1px solid ${theme.designSystem.base[200]}`,
  borderRadius: '100%',
  position: 'fixed',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.designSystem.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}))
