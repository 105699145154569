import { GridColDef } from 'shared/designSystem/components/GridColDef'
import { ContainerCodeTicket } from './style'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const useColumns = () => {
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
      <div hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
        {value === index && <ContainerCodeTicket sx={{ p: 3 }}>{children}</ContainerCodeTicket>}
      </div>
    )
  }

  const columnsDetails: GridColDef[] = [
    {
      field: 'description',
      headerName: 'Descrição',
      flex: 1
    },
    {
      field: 'amout',
      headerName: 'Quantidade',
      flex: 1
    },
    {
      field: 'valueTax',
      headerName: 'Tarifa',
      flex: 1
    },
    {
      field: 'value',
      headerName: 'Valor',
      flex: 1
    }
  ]

  return {
    columnsDetails,
    a11yProps,
    CustomTabPanel
  }
}

export default useColumns
