import { useEffect, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { InputAdornment } from 'shared/designSystem/components/InputAdornment'
import { Menu } from 'shared/designSystem/components/Menu'
import { MenuItem } from 'shared/designSystem/components/MenuItem'
import { Stack } from 'shared/designSystem/components/Stack'
import { TextField } from 'shared/designSystem/components/TextField'
import { ArrowDown } from 'shared/designSystem/Icons/ArrowDown'
import { ArrowRight } from 'shared/designSystem/Icons/ArrowRight'
import { ArrowUp } from 'shared/designSystem/Icons/ArrowUp'
import useFilters from 'shared/hook/useFilters'
import useFiltersGlobal from 'shared/hook/useFiltersGlobal'
import type { TypePropsPageHeader } from 'shared/layout/Header/interface'
import { TypeComboMonths } from 'shared/models/Filters'

const MultiLevelSelectYear = ({ fetchData }: TypePropsPageHeader) => {
  const { filters, setBulkFilterData } = useFilters('filtersAll')
  const { globalDate } = useFiltersGlobal()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null)
  const [comboMonths, setComboMonths] = useState<TypeComboMonths[]>([])
  const isMenuOpen = Boolean(anchorEl)

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event?.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSubmenuAnchorEl(null)
  }

  const handleCloseSubmenu = () => {
    setSubmenuAnchorEl(null)
  }

  const handleYear = (year: number, event: any) => {
    const dataFilters = {
      year: year,
      productId: filters?.product,
      groupId: filters?.group,
      unitId: filters?.unit
    }

    if (year != filters?.year) fetchData(dataFilters)

    setBulkFilterData({ ...filters, year: year, month: '' })
    setSubmenuAnchorEl(event?.currentTarget)
  }

  const handleMonth = (month: number | string) => {
    setBulkFilterData({ ...filters, month: month })
    handleCloseMenu()
  }

  const handleInitialParams = () => {
    if (filters?.year) {
      const dataMonth = globalDate?.filterDateList?.find((item: any) => item.year == filters?.year)
      if (dataMonth) setComboMonths(dataMonth?.months)
    }
  }

  const handleTitle = () => {
    if (filters?.month) {
      const dataYear = globalDate?.filterDateList?.find((item: any) => item.year == filters?.year)
      const dataMonth = dataYear?.months?.find((item: any) => item.value == filters?.month)
      return `${filters?.year} - ${dataMonth?.name}`
    }

    if (filters?.year) return filters?.year

    return 'Selecione uma data'
  }

  useEffect(() => {
    handleInitialParams()
  }, [filters])

  return (
    <>
      <TextField
        size="small"
        onClick={handleOpenMenu}
        variant="outlined"
        value={handleTitle()}
        slotProps={{
          input: {
            style: { fontWeight: 'bold', cursor: 'pointer' },
            endAdornment: (
              <InputAdornment position="end">
                {isMenuOpen ? (
                  <ArrowUp sx={{ cursor: 'pointer' }} />
                ) : (
                  <ArrowDown sx={{ cursor: 'pointer' }} />
                )}
              </InputAdornment>
            )
          }
        }}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          '& .MuiMenu-paper': {
            width: anchorEl ? `${anchorEl.offsetWidth}px` : 'auto',
            height: 'auto',
            border: 'none',
            borderRadius: '0',
            marginBottom: '10px',
            borderTop: '1px solid #E0E0E0'
          }
        }}
      >
        {globalDate?.filterDateList?.map((item) => (
          <MenuItem
            key={item.year}
            sx={{ fontWeight: filters?.year == item.year ? 'bold' : '' }}
            onClick={(event) => handleYear(item.year, event)}
          >
            <Stack
              display="flex"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <div>{item.year} </div>
              <div>{item?.months?.length > 0 && <ArrowRight />}</div>
            </Stack>
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={submenuAnchorEl}
        open={Boolean(submenuAnchorEl)}
        onClose={handleCloseSubmenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box sx={{ height: '224px' }}>
          {comboMonths.map((month) => (
            <MenuItem
              sx={{ fontWeight: filters?.month == month.value ? 'bold' : '' }}
              key={month.value}
              onClick={() => handleMonth(month.value)}
            >
              {month.name}
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </>
  )
}

export default MultiLevelSelectYear
