import { Box } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const ErrorCardContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: theme.designSystem.size[32],
  padding: theme.designSystem.size[24],
  height: '100vh',
  width: '100vw'
}))

export const BoxScreen = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.designSystem.size[32],
  padding: theme.designSystem.size[24]
}))

export const ErrorCircle = styledSystem(Box)(({ theme }) => ({
  width: theme.designSystem.size[64],
  height: theme.designSystem.size[64],
  backgroundColor: theme.designSystem.deepOrange[500],
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export const TypographyBody = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.body1,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[900]
}))

export const BoxMessage = styledSystem(Box)(({ theme }) => ({
  width: theme.designSystem.size[296]
}))

export const TypographyMessage = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.body2,
  fontWeight: theme.designSystem.typography.fontWeight.regular,
  color: theme.designSystem.base[500]
}))

export const ErrorButton = styledSystem(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    borderColor: theme.designSystem.base[900],
    color: theme.designSystem.base[500],
    backgroundColor: theme.designSystem.base[900],
    borderRadius: theme.designSystem.size[24],
    padding: `${theme.designSystem.size[12]}px ${theme.designSystem.size[32]}px`,
    textTransform: 'uppercase'
  }
}))

export const TypographyButton = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.button,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[100]
}))
