import { styledSystem } from 'shared/designSystem/styles/StyledSystem'
import { Tooltip } from '../Tooltip'

export const StyledTooltip = styledSystem(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    width: theme.designSystem.size[128],
    padding: theme.designSystem.size[12],
    backgroundColor: theme.designSystem.base[700],
    color: theme.designSystem.base[50],
    fontSize: theme.designSystem.typography.fontSize.caption,
    fontWeight: theme.designSystem.typography.fontWeight.regular,
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.24), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)'
  },
  '& .MuiTooltip-arrow': {
    color: theme.designSystem.base[700]
  }
}))
