import { Box } from 'shared/designSystem/components/Box'
import { Header } from '../../../../DetailsModal/Header'
import { ModalContainer } from '../../../../DetailsModal/styles'
import EconomyGraph from '../../index'
import { PDFDocument } from '../../../../PDFDocument'
import { Loading } from 'shared/designSystem/components/Loading'
import useModalEconomyEnergy from './useModal'

export const EconomyModalDetailEnergy = () => {
  const { loading, mutateEconomy, pdfRef, pdfElements, stateDataEconomy, infosHeaderReport } =
    useModalEconomyEnergy()

  return (
    <>
      <ModalContainer>
        <>
          <Header fetchData={mutateEconomy} pdfElements={pdfElements} pdfRef={pdfRef} />
          <Box sx={{ display: 'flex', height: '100%' }}>
            {loading ? (
              <Loading />
            ) : (
              <EconomyGraph
                variant="modal"
                dataInfos={stateDataEconomy}
                dataInfosHeader={infosHeaderReport}
              />
            )}
          </Box>
        </>
      </ModalContainer>
      <Box
        sx={{
          position: 'absolute',
          top: '-9999px',
          width: '270mm',
          height: '367mm',
          overflow: 'hidden',
          visibility: 'hidden'
        }}
        ref={pdfRef}
      >
        {pdfElements.map((pdf, i) => (
          <PDFDocument
            key={i}
            data={pdf.data}
            columns={pdf.columns}
            name="Relatório de Economia"
            footerTable={pdf?.footerTable}
          >
            {pdf.graph}
          </PDFDocument>
        ))}
      </Box>
    </>
  )
}
