import { Chip, styled } from '@mui/material'
import { TagProps } from '.'

export const StatusTag = styled(Chip)(({ colorTag }: TagProps) => ({
  borderRadius: 5,
  backgroundColor: `${colorTag || '#5D65F7'}10`,
  color: colorTag,
  height: 28,
  fontWeight: 600,
  fontSize: 14,
  padding: '8px 5px'
}))
