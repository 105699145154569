import { Box } from 'shared/designSystem/components/Box'
import { CardContent } from 'shared/designSystem/components/CardContent'
import { formatCurrencyManual } from 'shared/utils/formatCurrency'
import {
  CardContentBox,
  CardDate,
  CardDescription,
  CardName,
  CardPrice,
  CardUCName,
  ChipBox
} from './styles'

interface Props {
  ucName: string
  name: string
  status: 'Pago' | 'Pendente' | 'Não disponível'
  description: string
  date: string
  value: number
}

export const PaymentInfoCard = ({ ucName, name, status, description, date, value = 0 }: Props) => {
  return (
    <CardContentBox>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Box display="flex" justifyContent="space-between">
            <CardUCName>{ucName}</CardUCName>

            <ChipBox status={status} label={status} />
          </Box>
          <CardName>{name}</CardName>
        </Box>

        <CardDescription>{description}</CardDescription>

        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <CardDate>{date}</CardDate>
          <CardPrice>{formatCurrencyManual(value)}</CardPrice>
        </Box>
      </CardContent>
    </CardContentBox>
  )
}
