import { Box } from 'shared/designSystem/components/Box'
import { IconGenial } from 'shared/designSystem/Icons/IconGenial'
import { ProductBadge } from 'shared/layout/Header/HeaderContainer/ProductBadge'
import { theme } from 'shared/theme'
import {
  BoxIcon,
  HeaderContainer,
  InfoContainer,
  InfoDynamic,
  InfoItem,
  LogoContainer,
  Title
} from '../styles'
import useHeader from './useHeader'

interface Props {
  page: number
  numberOfPages: number
}

export const Header = ({ page, numberOfPages }: Props) => {
  const { product, title, selection, yearSelection } = useHeader()

  return (
    <HeaderContainer>
      <LogoContainer>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <BoxIcon hasBackground>
            <IconGenial />
          </BoxIcon>
        </Box>
        <ProductBadge name={product} color={theme.designSystem.base[500]} />
      </LogoContainer>

      <InfoContainer>
        <Box>
          <Title>{title}</Title>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 4
          }}
        >
          <Box sx={{ display: 'flex', gap: 1 }}>
            <InfoItem>Seleção</InfoItem>
            <InfoDynamic>{selection}</InfoDynamic>
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <InfoItem>Período</InfoItem>
            <InfoDynamic>{yearSelection}</InfoDynamic>
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <InfoItem>Página</InfoItem>
            <InfoDynamic>{page}</InfoDynamic>
            <InfoItem>de</InfoItem>
            <InfoDynamic>{numberOfPages}</InfoDynamic>
          </Box>
        </Box>
      </InfoContainer>
    </HeaderContainer>
  )
}
