import { ChartOptions } from 'chart.js'
import { GraphPropsEconomy } from 'pages/Reports/types/GraphProps'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useFilters from 'shared/hook/useFilters'
import { theme } from 'shared/theme'
import { formatCurrency } from 'shared/utils/formatCurrency'
import { getDesaturatedColor } from 'shared/utils/getDesaturatedColor'
import { getMonthAbbreviations, getMonthFirstLetter, getMonths } from 'shared/utils/dates'

const useEconomyGraphEnergy = ({ variant, dataInfos }: GraphPropsEconomy) => {
  const { filters } = useFilters('filtersAll')

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [isMonthView, setIsMonthView] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null)
  const [tooltipData, setTooltipData] = useState<{
    x: number
    y: number
    visible: boolean
  }>({ x: 0, y: 0, visible: false })

  const isModalVariant = variant === 'modal'
  const monthlySavings = dataInfos?.dataGraphic?.monthlySavings || []
  const captiveCost = dataInfos?.dataGraphic?.captiveCost || []
  const freeCost = dataInfos?.dataGraphic?.freeCost || []
  const hasData = isMonthView ? freeCost.some((_, index) => index === selectedMonth) : !!dataInfos
  const labels = getMonthAbbreviations()
  const months = getMonths()

  useEffect(() => {
    if (filters?.month && filters?.month != 0 && !isModalVariant) {
      setSelectedMonth(Number(filters?.month) - 1)
      setIsMonthView(true)
    } else {
      setSelectedMonth(null)
      setIsMonthView(false)
    }
  }, [filters?.month])

  const getBorderWidth = (label: string) => {
    if (!isMonthView) return undefined
    const borders: Record<string, { top: number; bottom: number; left: number; right: number }> = {
      'Custo tradicional': { top: 0, bottom: 0, left: 0, right: 4 },
      'Custo genial': { top: 8, bottom: 0, left: 4, right: 0 },
      Economia: { top: 0, bottom: 0, left: 4, right: 0 }
    }
    return borders[label]
  }

  const data: any = {
    labels: isModalVariant ? getMonthAbbreviations() : getMonthFirstLetter(),
    datasets: [
      {
        type: 'line' as const,
        label: 'Economia mensal',
        data: monthlySavings,
        borderColor: '#000000af',
        borderWidth: 2,
        pointBackgroundColor: '#ffffff',
        pointBorderColor: '#000000af',
        fill: false,
        stack: 'stack1'
      },
      {
        type: 'bar' as const,
        label: 'Custo tradicional',
        data: captiveCost,
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.primary[100]
            : getDesaturatedColor(theme.designSystem.primary[100]),
        barThickness: window.innerWidth <= 768 ? 8 : 22,
        barPercentage: 0.8,
        categoryPercentage: 0.5,
        stack: 'stack2'
      },
      {
        type: 'bar' as const,
        label: 'Custo Genial',
        data: freeCost,
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.primary[500]
            : getDesaturatedColor(theme.designSystem.primary[500]),
        barThickness: window.innerWidth <= 768 ? 8 : 22,
        barPercentage: 0.8,
        categoryPercentage: 0.5,
        stack: 'stack3'
      }
    ]
  }

  const detailedData: any = {
    labels: selectedMonth !== null ? [labels[selectedMonth]] : [],
    datasets: [
      {
        type: 'bar' as const,
        label: 'Custo tradicional',
        data: captiveCost.filter((_, index) => index === selectedMonth),
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.primary[100]
            : getDesaturatedColor(theme.designSystem.primary[100]),
        barThickness: 40,
        barPercentage: 0.8,
        categoryPercentage: 0.5,
        borderColor: 'white',
        borderWidth: getBorderWidth('Custo tradicional'),
        stack: 'stack1'
      },
      {
        type: 'bar' as const,
        label: 'Custo genial',
        data: freeCost.filter((_, index) => index === selectedMonth),
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.primary[500]
            : getDesaturatedColor(theme.designSystem.primary[500]),
        barThickness: 40,
        barPercentage: 0.8,
        categoryPercentage: 0.5,
        borderColor: 'white',
        borderWidth: getBorderWidth('Custo genial'),
        stack: 'stack2'
      },
      {
        type: 'bar' as const,
        label: 'Economia',
        data: monthlySavings.filter((_, index) => index === selectedMonth),
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? theme.designSystem.primary[300]
            : getDesaturatedColor(theme.designSystem.primary[300]),
        barThickness: 40,
        barPercentage: 0.8,
        categoryPercentage: 0.5,
        borderColor: 'white',
        borderWidth: getBorderWidth('Economia'),
        stack: 'stack2'
      }
    ]
  }

  const prevTooltipData = useRef(tooltipData)

  const handleTooltip = useCallback(
    ({ chart, tooltip }: any) => {
      if (tooltip.opacity === 0) {
        if (tooltipData.visible) setTooltipData({ ...tooltipData, visible: false })
        return
      }

      const { offsetLeft, offsetTop } = chart.canvas
      const newTooltipData = {
        x: offsetLeft + tooltip.caretX,
        y: offsetTop + tooltip.caretY,
        visible: true
      }

      if (
        prevTooltipData.current.x !== newTooltipData.x ||
        prevTooltipData.current.y !== newTooltipData.y ||
        !tooltipData.visible
      ) {
        prevTooltipData.current = newTooltipData
        setTooltipData(newTooltipData)
      }
    },
    [tooltipData]
  )

  const baseOptions: ChartOptions<'bar'> = {
    responsive: true,
    animation: false,
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
      tooltip: {
        enabled: false,
        external: handleTooltip
      }
    },
    onHover: (_, activeElements) => {
      const newIndex = activeElements.length ? activeElements[0].index : null
      setHoveredIndex(newIndex)
      if (!activeElements.length) {
        setTimeout(() => {
          setHoveredIndex(null)
        }, 50)
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: isModalVariant
        },
        ticks: {
          display: isModalVariant
        },
        border: {
          display: false
        }
      }
    },
    elements: {
      bar: {
        hoverBorderColor: '#FFF',
        hoverBackgroundColor: undefined
      }
    }
  }

  const options: ChartOptions<'bar' | 'line'> = {
    ...baseOptions,
    onClick: (_, activeElements) => {
      if (activeElements.length > 0 && !isModalVariant) {
        const index = activeElements[0].index
        setSelectedMonth(index)
        setIsMonthView(true)
      }
    }
  }

  const detailedOptions: ChartOptions<'bar'> = {
    ...baseOptions,

    onClick: () => {
      setSelectedMonth(null)
      setIsMonthView(false)
    }
  }

  const dataInfosHeaderDetail = useMemo(() => {
    const economyMonth = monthlySavings.find((_, index) => index === selectedMonth) ?? 0
    return [
      {
        titleValue: `Economia em ${months[selectedMonth!]}`,
        value: formatCurrency(economyMonth),
        tooltipInfos: 'Atualizado conforme medições da distribuidora para todas as unidades'
      }
    ]
  }, [selectedMonth])

  const legends = [
    {
      color: theme.designSystem.primary[100],
      text: 'Custo cativo',
      variant: 'square'
    },
    {
      color: theme.designSystem.primary[500],
      text: 'Custo livre',
      variant: 'square'
    },
    {
      color: isMonthView ? theme.designSystem.primary[300] : theme.designSystem.base[900],
      text: 'Economia',
      variant: isMonthView ? 'square' : 'line-thin'
    },
    {
      show: isMonthView,
      color: theme.designSystem.primary[200],
      secondaryColor: theme.designSystem.primary[100],
      text: 'Aguardando medição',
      variant: 'line-thick'
    }
  ]

  const handleEmpty = () => {
    setSelectedMonth(null)
    setIsMonthView(false)
  }

  return {
    isModalVariant,
    hasData,
    setHoveredIndex,
    data,
    options,
    tooltipData,
    captiveCost,
    freeCost,
    hoveredIndex,
    legends,
    monthlySavings,
    detailedData,
    detailedOptions,
    isMonthView,
    selectedMonth,
    dataInfosHeaderDetail,
    handleEmpty
  }
}

export default useEconomyGraphEnergy
