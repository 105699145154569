export const light = {
  base: {
    50: '#FAFBFF',
    100: '#F0F1F5',
    200: '#DDDEE4',
    300: '#BCBEC8',
    400: '#9194A3',
    500: '#636779',
    600: '#3E4256',
    700: '#24283E',
    800: '#12162C',
    900: '#050922'
  },
  primary: {
    50: '#EBF8FF',
    100: '#CDEEFF',
    200: '#A6E0FF',
    300: '#78CDFF',
    400: '#53BAFF',
    500: '#38A8FF',
    600: '#2A8DF1',
    700: '#1E6ACD',
    800: '#154495',
    900: '#0B2054'
  },
  secondary: {
    50: '#F0F0FF',
    100: '#DAD9FF',
    200: '#BEBDFF',
    300: '#9D9EFD',
    400: '#7E80FB',
    500: '#5D65F7',
    600: '#3F4CEB',
    700: '#2936C4',
    800: '#192488',
    900: '#011367'
  },
  tertiary: {
    50: '#E6FEFF',
    100: '#BCF9FE',
    200: '#8EF3FC',
    300: '#67EDFC',
    400: '#4CE6FA',
    500: '#37DDF6',
    600: '#2ACBEA',
    700: '#1FACCE',
    800: '#177E9F',
    900: '#0E455D'
  },
  brand: {
    50: '#EBF4FF',
    100: '#BFD8FF',
    200: '#91B6FF',
    300: '#6691FF',
    400: '#3B6CFF',
    500: '#1947FF',
    600: '#0029E7',
    700: '#001FAE',
    800: '#011367',
    900: '#050922'
  },
  blueGray: {
    50: '#F3F4FC',
    100: '#DADDEC',
    200: '#C2C6DA',
    300: '#AAAFC8',
    400: '#9499B6',
    500: '#7D84A3',
    600: '#636A8B',
    700: '#40486D',
    800: '#1D2349',
    900: '#050922'
  },
  attention: {
    500: '#FFC107'
  },
  deepOrange: {
    50: '#FBE9E7',
    400: '#FF7043',
    500: '#FF5722',
    600: '#F4511E',
    900: '#BF360C'
  },
  green: {
    50: '#F1F8E9',
    500: '#8BC34A',
    700: '#689F38'
  },
  brown: {
    500: '#4A3700'
  },
  success: '#8BC34A',
  white: '#FFFFFF',
  size: {
    1: 1,
    1.5: 1.5,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    8: 8,
    10: 10,
    12: 12,
    13: 13,
    14: 14,
    16: 16,
    17: 17,
    18: 18,
    20: 20,
    21: 21,
    22: 22,
    24: 24,
    25: 25,
    26: 26,
    28: 28,
    30: 30,
    32: 32,
    40: 40,
    44: 44,
    45: 45,
    48: 48,
    50: 50,
    52: 52,
    56: 56,
    60: 60,
    64: 64,
    58: 58,
    66: 66,
    72: 72,
    80: 80,
    81: 81,
    90: 90,
    119: 119,
    100: 100,
    120: 120,
    128: 128,
    130: 130,
    140: 140,
    143: 143,
    150: 150,
    180: 180,
    184: 184,
    191: 191,
    200: 200,
    238: 238,
    250: 250,
    255: 255,
    256: 256,
    271: 271,
    279: 279,
    296: 296,
    304: 304,
    309: 309,
    320: 320,
    350: 350,
    369: 369,
    396: 396,
    420: 420,
    422: 422,
    431: 431,
    484: 484,
    550: 550,
    580: 580,
    592: 592,
    600: 600,
    610: 610,
    686: 686,
    762: 762,
    960: 960,
    1352: 1352
  },
  typography: {
    fontSize: {
      caption: 12,
      button: 14,
      body2: 16,
      body1: 18,
      h3: 24,
      h2: 32,
      h1: 40
    },
    fontWeight: {
      bold: 700,
      medium: 600,
      regular: 400,
      light: 300
    },
    fontFamily: '"Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif'
  }
}
