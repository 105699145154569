import { GridColDef } from '@mui/x-data-grid'
import ButtonDetails from 'pages/Financial/components/buttonDetails'
import Tag from 'shared/designSystem/components/Tag'
import { IfinancesItemList } from 'shared/services/Requests/finances/types'
import { getColorsByStatus } from 'shared/utils/colorsStatus'
import { formatDateShort } from 'shared/utils/dates'
import { formatCurrency } from 'shared/utils/formatCurrency'

export const columnsHistory = (action: (row: IfinancesItemList) => void): GridColDef[] => [
  {
    field: 'unitName',
    headerName: 'Unidade consumidora',
    flex: 1
  },
  {
    field: 'invoiceMonthReference',
    headerName: 'Mês',
    flex: 1,
    renderCell: ({ row }) =>
      row.invoiceMonthReference ? formatDateShort(row.invoiceMonthReference) : '-'
  },
  {
    field: 'value',
    headerName: 'Valor',
    flex: 1,
    renderCell: ({ row }) => (row.value ? formatCurrency(row.value) : '-')
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    flex: 1,
    renderCell: ({ row }) => (row.dueDate ? formatDateShort(row.dueDate) : '-')
  },
  {
    field: 'payment',
    headerName: 'Pagamento',
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row }) => <Tag label={row.status} colorTag={getColorsByStatus(row?.status)} />
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    align: 'right',
    renderCell: ({ row }) => <ButtonDetails onClick={() => action(row)} />
  }
]

export const columnsIsOpen = (action: (row: IfinancesItemList) => void): GridColDef[] => [
  {
    field: 'unitName',
    headerName: 'Unidade consumidora',
    flex: 1
  },
  {
    field: 'invoiceMonthReference',
    headerName: 'Mês',
    flex: 1,
    renderCell: ({ row }) =>
      row.invoiceMonthReference ? formatDateShort(row.invoiceMonthReference) : '-'
  },
  {
    field: 'value',
    headerName: 'Valor',
    flex: 1,
    renderCell: ({ row }) => (row.value ? formatCurrency(row.value) : '-')
  },
  {
    field: 'duedate',
    headerName: 'Vencimento',
    flex: 1,
    renderCell: ({ row }) => (row.dueDate ? formatDateShort(row.dueDate) : '-')
  },
  {
    field: 'payment',
    headerName: 'Pagamento',
    flex: 1
  },
  {
    field: 'distributor',
    headerName: 'Distribuidora',
    flex: 1
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    align: 'right',
    renderCell: ({ row }) => <ButtonDetails onClick={() => action(row)} />
  }
]
